<template>
    <div class="w-full flex flex-col">
        <div class="flex w-full border-4 border-white rounded overflow-x-auto w-1460px">
            <template v-for="(data, index) in dataPattern">
                <div class="grid flex-1" style="grid-template-rows: 315px 200px 318px;height:833px;" :key="index" v-if="data<8">
                    <template v-for="(category, i) in gridLayout.layout_grid_category" >
                        <div
                            v-if="(data) < i && i <= dataPattern[index+1]"
                            class="shrink-0"
                            :key="category.id" >
                            <layoutComponent 
                            
                                :title="`${index}-${i}`"
                                :isVisualize='isVisualize' 
                                :category="category" 
                                :style="{'--bgImgForVisualize': `url(${backgroundImages[index][i]?.bg})`, '--opacityForVisualizeImg': backgroundImages[index][i]?.opacity}"
                                layout="threeByThree" 
                                categoryIndexToModify="[1,4,7]" 
                                :_class="[1,4,7].includes(i) ? 'inner_column_3/3 row_one' : 'inner_column_3/3'" />
                        </div>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
    import layoutComponent from './layoutComponent.vue'
    import imageOne from '@/assets/images/visualize/3x3-1.webp'
    import imageTwo from '@/assets/images/visualize/3x3-2.webp'
    import imageThree from '@/assets/images/visualize/3x3-3.webp'
    import imageFour from '@/assets/images/visualize/3x3-4.webp'
    import imageFive from '@/assets/images/visualize/3x3-5.webp'
    
    const dataPattern = [-1, 2, 5, 8];
    // eslint-disable-next-line no-undef
    const props =  defineProps({
        gridLayout: {
            type: Object
        },
        isVisualize: {
            type: Boolean
        }
    });
    
    const backgroundImages = [[{
          bg: imageOne,
          opacity: 1/100*5
      },
      {
          bg: imageThree,
          opacity: 1/100*10
      },
      {}],

      [{}, {}, {}, {
          bg: imageTwo,
          opacity: 1/100*5
      },
      {
          bg: imageFour,
          opacity: 1/100*50
      }],

      [{}, {}, {}, {}, {}, {}, {}, {},
      {
          bg: imageFive,
          opacity: 1/100*10
    }]]
</script>