import { render, staticRenderFns } from "./CoverImage.vue?vue&type=template&id=648fb4f1&"
import script from "./CoverImage.vue?vue&type=script&setup=true&lang=js&"
export * from "./CoverImage.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports