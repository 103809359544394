<template>
    <div>
        <!-- page title slot -->
        <slot />
        <!-- page title slot -->
        <div style="height: 127px; background-color: #EDEDED" class="cursor-pointer mt-3 relative">
            <img :src="get(item, 'file.file_path', '/images/build/demo_image_1.jpg')" alt=""
                class="h-full w-full object-cover">
            <label v-if="canEdit(project.cans)" class="absolute inset-0 property-image-icon cursor-pointer">
                <input @input="handleChange($event, item)" hidden type="file" accept="image/x-png,image/jpeg" />
            </label>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios'
import { get } from 'lodash'
import store from '@/store'
import { set, computed } from 'vue'
import { useFile } from '@/composable/useFile.js'
import usePermission from '@/composable/usePermission.js'

const { onChange, previewURL, file } = useFile()
const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

const project = computed(() => store.getters['project/project'])

const props = defineProps({
    item: Object
})

const handleChange = (e, item) => {
    onChange(e)
    set(props.item, 'file', {file_path: previewURL})
    updateImage(file.value, item)
}

const updateImage = async (file, item) => {
    let formData = new FormData()
    formData.append('id', item.id)
    formData.append('image', file)
    await axios.post('projects/scenarios/collections/image', formData)
}
</script>