<template>
    <div class="flex flex-col layoutTwoBySevenWrapper">
        <div ref="mainContainerToOvserve" class="grid border-4 border-white rounded">
            <div class="flex" ref="rowOne">
                <div class="topInner relative col flex-grow-0 flex-1 border border-white" >
                    <layoutComponent width="356px" height="486px" :style="{'--bgImgForVisualize': `url(${imageOne})`, '--opacityForVisualizeImg': 'calc(1 / 100 * 5)'}"  :isVisualize='isVisualize' :category="getCategoryData('key-partner')" layout="twoBySeven" _class="inner_column_2_7" />
                </div>

                <div class="topInner relative col flex-grow-0 grid flex-1">
                    <div class="border border-white">
                        <layoutComponent width="340px" height="243px" :isVisualize='isVisualize' :category="getCategoryData('key-activity')" layout="twoBySeven" _class="inner_column_2_7 row_one" />
                    </div>

                    <div class="border border-white">
                        <layoutComponent width="340px" height="241px" :isVisualize='isVisualize' :category="getCategoryData('key-resource')" layout="twoBySeven" _class="inner_column_2_7 row_one" />
                    </div>
                </div>

                <div class="topInner col flex-grow-0 flex-1 border border-white">
                    <layoutComponent width="340px" height="486px" :style="{'--bgImgForVisualize': `url(${imageTwo})`, '--opacityForVisualizeImg': 'calc(1 / 100 * 10)'}" :isVisualize='isVisualize' :category="getCategoryData('value-proportion')" layout="twoBySeven" _class="inner_column_2_7" />
                </div>

                <div class="topInner col flex-grow-0 grid flex-1">
                    <div class="border border-white">
                        <layoutComponent width="340px" height="243px" :isVisualize='isVisualize' :category="getCategoryData('customer-relationship')" layout="twoBySeven" _class="inner_column_2_7 row_one" />
                    </div>
                    <div class="border border-white">
                        <layoutComponent width="340px" height="241px" :isVisualize='isVisualize' :category="getCategoryData('channel')" layout="twoBySeven" _class="inner_column_2_7 row_one" />
                    </div>
                </div>

                <div class="topInner col flex-grow-0 flex-1 border border-white">
                    <layoutComponent width="340px" height="486px" :style="{'--bgImgForVisualize': `url(${imageThree})`, '--opacityForVisualizeImg': 'calc(1 / 100 * 10)'}" :isVisualize='isVisualize' :category="getCategoryData('customer-segment')" layout="twoBySeven" _class="inner_column_2_7" />
                </div>
            </div>

            <div class="flex flex-grow-0">
                <div ref="rowTwoColumnOne" class="border border-white twoBysevenLayoutWrapper" :class="!isVisualize&&'h-300px'">
                    <layoutComponent width="869px" height="344px" :style="{'--bgImgForVisualize': `url(${imageFour})`, '--opacityForVisualizeImg': 'calc(1 / 100 * 5)'}" :isVisualize='isVisualize' :category="getCategoryData('cost-structure')" layout="twoBySeven" _class="inner_column_2_7 row_two" />
                </div>
                <div ref="rowTwoColumnTwo" class="border border-white" :class="[!isVisualize&&'w-full', !isVisualize&&'h-300px']" >
                    <layoutComponent width="853px" height="344px" :style="{'--bgImgForVisualize': `url(${imageFive})`, '--opacityForVisualizeImg': 'calc(1 / 100 * 5)'}" :isVisualize='isVisualize' :category="getCategoryData('revenue-stream')" layout="twoBySeven" _class="inner_column_2_7 row_two" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import layoutComponent from './layoutComponent.vue'
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import {ref, onMounted} from 'vue';
    import imageOne from '@/assets/images/visualize/2x7-1.webp'
    import imageTwo from '@/assets/images/visualize/2x7-2.webp'
    import imageThree from '@/assets/images/visualize/2x7-3.webp'
    import imageFour from '@/assets/images/visualize/2x7-4.webp'
    import imageFive from '@/assets/images/visualize/2x7-5.webp'


    // eslint-disable-next-line no-undef
    const props =  defineProps({
        gridLayout: {
            type: Object
        },
        isVisualize: {
            type: Boolean
        }
    });

    const { checkBg, MakeFirstLayoutPerfect, updateTitleAndBgColor } = useCanvas();

    const getCategoryData = (category) => {
        if(!props.gridLayout){ return Object(null) }
        const { layout_grid_category: categoryObjects } = props.gridLayout;

        if(!categoryObjects) return Object(null);
        return categoryObjects.find((item)=> item.category === category);
    }

    const rowOne   = ref();
    const rowTwoColumnOne = ref();
    const rowTwoColumnTwo = ref();
    const mainContainerToOvserve = ref();

    onMounted(()=>{
        if(rowOne.value != null)
        {
            window.addEventListener('click', ()=>{
                MakeFirstLayoutPerfect(rowOne.value, rowTwoColumnOne.value);
            })

            MakeFirstLayoutPerfect(rowOne.value, rowTwoColumnOne.value);
            const observer = new MutationObserver(() => {
                MakeFirstLayoutPerfect(rowOne.value, rowTwoColumnOne.value);

                /**
                 * after calling calculate function we need to recall same function to calculate the position according to new placement of tag
                 */
                setTimeout(()=>{
                    MakeFirstLayoutPerfect(rowOne.value, rowTwoColumnOne.value);
                }, 10)
            });

            observer.observe(mainContainerToOvserve.value, {subtree: true, childList: true});
        }
    });
</script>

<style scoped>
    .layoutTwoBySevenWrapper{
        min-width: 100%;
    }
    .i-left-panel+div>.i-right-panel .layoutTwoBySevenWrapper{
        min-width: unset;
    }
    .topInner{
        flex: 1;
    }
</style>