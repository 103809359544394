<template>
    <div :ref="getRef" @mousemove="cursorMoving" class="w-full overflow-scroll pl-10 scroll-smooth relative">
        <div class="ws-heading-box flex flex-col absolute top-0 right-0 z-50">
            <workspace-menu />
        </div>
        <div class="flex justify-between items-center">
            <div class="absolute tool-pos z-10">

                <div class="flex gap-16 mb-6">
                    <div class="flex gap-1 items-center">
                        <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                        <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">
                            {{ project && project.scenario ? project.scenario.title : 'N/A' }}
                        </h2>
                    </div>
                </div>

                <TopMenu :clickNavigation="clickNavigation" :navigationClicked="navigationClicked"
                    :root-data="activeBrainstormData" canvas="_canvas" :colorSet="colorSet" @onResetZoom="resetZoom"
                    @onShare="shareModal = true">
                    <template #cluster>
                        <div class="flex tool-group">
                            <div @click="setHiddenLevel()" class="flex gap-1">
                                <div
                                    class="flex tool-group i-border-r-1 concept-tool-level-box bg-primary-four cursor-pointer i-border-1 border-transparent" />
                                <div
                                    class="flex tool-group i-border-r-1 concept-tool-level-box l2c cursor-pointer i-border-1 border-transparent" />
                                <div
                                    class="flex tool-group i-border-r-1 concept-tool-level-box l3c cursor-pointer i-border-1 border-transparent" />
                                <div class="flex tool-group i-border-r-1 concept-tool-level-box l4c cursor-pointer i-border-1 border-transparent"
                                    :class="{'opacity-50': true}" />
                            </div>
                        </div>
                    </template>
                    <template #extra>
                        <DuplicateBorderedIcon class="w-10 h-10 minimum-shadow" title="Quick Visualize (Coming Soon)" />
                        <router-link
                            :to="{name: 'brainstormPreview', params: {projectId: $route.params.id, brainstormId: $route.params.brainstorm_id}}">
                            <MonitorBorderedIcon class="w-10 h-10 minimum-shadow cursor-pointer" title="Quick Visualize" />
                        </router-link>
                    </template>
                </TopMenu>
            </div>
        </div>
        <canvas id="_canvas"></canvas>
        <div class="_content_wrapper w-full h-full">
            <!-- <Zoomable> -->
            <PanzoomComponent>
                <div class="flex gap-6 justify-content-between w-full relative mt-40">
                    <div @dragover.prevent class="relative brainstrom_wrapper main_dropzone w-full">
                        <template v-for="note in stickyNotes">
                            <StickyNote :key="note.id" v-if="hasCoordination(note)" @deleteNote="(id) => deleteNote(id)"
                                :id="note.id" :title="note.title" :description="note.description"
                                :color="(note.settings && note.settings.bg_color) ? note.settings.bg_color : '#E7EDC0'"
                                :coordination="{ x: note.coordination.x, y: note.coordination.y }" :class="'_absolute'"
                                :data="note" />
                        </template>
                        <Board :activeBrainstormData="activeBrainstormData" :isolatedModule="isolatedModule"
                            :isolatedModules="isolatedModules" :hiddenLevel="hiddenLevel" />
                    </div>
                </div>
            </PanzoomComponent>
            <!-- </Zoomable> -->
        </div>
        <Modal v-model="shareModal">
            <share-modal :activeBrainstormData="activeBrainstormData" />
        </Modal>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import Board from "@/components/teamwork/brainstorm/Board.vue";
import Modal from '@/elements/atom/Modal'
import TopMenu from "@/components/teamwork/TopMenu.vue";
import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
import ShareModal from "@/components/teamwork/brainstorm/ShareModal";
import { useSticky } from "@/components/teamwork/brainstorm/sticky/stickyController";
import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'

import StickyNote from "@/components/teamwork/brainstorm/sticky/StickyNote.vue";
import router from "@/routes/routes.js";
import store from '@/store'
import { mapGetters } from 'vuex';
import usePanzoom from '@/elements/panzoom/usePanzoom.js'
import { nextIndex  } from '@/plugins/lodash'
import { useShare } from '@/components/teamwork/brainstorm/useShare.js';



export default {
  name: "Brainstorm",

  setup() {
    const route = router.currentRoute;
    const { setStickyNotes, stickyNotes, hasCoordination, deleteSticky, getSticky } = useSticky();
    const { socket } = brainstormContentSharing();
    const hiddenLevel = computed(() => hideSequence[hideSequenceIndex.value])
    const hideSequence = [4, 3, 2, 1, 0, 1, 2, 3]
    const hideSequenceIndex = ref(0);
    
    socket.value.on('brainstorm_api_updating', (data) => {
        let brainstormId = data.brainstormId || route.params.brainstorm_id;
        store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: brainstormId});
        getSticky(brainstormId);
    });
    const setHiddenLevel = () => {
      hideSequenceIndex.value = nextIndex(hideSequenceIndex.value, hideSequence.length)
    }
    const deleteNote = (id) => {
        deleteSticky(id);
        stickyNotes.value = stickyNotes.value.filter((note, i) => {
            return note.id != id;
        });
    }

    onMounted(async () => {
        let notes = await getSticky(route.params.brainstorm_id);
        setStickyNotes(notes);
    });

    const { resetZoom, getRef } = usePanzoom()
    const { loadInvitations } = useShare();

    return {
      getRef,
      resetZoom,
      hiddenLevel,
      setHiddenLevel,
      setStickyNotes,
      deleteNote,
      hasCoordination,
      stickyNotes,
      loadInvitations
    };
  },

  components: {
    Board,
    TopMenu,
    ShareModal,
    Modal,
    PanzoomComponent,
    StickyNote
  },
  computed: {
    ...mapGetters({
      project: "project/project",
      activeStickyNote: "stickyNote/activeStickyNote",
    }),
  },

  data() {
    return {
      dropZon: null,
      timeoutId: null,
      shareModal: false
    };
  },

  props: {
    isolatedModule: [Array, Number, Boolean],
    activeBrainstormData: Object,
    isolatedModules:[Array],
    clickNavigation: Function,
    cursorMoving: Function,
    navigationClicked: Number
  },

  async created() {
    document.title = "Brainstorm";
    this.loadInvitations(this.activeBrainstormData.id);

  },

  methods: {
    dragover(e) {
      e.preventDefault();
    },
    toggleShareModalMode(value) {
      this.shareModal = value;
    },
    colorSet(color) {
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => 
      {
        this.activeStickyNote.settings.bg_color = color
        this.updateSticky(this.activeStickyNote.id, this.activeStickyNote)
      }, 1000)
    }
  }
  
};
</script>

<style>
.col_width {
    min-width: 275px;
    width: 275px;
}

.tool-group img:hover {
    opacity: 1;
}

.tool-group .bs-tool-mr-0 {
    margin-right: 0;
}

.concept-tool-level-box {
    width: 10px;
    height: 16px;
    border-radius: 1px;
}

.l2c {
    background: #3D6A83;
}

.l3c {
    background: #6A7881;
}

.l4c {
    background: #818487;
}

.gap-26px {
    gap: 26px;
}

.tool-pos {
    top: 48px;
}

.tools-panel {
    width: 860px;
}
</style>