<template>
    <div 
        :style="{ backgroundColor: bgColor, width: isVisualize&&width, height: isVisualize&&height }"
        class="layoutWrapper relative i-p-15 h-full border border-white"
        :class="[isVisualize&&'isVisualize', (layout=='oneByFive' || layout=='twoByTwo' || layout=='twoBySeven') ? 'flex-1':'']" >
        
        <div v-if="isVisualize" class="mb-2 flex items-center justify-between flex-auto w-260px">
            <h2 class="uppercase title fs-16 font-black font-poppins text-white" > {{ category.title }} </h2>
        </div>

        <TitleBox v-else :title="category.title" :catId="category.id"
            @onClick="(data) => {let res = createNewTag(category.id); 
            getData(res, (data)=>{category.tags.push(data)})}"
        />
        <div class="grid gap-3 fit-content" :class="!isVisualize&&_class" style="height: 80%">
            <ul v-if="isVisualize" class="listStyle fs-16 fw-400 text-primary-one overflow-y-auto h-full">
                <li v-for="tag in category.tags" :key="tag.id">
                    {{ tag.title }}
                </li>
            </ul>
            <template v-else>
                <ContentBox
                    v-for="(tag, index) in category.tags" 
                    @deleteTag="()=>{deleteTag(tag.id); category.tags.splice(index, 1)}"
                    :key="tag.id" 
                    :id="tag.id" 
                    :brainstorm_business_model_layout_grid_category_id="category.id"
                    :title="tag.title"
                    :settings="category.settings"
                    :isVisualize='isVisualize'
                />
            </template>
        </div>

        <div v-if="showColorPanel" class="absolute top-0 z-30 panzoom-exclude">
            <button class="bg-white px-4 py-1 text-xs mb-2" @click="hidePicker(category)">Save</button>
            <color-panel v-model="bgColor" ></color-panel>
        </div>
        <button v-if="!isVisualize" @click="showPicker" class="absolute right-2 bottom-2 text-white text-xs opacity-60" :class="!canEdit(project.cans)&&'hidden'">Color</button>
    
    </div>
</template>

<script setup>
    import TitleBox from '@/components/teamwork/canvas/components/TitleBox.vue';
    import ContentBox from '@/components/teamwork/canvas/components/ContentBox.vue';
    import PlusIcon from '@/components/teamwork/canvas/components/PlusIcon.vue';
    import usePermission from '@/composable/usePermission.js'
    import store from '@/store'
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import { computed, ref, watchEffect } from'vue'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

    // eslint-disable-next-line no-undef
    const props =  defineProps({
        category: {
            type: Object
        },
        layout: {
            type: String,
            default: ''
        },
        _class: {
            type: String,
            default: ''
        },
        categoryIndexToModify: {
            type: Array,
            default: () => []
        },
        isVisualize: {
            type: Boolean,
            default: false
        },
        width: {
            type: String
        },
        height: {
            type: String
        },
        bgImage: {
            type: String
        }
    });

    const project = computed(()=>{
        return store.getters['project/project']
    })

    const { createTag, deleteTag, getData, checkBg, updateTitleAndBgColor } = useCanvas();
    const createNewTag = async (brainstorm_business_model_layout_grid_category_id)=>{
        let res = await createTag({
            brainstorm_business_model_layout_grid_category_id,
            title:''
        });

        if(res.status) return res.data;
    }


    /* work with color start */
        const bgColor = ref()
        watchEffect(()=>{
            bgColor.value = (props?.category?.settings) ? props.category.settings.bg_color : '#ccc'
        })
        const showColorPanel  = ref(false)
        
        const showPicker = () => {
            showColorPanel.value = true
        }
        const hidePicker = (cat) => 
        {
            showColorPanel.value = false
            updateTitleAndBgColor(cat.id, false, bgColor.value)
        }
    /* work with color end */
</script>

<style scoped>
    .isVisualize.layoutWrapper::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: var(--bgImgForVisualize);
        opacity: var(--opacityForVisualizeImg);
        pointer-events: none;
    }
    .listStyle{
        list-style: disc;
        padding-left: 18px;
    }
    .inner_column{
        grid-template-rows: repeat(5, 100px);
        grid-auto-flow:column;
        min-width: 280px;
    }
</style>