import { render, staticRenderFns } from "./oneByFiveLayout.vue?vue&type=template&id=31dc2920&scoped=true&"
import script from "./oneByFiveLayout.vue?vue&type=script&setup=true&lang=js&"
export * from "./oneByFiveLayout.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./oneByFiveLayout.vue?vue&type=style&index=0&id=31dc2920&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31dc2920",
  null
  
)

export default component.exports