<template>
  <div>
    <!-- title slot -->
    <slot />
    <!-- title slot -->
    <div class="expand-item panzoom-exclude">
      <div class="item-chart-div">
        <div class="flex justify-between">
          <div style="width: 130px;" class="flex flex-col">
            <select
              class="brainstorm-prop-selector fs-10 bg-transparent text-secondary-five"
              v-model="chartConfig.choosed_property_id"
            >
                <option :value="null">Select</option>
              <option
                :value="prop.id"
                v-for="(prop, propIndex) in moduleProperties"
                :key="propIndex"
                @change="setCollectionProperty(prop, item)"
              >
                {{ prop.title }}
              </option>
            </select>
            <select
              class="brainstorm-prop-selector fs-10 text-primary-one bg-transparent"
              v-model="chartConfig.choosed_property_param_id"
              @change="setCollectionPropertyParam($event, item)"
            >
            <option :value="null">Select</option>
              <option
                :value="propParam.id"
                v-for="(propParam, propParamIndex) in getModulePropertyParams(
                  item
                )"
                :key="propParamIndex"
              >
                {{ propParam.title }}
              </option>
            </select>
            <h4 class="fs-16 fw-bold font-poppins text-primary-one py-1">
              {{
                item.current_prop_param
                  ? item.current_prop_param.toFixed(2)
                  : 0.0
              }}
            </h4>
          </div>
          <div
            id="chartcontent"
            class="w-50 pl-4 flex justify-between items-end pb-2"
          >

          <!-- <div  class="w-50 pl-4 flex justify-between items-end  pb-2"> -->
                <div
                :style="{
                    width: propCollection.width + 'px',
                    height: propCollection.sample_value * 10 + '%',
                }"
                    class="flex justify-end bg-primary-four opacity-75 relative bar-radius shadow-two"
                    v-for="(propCollection, propCIndex) in getPropertyValue(item)"
                    :key="propCIndex"
                    :class="{'opacity-100': chartConfig.choosed_property_param_id && parseInt(chartConfig.choosed_property_param_id) === parseInt(propCollection.property_collection_id)}"
                >
                </div>
            <!-- </div> -->

            <!-- <div
              class="flex justify-end bg-primary-four opacity-75 relative bar-radius shadow-two"
              v-for="(propCollection, propCIndex) in getPropertyValue(item)"
              :key="propCIndex"
              :class="{
                'opacity-100':
                  chartConfig.choosed_property_param_id &&
                  parseInt(chartConfig.choosed_property_param_id) ===
                    parseInt(propCollection.property_collection_id),
              }"
              :style="{
                width: propCollection.width + 'px',
                height: propCollection.sample_value * 10 + '%',
              }"
            >
            </div> -->
          </div>
        </div>
        <!-- <div @click="moveToNextDefinitionSet()">
          <h4 class="fs-12 font-poppins text-primary-one">
            {{ getLevelDefinition(item.current_prop_param) }}
          </h4>
        </div> -->
      </div>
      <div class="fs-10">
        <property
          :properties="getModulePropertyParams(item)"
          :activeScenarioCollection="item"
          :selectedPropSet="chartConfig.choosed_property_id"
        ></property>
      </div>
    </div>
  </div>
</template>

<script>
import Property from "@/components/brainstormv2/canvas/ScenarioItemProperty";
import { getFlattenCollections } from '@/store/modules/global/services';

import $ from "jquery";
import { mapGetters } from "vuex";
export default {
  name: "ChartFeature",
  components: {
    Property,
  },
  props: {
    title: String,
    item: {
      type: Object,
      required: true
    },
    module: {
      type: Object,
      required: true
    },
    moduleProperties: {
      type: Array
    },
    chartConfig: Object,
    flippedArray: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      definitionSets: "definition/definitionSets",
    }),
  },
  data() {
    return {
      activeDefinitionSet: 0,
    };
  },
  methods: {
    getLevelDefinition(value = 0) {
      let definitionSet = this.definitionSets[this.activeDefinitionSet];
      if (definitionSet) {
        let definition = definitionSet.items.find((data) => data.type === 2);
        if (definition) {
          if (value > 0) {
            value = value - 0.000001;
          }
          value = Math.floor(value / 2);
          if (value > 4) {
            value = 4;
          }
          let defText = definition.range_with_definition[value].definition;
          if (defText) {
            return defText;
          }
        }
      }
      return "Not found";
    },
    moveToNextDefinitionSet() {
      let length = this.definitionSets.length;
      if (length === this.activeDefinitionSet + 1) {
        this.activeDefinitionSet = 0;
      } else {
        this.activeDefinitionSet++;
      }
    },
    changedDataFromCollaboration(initial, item, value) {
      this.$emit("changedDataFromCollaboration", initial, item, value);
    },
    regularUpdate(e, item) {
      this.$emit("regularUpdate", e, item);
    },
    setCollectionProperty(property, item) {
      // console.log(property)
    },
    getModulePropertyParams(item) {
      let params = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(this.chartConfig.choosed_property_id)
      );
      if (params && params.property_collections.length > 0) {
        return params.property_collections.filter(
          (param) => !param.option || param.option == 1
        );
      }
      return [];
    },
    setCollectionPropertyParam(e, item) {
      this.setPropertyToOther(e, this.module)
      item.current_prop_param = parseFloat(
        this.getPropertyValueWithParam(item)
      );
      if(!this.flippedArray) return
      let data = this.flippedArray.find(
        (loopItem) => parseInt(loopItem.id) === parseInt(item.id)
      );
      if (data) {
        data.choosed_property_param_id = this.chartConfig.choosed_property_param_id;
      }
    },
    setPropertyToOther(e, module) {
      let allItem = getFlattenCollections(module.collections)
      allItem.forEach(item => this.chartConfig.choosed_property_param_id = e.target.value)
    },
    getPropertyValue(item) {
      let collection = this.module.properties.find(
        (prop) => parseInt(prop.id) === parseInt(this.chartConfig.choosed_property_id)
      );
      if (collection && collection.property_collections.length > 0) {
        let element = document.getElementById("chartcontent");
        if(!element) return
        let parameters = this.getSliderProperties(
          item,
          collection.property_collections
        );
        let values = parameters.filter((value) =>
          collection.property_collections.find(
            (prop_collection) =>
              parseInt(prop_collection.id) ===
              parseInt(value.property_collection_id)
          )
        );
        // let width = (element.clientWidth - values.length * 7) / values.length;
        let width = (92 - values.length * 7) / values.length;
        let max = 0;
        let sum = 0;
        values.forEach((value, index) => {
          value.width = width;
          item.max = false;
          if (value.sample_value > max) {
            max = value.sample_value;
            value.max = true;
            if (index > 0) {
              values[index - 1].max = false;
            }
          }
          sum += value.sample_value;
          if (index === 0) {
            item.current_prop_param = parseFloat(
              this.getPropertyValueWithParam(item)
            );
          }
        });
        item.average = sum / values.length;
        return values;
      }
      return [];
    },
    getPropertyValueWithParam(item) {
      let valueObj = item.properties.find(
        (value) =>
          parseInt(value.property_collection_id) ===
          parseInt(this.chartConfig.choosed_property_param_id)
      );
      if (valueObj) {
        return valueObj.sample_value;
      }
      return 0;
    },
    getSliderProperties(item, properties) {
      let sliderParams = properties
        .filter((data) => !data.option || data.option == 1)
        .map((param) => param.id);
      return item.properties.filter((data) =>
        sliderParams.includes(data.property_collection_id)
      );
    },
  },
};
</script>

<style scoped>
#chartcontent {
  height: 60px;
  width: 116px;
}
</style>
