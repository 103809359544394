import { fabric } from 'fabric'
import { toRaw } from "vue"

export function usePen() 
{
    const penStyles = ['Pencil', 'Circle', 'Spray', 'Pattern']

    let init = {
        canvas: null,
        brush: null,
        width: 1,
        color: '#000'
    }

    const initDrawing = (canvasObj) => 
    {
        init.canvas = canvasObj
        init.brush  = init.canvas.freeDrawingBrush
    }

    const setPenStyle = style => 
    {
        init.canvas.freeDrawingBrush = new fabric[style + 'Brush'](init.canvas)
        init.brush = init.canvas.freeDrawingBrush

        setPenColor(init.color)
        setPenSize(init.width)
    }

    const setPenSize  = (size)  => 
    {
        let activeObj = init.canvas.getActiveObject()
        if(activeObj && activeObj.type == 'path')
        {
            activeObj.set({strokeWidth: parseInt(size)})
            init.canvas.requestRenderAll()
        }

        init.width = init.brush.width = parseInt(size)
    }

    const setPenColor = (color) => {
        let activeObj = init.canvas.getActiveObject()
        if(activeObj && activeObj.type == 'path')
        {
            activeObj.set({stroke: color})
            init.canvas.requestRenderAll()
        }

        init.color = init.brush.color = color
    }

    return {
        initDrawing,
        penStyles,
        setPenStyle,
        setPenSize,
        setPenColor
    }
}