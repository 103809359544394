import { ref, computed } from 'vue'
import Chart from './assessment/Chart'
import Assessment from './assessment/Assessment'
import CoverImage from './assessment/CoverImage'
import TextEdit from '@/components/teamwork/brainstorm/assessment/TextEdit'
import { nextIndex  } from '@/plugins/lodash'

export default function useLevelAssessment() {
    const components = ref({TextEdit, Chart, Assessment, CoverImage})
    const activeComponentName = ref(null)
    const selectedComponent = computed(() => activeComponentName.value ? components.value[activeComponentName.value] : null)
    const setComponentName = (name) => activeComponentName.value = name

    const handleLevelComponentChange = (names) => {
        let componentName = activeComponentName.value
        if(componentName == null) {
            setComponentName(names[0])
            return
        } else {
            findComponentName(componentName, names)
        }
    }

    const findComponentName = (currentName, names) => {
        let currentIndex = names.indexOf(currentName)
        if(currentIndex == -1) setComponentName(names[0])
        if(currentIndex != -1) setComponentName(names[nextIndex(currentIndex, names.length)])
    }

    return {
        selectedComponent,
        setComponentName,
        handleLevelComponentChange,
    }
}