<template>
    <div class="i-right-panel canvasRightPanel" :style="{'--textColor':gridLayout?.settings?.textColor}">
        <div class="flex flex-col right-0 top-0 z-50 ws-heading-box absolute">
            <workspace-menu />
        </div>
        <div class="pl-10 pr-10 py-5 overflow-y-auto" style="height: 1080px;">
            <div class="flex justify-between items-center">
                <div class="absolute tool-pos z-50">
                    <div class="top_text flex gap-16 mb-6">
                        <div class="flex gap-1 items-center">
                            <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                            <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">
                                {{ project && project.scenario ? project.scenario.title : 'N/A' }}
                            </h2>
                        </div>
                        <!-- layout switcher start -->
                        <div class="flex gap-1 items-center">
                            <h2 class="fs-14 fw-800 text-primary-one">Layout: </h2>
                            <select @change="switchComponent" :value="activeLayout" class="custom_select fw-500 fs-14 bg-transparent pr-2">
                                <option value="0">-Select layout-</option>
                                <option v-for="layout in componentList" :key="layout.id" :value="layout.id">{{ layout.title }}</option>
                            </select>
                        </div>
                        <!-- layout switcher end -->
                    </div>

                    <TopMenu
                        :downloadCallBack="()=>{showModal=true}"
                        :clickNavigation="clickNavigation"
                        :navigationClicked="navigationClicked"
                        :rootData="activeBrainstormData"
                        @onResetZoom="resetZoom"
                        @onShare="$emit('onShare')"
                        :colorSet="colorChange"
                    >
                        <template #extra>
                            <DuplicateBorderedIcon
                                class="w-10 h-10 shadow-one"
                                title="Copy Scenario (Coming Soon)"
                            />

                            <MonitorBorderedIcon
                                @click.native="isVisualize = true; callBackToUpdateLayout()"
                                class="w-10 h-10 shadow-one"
                                title="Quick Visualize"
                            />
                        </template>
                    </TopMenu>
                </div>
            </div>

            <PanzoomComponent style="height: 900px;">
                <div class="flex justify-content-between w-full i-mt-140">
                    <component :gridLayout="gridLayout" :is="componentName" />
                </div>
                <AtomicArea
                    v-if="gridLayout"
                    :activeBrainstormData="activeBrainstormData"
                    :isolatedModule="isolatedModule"
                    :isolatedModules="isolatedModules"
                    :gridLayout="gridLayout"
                    :brainstormId="route.params.brainstorm_id"
                />
            </PanzoomComponent>
        </div>

        <Modal v-model="showModal" >
            <div class="bg-white relative overflow-y-auto" style="width: 600px;max-height: 920px;padding: 80px 30px;">
                <button @click="showModal = false" class="circle absolute -right-2 -top-2 transform translate-x-1/2 -translate-y-1/2" style="width: 200px; height: 200px;background: #E2E2E2;">
                    <Close class="w-3 h-3 absolute left-12 bottom-12" />
                </button>
                <div v-if="brainstorms.length > 1" class="body">
                    <template v-for="brainstorm in brainstorms">
                        <h2
                            @click="showModal = !showModal; cloneBrainstormsTags({activeBrainstormId:brainstormId, selectedBrainstormId:brainstorm.id}, callBackToUpdateLayout);"
                            v-if="brainstormId != brainstorm.id" :key="brainstorm.id"
                            class="fw-500 fs-16 opacity-70 hover:opacity-100 p-2 border border-transparent hover:border-primary-two cursor-pointer">{{ brainstorm.title }}</h2>
                    </template>
                </div>
                <div v-else class="body">
                    <h2 class="fw-500 fs-16 opacity-70 hover:opacity-100 p-2 border border-transparent hover:border-primary-two cursor-pointer">No Brainstorm Available!</h2>
                </div>
            </div>
        </Modal>

        <!-- visualize start -->
        <div v-if="isVisualize" class="bg-secondary-three fixed top-0 left-0 w-full h-full overflow-y-auto" style="z-index: 101; padding: 70px 92px 123px;">
            <h2 @click="isVisualize = false" class="fs-16 fw-400 absolute top-12 right-24 cursor-pointer">Back</h2>
            <h2 class="fs-24 fw-800 text-primary-one mb-8">Headline</h2>
            <div class="visualize_content_wrapper border border-white">
                <component :gridLayout="gridLayout" :is="componentName" :isVisualize='isVisualize' />
            </div>
            <div class="flex justify-end gap-7 buttons mt-6">
                <button @click="switchComponent(-1)" class="opacity-50 hover:opacity-100">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.18202 12.364L2 7.18202L7.18202 2" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 7.18213H14.2142" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button @click="switchComponent(+1)" class="opacity-50 hover:opacity-100">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.94531 12.364L14.1273 7.18202L8.94531 2" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1.91406 7.18213H14.1282" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
        <!-- visualize end -->
    </div>
</template>

<script setup>
    import axios from 'axios'
    import store from '@/store'
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import { onMounted, ref, computed } from 'vue';
    import router from '@/routes/routes.js';
    import TopMenu from '@/components/teamwork/TopMenu.vue'
    // import Zoomable from '@/elements/Zoomable'
    import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
    import Modal from "@/elements/atom/Modal"
    import usePanzoom from '@/elements/panzoom/usePanzoom.js'

    import { useBrainstorm } from '@/components/teamwork/brainstorm/brainstorm.js'

    const { getBrainstorms, cloneBrainstormsTags } = useBrainstorm()
    const route = router.currentRoute;
    const { resetZoom, getRef } = usePanzoom()

    // update content box text color
    let timeoutId = null
    const colorChange = (color) =>
    {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(async() => {
            gridLayout.value.settings = {textColor: color}
            await axios.post(`brainstorms/business-models/${gridLayout.value.id}/update`, gridLayout.value)
        }, 1000)
    }


    const gridLayout = ref({});
    const componentName = ref('twoBySevenLayout');

    const showModal    = ref(false)
    const isVisualize  = ref(false)
    const brainstorms  = ref(false)
    const projectId    = ref(route.params.id)
    const brainstormId = ref(route.params.brainstorm_id)
    const selectedItem = ref()

    const { getLayout, updateLayout, componentList, attachTagsAndAtomic  } = useCanvas();

    // eslint-disable-next-line no-undef
    const props = defineProps({
        isolatedModule: [Array, Number, Boolean],
        activeBrainstormData: Object,
        isolatedModules: Array,
        clickNavigation: Function,
        navigationClicked: Number
    });

    const project = computed(() => store.getters["project/project"])

    const switchComponent = (e) =>
    {
        let layoutData = {}
        if(e.target?.value){
            layoutData = componentList.find(layout=>{
                return (layout.id == e.target.value)
            })
        }else
        {
            let newId = gridLayout.value.active_layout
            if(gridLayout.value.active_layout + e > componentList.length){
                newId = 1
            }
            else if(gridLayout.value.active_layout + e < 1){
                newId = componentList.length
            }
            else{
                newId = gridLayout.value.active_layout + e
            }

            layoutData = componentList.find(layout=>{
                return (layout.id == newId)
            })
        }

        componentName.value = layoutData.component;
        gridLayout.value.active_layout = layoutData.id
        updateLayout(gridLayout.value.id, layoutData.id);
    }

    const activeLayout = computed(() => gridLayout.value?.active_layout || 0);

    onMounted(async () => {
        gridLayout.value = await getLayout(route.params.brainstorm_id);
        document.title = "Teamwork | Canvas";
        brainstorms.value = await getBrainstorms(projectId.value)

        // set active layout
        selectedItem.value = componentList.find(component => (component.id == gridLayout.value?.active_layout));
        componentName.value = selectedItem.value.component
        gridLayout.value.active_layout = selectedItem.value.id
    })

    const callBackToUpdateLayout = async () => {
        gridLayout.value = await getLayout(route.params.brainstorm_id)
    }
</script>

<script>
    import importer from "@/components/teamwork/canvas/importer";
    export default {
        name: "Canvas",
        components: importer,
    };
</script>

<style scoped>
    .custom_select{
        outline: none;
    }
    /* .i-right-panel{
        width: calc(1565px + 355px);
    }
    .i-left-panel+div > .i-right-panel{
        width: 1565px;
    } */
    .tool-pos {
        top: 48px;
    }
    .tools-panel {
        width: 860px;
    }
    .visualize_content_wrapper{
        width: 1736px;
    }

    .visualize_content_wrapper .layoutOneByFiveWrapper,
    .visualize_content_wrapper .layoutTwoBySevenWrapper>div {
        overflow-x: auto;
    }
    .visualize_content_wrapper .twoByTwoLayoutItem{
        width: 100% !important;
    }

/*     @media all and (max-width: 1850px) and (min-width: 1300px){
        .top_text{
            gap: 16px;
        }
        .visualize_content_wrapper{
            width: 100%;
        }
    } */
</style>

<style>
    .canvasRightPanel{
        width: 1565px;
    }
    .i-left-panel+div>.canvasRightPanel{
        width: 1565px;
    }
    .canvasRightPanel{
        width: 100%;
    }
</style>
