<template>
  <div class="flex items-center font-poppins fw-500">
        <ChevronSolidIcon @click.native="decrement" class="w-3 h-3 cursor-pointer" style="transform: rotate(90deg)" />
            <span class="mx-6px inline-block fs-16 font-light w-40 italic text-center truncate text-primary-one">{{ label }}</span>
        <ChevronSolidIcon @click.native="increment" class="w-3 h-3 cursor-pointer" style="transform: rotate(-90deg)" />
    </div>
</template>

<script setup>
    import { ref, computed, watch } from 'vue';
    // eslint-disable-next-line no-undef
    const emit = defineEmits(['onChange']);
    // eslint-disable-next-line no-undef
    const props = defineProps({
        items: Array,
        selectedIndex: [Number, String]
    });
    watch(
        () => props.selectedIndex,
        (value) => currentIndex.value = !isNaN(value) ? parseInt(value) : 0
    )
    const currentIndex = ref(0);
   
    const label = computed(() => {
        let item = props.items && props.items[currentIndex.value] || null;
        if(item) {
            emit('onChange', {component: item.component, currentIndex: currentIndex.value});
            return item.title;
        }
        return 'Component not found';
    })
    const increment = () => {
        if(currentIndex.value < props.items.length - 1) {
            currentIndex.value++;
        }else {
            currentIndex.value = 0;
        }
    }
    const decrement = () => {
        if(currentIndex.value == 0) {
            currentIndex.value = props.items.length - 1;
        }else {
            currentIndex.value--;
        }
    }
   
</script>