<template>
    <div
        @click="onSelect"
        class="relative content-box grid place-content-center cursor-pointer rounded py-5px px-5px"
        :style="[selectedTagsIds.includes(id) ? {borderWidth: '2px'} : {}]"
    >
        <svg v-if="canDelete(project.cans)" @click="$emit('deleteTag')" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="bg-white close_icon cursor-pointer absolute right-1 top-1 opacity-0" style="transform: scale(.7)"><path data-v-22e31d76="" d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#777"></path></svg>
        <h2
            style="color: var(--textColor)"
            @input="updateInfo"
            :contenteditable="canEdit(project.cans)"
            v-once
            class="panzoom-exclude font-poppins grid place-content-center select-none placeholder size overflow-hidden break-all fs-14 font-light text-center">{{ name }}</h2>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue';
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import { useTag } from "@/components/teamwork/canvas/useTag";
    import usePermission from '@/composable/usePermission.js'
    import store from '@/store'

    const { handleSelectedTag, selectedTagsIds } = useTag()
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

    const { updateTag } = useCanvas();

    // eslint-disable-next-line no-undef
    const props =  defineProps({
        title: {
            type: String,
            default: ''
        },
        id: {
            type: Number
        },
        brainstorm_business_model_layout_grid_category_id: {
            type: Number
        },
        settings: Object
    });
    const name          = ref(props.title);
    const truncate      = ref(true);

    const project = computed(()=>{
        return store.getters['project/project']
    })

    const toggleTruncate = (status) => {
        // truncate.value = !truncate.value;
        truncate.value = status;
    }

    const getTitle = (data, length=33) =>
    {
        if(!data) return;

        if(!truncate.value || data.length<length) {
            return data
        }

        return data.substr(0,length)+'...'
    }

    const onSelect = () => {
        handleSelectedTag({id: props.id, title: props.title, bgColor: props.settings.bg_color})
    }

    const updateInfo = (e) =>
    {
        name.value = e.target.innerText;
        updateTag(props.id, {
            brainstorm_business_model_layout_grid_category_id: props.brainstorm_business_model_layout_grid_category_id,
            id: props.id,
            title: name.value
        });
    }
</script>

<style scoped>
    .content-box {
        line-height: 21px;
        width: 120px;
        height: 100px;
        border: 0.5px solid #FFFFFF;
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
    .close_icon{
        border-radius: 50%;
    }
    .content-box:hover .close_icon{
        opacity: 0.6;
    }
    .size{
        min-height: 20px;
        width: 100px;
    }

    .px-5px{
        padding-left: 5px;
        padding-right: 5px;
    }
    .py-5px{
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .placeholder:empty::before{
        content: 'Add Tag...';
        color: #FFFFFF;
        display: block;
        text-align: center;
    }
</style>
