<template>
    <div class="flex justify-between w-full items-center">
        <div class="flex gap-x-1 -ml-2">
            <PlusCircleOutlineIcon @click.native="canCreate(project.cans) && createNewItem(item)" :class="[iconClass, dimensionClass]" />
            <MinusCircleIcon @click.native="canDelete(project.cans) && $emit('deleteItem', item.id)" :class="[iconClass, dimensionClass]" />
        </div>
        <div class="flex gap-x-3 items-center">
            <EditContentIcon :class="[iconClass]" class="h-4 w-4"  @click.native="handleLevelComponentChange(['TextEdit', null])" />
            <AssessmentIcon :class="[iconClass, dimensionClass]"  @click.native="handleLevelComponentChange(['Assessment', null])" />
            <CheckboxIcon :style="{fill: bgColor}" :class="[iconClass, dimensionClass]" @click.native="handleLevelComponentChange(['Chart', 'CoverImage', null])" />
        </div>
    </div>
</template>

<script setup>
import usePermission from '@/components/teamwork/brainstorm/usePermission.js'
import store from '@/store'

const { canEdit, canCreate, canDelete } = usePermission()
const iconClass = 'opacity-40 text-primary-one hover:opacity-100 transition duration-300 cursor-pointer'
const dimensionClass = 'h-3 w-3'
const props = defineProps({
    bgColor: String,
    item: Object,
    handleLevelComponentChange: Function,
    createNewItem: Function
})
const project = store.getters['project/project']
</script>
