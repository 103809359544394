<template>
    <div>
        <div class="flex">
            <span v-if="item.index_code && isIndexCodeVisible" class="mr-1 flex-shrink-0  fs-14 fw-700">{{ item.index_code }}</span>
            <TextInput ref="input" v-show="isInput" class="w-full  fs-14 fw-700 pr-1 panzoom-exclude" v-model="item.title"
                @input="updateInfo()" @blur="handleBar" :disabled="!canEdit(project.cans)" />
            <p v-show="!isInput" @click="handleShow" class="fs-14 fw-700 truncate"
                style="min-height: 20px; width: 164x; letter-spacing: 0.05em;">{{ item.title }}</p>
        </div>
        <div :style="{ height: getHeight(level, hasChild) }">
            <textarea
                :disabled="!canEdit(project.cans)"
                placeholder="No description"
                @input="updateInfo()"
                v-model="item.description"
                class="w-full h-full panzoom-exclude bg-transparent fs-12 fs-400 relative z-100"></textarea>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import TextInput from '@/components/communicator/templates/TextInput'
import usePermission from '@/components/teamwork/brainstorm/usePermission.js'

const { canEdit, canCreate, canDelete } = usePermission()
const isInput = ref(false)
const input = ref(null)

const props = defineProps({
    item: Object,
    project: Object,
    updateInfo: Function,
    level: [String, Number],
    hasChild: Boolean,
    isIndexCodeVisible: Boolean
})

function getHeight(level, hasChild) {
    if(level == 1 && hasChild) return '103px'
    return '176px'
}

function handleBar() {
    isInput.value = false
}

function handleShow() {
    isInput.value = true
    setTimeout(() => {
        input.value.$el.focus()
    }, 100)
}
</script>
