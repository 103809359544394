export default function useLevelDom() {
    const getWrapper = (target, className) => target.closest(className)

    const handleBringTop = (event, className) => {
        const levelElement = getWrapper(event.target, className)
        const level = levelElement.getAttribute('data-level')
        const clickFromIcon = event.target.classList.contains('back-icon')
        const onTop = levelElement.classList.contains('on-top')

        if(clickFromIcon) handleClickFromIcon(levelElement, level, onTop)
        if(!clickFromIcon && !onTop) handleClickFromBody(levelElement, level, onTop)
    }

    const handleClickFromBody = (levelElement, level, isOnTop) => {
        if(isOnTop) return
        removeFromTop(levelElement)
        hideAllFooter() // to remove previously-on-top items footer
        bringTop(levelElement, level)
        addFooter(levelElement)
    }

    const handleClickFromIcon = (levelElement, level, isOnTop) => {
        if(isOnTop) {
            removeFromTop(levelElement,)
        } else {
            removeFromTop(levelElement,)
            bringTop(levelElement, level)
            addFooter(levelElement)
        }
    }

    const bringTop = (levelElement, level) => {
        addClassToElement(levelElement, 'on-top')
        if(level == 2) {
            handleSecondLevelPosition(levelElement)
            return
        }
        if(level == 3) {
            handleThirdLevelPosition(levelElement)
            return
        }
        if(level == 4) {
            handleFourthLevelPosition(levelElement)
        }
    }
    
    const removeFromTop = (levelElement) => {
        hideFooter(levelElement)
        levelElement.classList.remove('on-top')
        classRemoveFromNodeList('-z-4-level')
        classRemoveFromNodeList('z-4-level')
    }

    const handleSecondLevelPosition = (levelElement) => {
        let children  = levelElement.getElementsByClassName('third-child-wrapper')
        classAddToNodeList('-z-4-level', children)
    }
    
    const handleThirdLevelPosition = (levelElement) => {
        let children  = levelElement.getElementsByClassName('fourth-child-wrapper')
        addClassToElement(getWrapper(levelElement, '.third-child-wrapper'), 'z-4-level')
        classAddToNodeList('-z-4-level', children)
    }
    
    const handleFourthLevelPosition = (levelElement) => {
        addClassToElement(levelElement, 'z-4-level')
    }

    
    const hideFooter = (levelElement) => {
        let footer = getLevelFooter(levelElement)
        footer.classList.add('hidden')
    }
    const addFooter = (levelElement) => {
        let footer = getLevelFooter(levelElement)
        footer.classList.remove('hidden')
    }

    const getLevelFooter = (levelElement) => {
        const footerId = 'footer-'+levelElement.getAttribute('data-id')
        return document.getElementById(footerId)
    }

    const hideAllFooter = () => {
        const footers = document.querySelectorAll('.level-footer-icon-wrapper')
        classAddToNodeList('hidden', footers)
    }

    const classRemoveFromNodeList = (className, nodes = null) => {
        const nodeList = nodes || document.querySelectorAll(`.${className}`)
        if(nodeList.length) {
            nodeList.forEach(child => {
                child.classList.remove(className)
            });
        }
    }
    
    const classAddToNodeList = (className, nodes = null) => {
        const nodeList = nodes || document.querySelectorAll(`.${className}`)
        if(nodeList.length) {
            nodeList.forEach(child => {
                child.classList.add(className)
            });
        }
    }

    const addClassToElement = (element, className) => setTimeout(() => {element.classList.add(className)}, 10)

    return {
        handleBringTop
    }
}