<template>
    <div class="flex items-center justify-between gap-2 text-gray-600 text-xs">
        <input v-model="size" @input="emit('Onchange', size)" type="range" :min="min" :max="max" :step="step" id="slider" />
        <span>{{ size }}</span>
    </div>
</template>

<script setup>
    import { ref } from "vue"

    // eslint-disable-next-line no-undef
    const props = defineProps({
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: null
        },
        step: {
            type: Number,
            default: 1
        },
        value: {
            type: Number,
            default: 0
        }
    });
    const size = ref(props.value)

    // eslint-disable-next-line no-undef
    const emit = defineEmits(['Onchange']);
</script>

<style scoped>
    #slider {
        -webkit-appearance: none;
        appearance: none;
        height: 2px !important;
        width: 100%;
        border-radius: 2px;
        background-color: #888;
        outline: none;
    }

    #slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 16px;
        height: 6px;
        border-radius: 2px;
        background: #ff7300;
        cursor: pointer;
        border: 1px solid #ff7300;
    }

    #slider::-moz-range-thumb {
        width: 16px;
        height: 6px;
        border-radius: 2px;
        background: #ff7300;
        cursor: pointer;
        border: 1px solid #ff7300;
    }
</style>