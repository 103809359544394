<template>
  <div :class="[isVisualize&&'visualizeMode', 'canvasStyle_'+canvasStyle, verticalDefinitionItems&&'hasVerticalDefinitionItems', horizontalDefinitionItems&&'hasHorizontalDefinitionItems']">
    <div
      v-if="conceptData && conceptData.id && !isVisualize"
      class="-mt-6 flex items-end justify-between"
      :class="{
        'canvas-serial': canvasStyle !== 2,
        'canvas-serial-logic': canvasStyle === 2,
      }"
    >
      <h2 class="fs-14 text-primary-one fw-bold -mb-1">
        Concept Canvas: {{ canvasSerial }}
        {{ canvasData ? "- " + canvasData.title : "" }}
      </h2>
      <div class="flex gap-5 rangeSliderWrapper ml-auto w-370px">
        <div class="w-full">
          <h2 class="mb-2 fs-12 fw-500 text-secondary-five">Horizontal</h2>
          <div class="px-2">
            <RangeSlider
              :min="0"
              :max="10"
              :step="1"
              :ruler="false"
              :label="false"
              :minValue="0"
              :maxValue="10"
              @input="(value)=>{horizontal_filter = value}" />
          </div>
        </div>
        <div class="w-full mr-1">
          <h2 class="mb-2 fs-12 fw-500 text-secondary-five">Vertical</h2>
          <div class="px-2">
            <RangeSlider
              :min="0"
              :max="10"
              :step="1"
              :ruler="false"
              :label="false"
              :minValue="0"
              :maxValue="10"
              @input="(value)=>{vertical_filter = value}" />
          </div>
        </div>
      </div>
    </div>


    <div class="fs-14 font-poppins flex mt-2 full-canvas" :class="!canEdit(project.cans)&&'pointer-events-none'">


      <!-- horizontal low heigh tex start -->
        <div
          class="flex flex-col justify-between vertical-line-height rotate-180"
          :class="{
            'vertical-line-height-side': canvasStyle === 2,
            'vertical-line-def': canvasStyle === 3,
          }"
          v-if="conceptData && conceptData.definitions"
        >
          <p
            class="vertical-lr primary fw-600 h-full text-center"
            v-for="(item, index) in conceptData.definitions[1]"
            :key="index"
          >
            {{ item }}
          </p>
        </div>
      <!-- horizontal low heigh tex end -->


      <div class="w-full">
        <div class="canvas-height relative pt-3" id="canvas_height">
          <div class="grid layoutWrapper">
            <div
              class="leftLevelRange flex flex-col-reverse w-full vertical-number-height justify-between items-end pb-5 mr-3 w-10"
              :class="{
                'vertical-number-height-full': navigationClicked === 2,
                'vertical-number-def': canvasStyle === 3,
                'w-70px': !isVisualize,
                '-ml-5': isVisualize
              }"
            >
              <p class="text-secondary-two pr-2 relative">0 <!-- <span v-if="!isVisualize" class="fs-16 fw-600 absolute transform -rotate-90 -left-12 bottom-10 origin-left text-primary-one whitespace-nowrap">Level 1</span> --></p>
              <p class="text-secondary-two pr-2 relative">2 <!-- <span v-if="!isVisualize" class="fs-16 fw-600 absolute transform -rotate-90 -left-12 bottom-10 origin-left text-primary-one whitespace-nowrap">Level 2</span> --></p>
              <p class="text-secondary-two pr-2 relative">4 <!-- <span v-if="!isVisualize" class="fs-16 fw-600 absolute transform -rotate-90 -left-12 bottom-10 origin-left text-primary-one whitespace-nowrap">Level 3</span> --></p>
              <p class="text-secondary-two pr-2 relative">6 <!-- <span v-if="!isVisualize" class="fs-16 fw-600 absolute transform -rotate-90 -left-12 bottom-10 origin-left text-primary-one whitespace-nowrap">Level 4</span> --></p>
              <p class="text-secondary-two pr-2 relative">8 <!-- <span v-if="!isVisualize" class="fs-16 fw-600 absolute transform -rotate-90 -left-12 bottom-10 origin-left text-primary-one whitespace-nowrap">Level 5</span> --></p>
              <p class="text-secondary-two pr-2">10</p>
            </div>

            <div>
              <div class="relative">
                <div class="flex">
                  <div
                    class="graph-layout relative"
                    :class="{
                      'graph-layout-full': navigationClicked === 2,
                      'graph-layout-side-by-side': canvasStyle === 2,
                      'graph-layout-definition': canvasStyle === 3,
                    }"
                    id="graph_layout"
                    style="z-index: 1"
                    :style="{width: isVisualize&&'100%'}"
                  >
                    <div class="flex h-1/2 relative chart-hints-upper">
                      <div
                        class="absolute top-0 left-0 right-0 flex justify-between w-full p-6 z-50">
                        <input
                          type="text"
                          class="bg-transparent text-white placeholder-primary-three fs-14 fw-bold"
                          :disabled='isVisualize'
                          placeholder="Add text"
                          v-model="getHintsText[0]"
                          @change="hintsUpdate()"
                        />
                        <input
                          type="text"
                          class="bg-transparent text-white placeholder-primary-three fs-14 fw-bold text-right"
                          :disabled='isVisualize'
                          placeholder="Add text"
                          v-model="getHintsText[1]"
                          @change="hintsUpdate()"
                        />
                      </div>
                    </div>

                    <div class="flex h-1/2 relative chart-hints-lower">
                      <div class="absolute bottom-0 left-0 right-0 flex justify-between w-full p-6 z-50">
                        <input
                          type="text"
                          class="bg-transparent text-white placeholder-primary-three fs-14 fw-bold"
                          :disabled='isVisualize'
                          placeholder="Add text"
                          v-model="getHintsText[2]"
                          @change="hintsUpdate()"
                        />
                        <input type="text" class="bg-transparent text-white placeholder-primary-three fs-14 fw-bold text-right"
                          :disabled='isVisualize'
                          placeholder="Add text"
                          v-model="getHintsText[3]"
                          @change="hintsUpdate()"
                        />
                      </div>
                    </div>

                    <div class="absolute" style="z-index: 0; top: 0; width: 100%; opacity: 0.35"
                      v-if="
                        conceptData &&
                        conceptData.id &&
                        collections &&
                        canvasStyle !== 0 &&
                        canvasStyle !== 3
                      "
                    >
                      <div class="flex justify-between">
                        <div class="canvas4-quadrant relative  i-border-2 border-primary-three"
                          :data-firstletter="getHintsText[0] && getHintsText[0].length > 0 ? getHintsText[0][0].toUpperCase() : 'A'"
                          :style="{ background: quadrantColors[0] }"
                        ></div>
                        <div
                          class="canvas4-quadrant relative canvas-mr i-border-2 border-left-0 border-primary-three"
                          :data-firstletter="getHintsText[1] && getHintsText[1].length > 0 ? getHintsText[1][0].toUpperCase() : 'A'"
                          :style="{ background: quadrantColors[1] }"
                        ></div>
                      </div>
                      <div class="flex justify-between">
                        <div class="canvas4-quadrant relative  i-border-2 border-top-0 border-primary-three"
                          :data-firstletter="getHintsText[2] && getHintsText[2].length > 0 ? getHintsText[2][0].toUpperCase() : 'A'"
                          :style="{ backgroundColor: quadrantColors[2], '--bgImage': '', '--bgOpacity': ''}"
                        ></div>
                        <div class="canvas4-quadrant relative  canvas-mr i-border-2 border-top-0 border-left-0 border-primary-three"
                          :data-firstletter="getHintsText[3] && getHintsText[3].length > 0 ? getHintsText[3][0].toUpperCase() : 'A'"
                          :style="{ backgroundColor: quadrantColors[3], '--bgImage': backgroundImages&&`url(${backgroundImages[0]?.bg})`, '--bgOpacity': backgroundImages&&backgroundImages[0]?.opacity }"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="hor-definitions" v-if="canvasStyle === 3">
                    <div class="flex flex-col h-full bg-#f0f0f0">
                      <div class="hor-definition text-primary-one i-border-b-1 border-white flex text-left items-center fs-12 h-full"
                        v-for="(
                          definitionItem, dIndex
                        ) in getHorizontalDefinitionItems(2)"
                        :key="dIndex"
                        :class="{ 'i-border-t-1': dIndex === 0 }"
                      >
                        {{ definitionItem.definition }}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-for="(l1, l1Index) in getData(collections)"
                  :key="l1Index"
                >
                  <template
                    v-if="(getXValue(l1)>=horizontal_filter.minValue)
                          && (getXValue(l1)<=horizontal_filter.maxValue)
                          && (getYValue(l1)>=vertical_filter.minValue)
                          && (getYValue(l1)<=vertical_filter.maxValue)
                  ">
                    <div class="absolute l1div text-primary-three flex items-center justify-center fw-600 cursor-pointer i-border-1 border-transparent shadow-two"
                      :id="'l1_' + l1.id + '_' + canvasSerial"
                      v-if="filterLevel[0].visibility"
                      @click="setActiveData(l1)"
                      @dblclick="lineManagement(l1, [])"
                      :class="{ movable: l1.child.length <= 0 }"
                      :style="{
                        left: getPosition(l1, 0, 40) + 'px',
                        bottom: getPosition(l1, 1, 40) + 'px',
                        background: getBg(1, l1),
                      }"
                    >
                      <span class="fs-12">{{ getRootIndexNumber(1, l1Index) }}</span>
                      <div
                        class="absolute info-box bg-primary-three shadow-one rounded"
                        :class="{
                          'in-left-1': getXValue(l1) > 7 || canvasSerial === 2,
                          'in-right-1': getXValue(l1) <= 7 && canvasSerial !== 2,
                        }"
                        v-if="
                          (Object.keys(activeItem).length > 0 &&
                            activeItem.id === l1.id) ||
                          viewDetails
                        "
                      >
                        <div
                          class="flex items-center"
                          :class="{
                            'py-1 px-2': !viewDetails,
                            'px-1': viewDetails,
                          }"
                        >
                          <div
                            class="square-box"
                            :style="{ background: getBg(1, l1) }"
                          />
                          <h4
                            class="text-secondary-one"
                            :class="{
                              'fs-10 ml-1': viewDetails,
                              'fs-12 ml-2': !viewDetails,
                            }"
                          >
                            {{ getIndexId(1) }}{{ getRootIndexNumber(1, l1Index) }} {{ l1.title }}
                          </h4>
                        </div>
                      </div>
                      <div
                        class="absolute drag-pointer-1 w-5 h-5 z-10"
                        :class="{ hidden: l1.child.length > 0 }"
                        :id="'l1_' + l1.id + '_drag'"
                        v-if="
                          Object.keys(activeItem).length > 0 &&
                          activeItem.id === l1.id
                        "
                      >
                        <img
                          src="/images/icons/brainstormV2/pointer.svg"
                          alt="image"
                        />
                      </div>
                    </div>

                    <div
                      v-for="(l2, l2Index) in getData(l1.child)"
                      :key="l2Index"
                      class="relative"
                    >
                      <template
                        v-if="(getXValue(l2)>=horizontal_filter.minValue)
                              && (getXValue(l2)<=horizontal_filter.maxValue)
                              && (getYValue(l2)>=vertical_filter.minValue)
                              && (getYValue(l2)<=vertical_filter.maxValue)
                      ">
                        <div class=" absolute l2div text-prima movablery-three flex items-center justify-center fw-600 cursor-pointer i-border-1 border-transparent shadow-two"
                          :id="'l2_' + l2.id + '_' + canvasSerial"
                          v-if="filterLevel[1].visibility"
                          @click="setActiveData(l2)"
                          @dblclick="lineManagement(l2, [l1.id])"
                          :class="{ movable: l2.child.length <= 0 }"
                          :style="{
                            left: getPosition(l2, 0, 35) + 'px',
                            bottom: getPosition(l2, 1, 35) + 'px',
                            background: getBg(2, l2),
                          }"
                        >
                          <span class="fs-12 text-primary-three">{{ l1Index + 1 }}.{{ l2Index + 1 }}</span>
                          <div
                            class="
                              absolute
                              info-box
                              bg-primary-three
                              shadow-one
                              rounded
                            "
                            :class="{
                              'in-left-2': getXValue(l2) > 7 || canvasSerial === 2,
                              'in-right-2':
                                getXValue(l2) <= 7 && canvasSerial !== 2,
                            }"
                            v-if="
                              (Object.keys(activeItem).length > 0 &&
                                activeItem.id === l2.id) ||
                              viewDetails
                            "
                          >
                            <div
                              class="flex items-center"
                              :class="{
                                'py-1 px-2': !viewDetails,
                                'px-1': viewDetails,
                              }"
                            >
                              <div
                                class="square-box"
                                :style="{ background: getBg(2, l2) }"
                              />
                              <h4
                                class="text-secondary-one"
                                :class="{
                                  'fs-10 ml-1': viewDetails,
                                  'fs-12 ml-2': !viewDetails,
                                }"
                              >
                                {{ getIndexId(2)
                                }}{{ getRootIndexNumber(1, l1Index) }}.{{
                                  l2Index + 1
                                }}
                                {{ l2.title }}
                              </h4>
                            </div>
                          </div>

                          <div
                            class="absolute drag-pointer-2 w-5 h-5 z-10"
                            :class="{ hidden: l2.child.length > 0 }"
                            :id="'l2_' + l2.id + '_drag'"
                            v-if="
                              Object.keys(activeItem).length > 0 &&
                              activeItem.id === l2.id
                            "
                          >
                            <img
                              src="/images/icons/brainstormV2/pointer.svg"
                              alt="image"
                            />
                          </div>
                        </div>

                        <div
                          v-for="(l3, l3Index) in getData(l2.child)"
                          :key="l3Index"
                        >
                          <template
                            v-if="(getXValue(l3)>=horizontal_filter.minValue)
                                  && (getXValue(l3)<=horizontal_filter.maxValue)
                                  && (getYValue(l3)>=vertical_filter.minValue)
                                  && (getYValue(l3)<=vertical_filter.maxValue)
                          ">
                            <div
                              class="
                                absolute
                                l3div
                                text-primary-three
                                flex
                                items-center
                                justify-center
                                fw-600
                                cursor-pointer
                                i-border-1
                                border-transparent
                                shadow-two
                              "
                              :id="'l3_' + l3.id + '_' + canvasSerial"
                              v-if="filterLevel[2].visibility"
                              @click="setActiveData(l3)"
                              @dblclick="lineManagement(l3, [l1.id, l2.id])"
                              :class="{ movable: l3.child.length <= 0 }"
                              :style="{
                                left: getPosition(l3, 0, 30) + 'px',
                                bottom: getPosition(l3, 1, 30) + 'px',
                                background: getBg(3, l2),
                              }"
                            >
                              <span class="fs-12">{{ l1Index + 1 }}.{{ l2Index + 1 }}.{{ l3Index + 1 }}</span>
                              <div
                                class="
                                  absolute
                                  info-box
                                  bg-primary-three
                                  shadow-one
                                  rounded
                                "
                                :class="{
                                  'in-left-3':
                                    getXValue(l3) > 7 || canvasSerial === 2,
                                  'in-right-3':
                                    getXValue(l3) <= 7 && canvasSerial !== 2,
                                }"
                                v-if="
                                  (Object.keys(activeItem).length > 0 &&
                                    activeItem.id === l3.id) ||
                                  viewDetails
                                "
                              >
                                <div
                                  class="flex items-center"
                                  :class="{
                                    'py-1 px-2': !viewDetails,
                                    'px-1': viewDetails,
                                  }"
                                >
                                  <div
                                    class="square-box"
                                    :style="{ background: getBg(3, l3) }"
                                  />
                                  <h4
                                    class="text-secondary-one"
                                    :class="{
                                      'fs-10 ml-1': viewDetails,
                                      'fs-12 ml-2': !viewDetails,
                                    }"
                                  >
                                    {{ getIndexId(3)
                                    }}{{ getRootIndexNumber(1, l1Index) }}.{{
                                      l2Index + 1
                                    }}.{{ l3Index + 1 }} {{ l3.title }}
                                  </h4>
                                </div>
                              </div>
                              <div
                                class="absolute drag-pointer-3 w-5 h-5 z-10"
                                :class="{ hidden: l3.child.length > 0 }"
                                :id="'l3_' + l3.id + '_drag'"
                                v-if="
                                  Object.keys(activeItem).length > 0 &&
                                  activeItem.id === l3.id
                                "
                              >
                                <img
                                  src="/images/icons/brainstormV2/pointer.svg"
                                  alt="image"
                                />
                              </div>
                            </div>
                            <div
                              v-for="(l4, l4Index) in getData(l3.child)"
                              :key="l4Index"
                            >
                              <template
                                v-if="(getXValue(l4)>=horizontal_filter.minValue)
                                      && (getXValue(l4)<=horizontal_filter.maxValue)
                                      && (getYValue(l4)>=vertical_filter.minValue)
                                      && (getYValue(l4)<=vertical_filter.maxValue)
                              ">
                                <div
                                  class="
                                    absolute
                                    l4div
                                    text-primary-three
                                    flex
                                    items-center
                                    justify-center
                                    fw-600
                                    cursor-pointer
                                    i-border-1
                                    border-transparent
                                    movable
                                    shadow-two
                                  "
                                  :id="'l4_' + l4.id + '_' + canvasSerial"
                                  @click="setActiveData(l4)"
                                  @dblclick="lineManagement(l4, [l1.id, l2.id, l3.id])"
                                  v-if="filterLevel[3].visibility"
                                  :style="{
                                    left: getPosition(l4, 0, 25) + 'px',
                                    bottom: getPosition(l4, 1, 25) + 'px',
                                    background: getBg(4, l4),
                                  }"
                                >
                                  <span class="fs-12"
                                    >{{ l1Index + 1 }}.{{ l2Index + 1 }}.{{
                                      l3Index + 1
                                    }}.{{ l4Index + 1 }}</span
                                  >
                                  <div
                                    class="
                                      absolute
                                      info-box
                                      bg-primary-three
                                      shadow-one
                                      rounded
                                    "
                                    :class="{
                                      'in-left-4':
                                        getXValue(l4) > 7 || canvasSerial === 2,
                                      'in-right-4':
                                        getXValue(l4) <= 7 && canvasSerial !== 2,
                                    }"
                                    v-if="
                                      (Object.keys(activeItem).length > 0 &&
                                        activeItem.id === l4.id) ||
                                      viewDetails
                                    "
                                  >
                                    <div
                                      class="flex items-center"
                                      :class="{
                                        'py-1 px-2': !viewDetails,
                                        'px-1': viewDetails,
                                      }"
                                    >
                                      <div
                                        class="square-box"
                                        :style="{ background: getBg(4, l4) }"
                                      />
                                      <h4
                                        class="text-secondary-one"
                                        :class="{
                                          'fs-10 ml-1': viewDetails,
                                          'fs-12 ml-2': !viewDetails,
                                        }"
                                      >
                                        {{ getIndexId(4)
                                        }}{{ getRootIndexNumber(1, l1Index) }}.{{
                                          l2Index + 1
                                        }}.{{ l3Index + 1 }}.{{ l4Index + 1 }}
                                        {{ l4.title }}
                                      </h4>
                                    </div>
                                  </div>
                                  <div
                                    class="absolute drag-pointer-4 z-10 w-5 h-5"
                                    :id="'l4_' + l4.id + '_drag'"
                                    v-if="
                                      Object.keys(activeItem).length > 0 &&
                                      activeItem.id === l4.id
                                    "
                                  >
                                    <img
                                      src="/images/icons/brainstormV2/pointer.svg"
                                      alt="image"
                                    />
                                  </div>
                                </div>
                              </template>
                            </div>
                          </template>
                        </div>
                      </template>
                    </div>
                  </template>
                </div>

                <div v-if="isVisualize && (canvasStyle === 0 || canvasStyle === 1)" class="absolute hor-def-sec">
                  <div class="flex justify-between items-center">
                    <div class="flex">
                      <h4 class="fs-12 text-secondary-two m-2 def-title"
                        :content="getDefinition(1) ? getDefinition(1).title : 'N/A'"
                        v-tippy="{ placement: 'top', arrow: true }"
                      >
                        {{ getDefinition(1) ? getDefinition(1).title : "" }}
                      </h4>
                    </div>

                    <div class="flex gap-1 items-center relative z-10">
                      <img
                        src="/images/icons/left_arrow_black.svg"
                        alt="icon"
                        class="def-next-icon cursor-pointer opacity-25"
                        :class="{
                          'pointer-events-none':
                            definitionActiveIndex.horizontal === 0,
                        }"
                        style="transform: scale(1.5);"
                        @click="
                          definitionActiveIndex.horizontal =
                            definitionActiveIndex.horizontal - 1
                        "
                      />
                      <img
                        src="/images/icons/left_arrow_black.svg"
                        alt="icon"
                        class="ml-2 def-next-icon cursor-pointer opacity-25"
                        style="margin-top: -2px; transform: scale(1.5) rotate(180deg);"
                        :class="{
                          'pointer-events-none': horizontalNextDisability(1),
                        }"
                        @click="
                          definitionActiveIndex.horizontal =
                            definitionActiveIndex.horizontal + 1
                        "
                      />
                    </div>
                  </div>
                </div>

                <div v-if="isVisualize && (canvasStyle === 0 || canvasStyle === 1)" class="absolute ver-def-sec">
                  <div class="flex justify-between items-center">
                    <div class="flex">
                      <h4
                        class="fs-12 text-secondary-two m-2 def-title"
                        :content="
                          getDefinition(2) ? getDefinition(2).title : 'N/A'
                        "
                        v-tippy="{ placement: 'top', arrow: true }"
                      >
                        {{ getDefinition(2) ? getDefinition(2).title : "" }}
                      </h4>
                    </div>
                    <div class="flex gap-1 items-center z-10">
                      <img
                        src="/images/icons/left_arrow_black.svg"
                        alt="icon"
                        class="def-next-icon cursor-pointer opacity-25"
                        :class="{
                          'pointer-events-none':
                            definitionActiveIndex.vertical === 0,
                        }"
                        style="transform: scale(1.5)"
                        @click="
                          definitionActiveIndex.vertical =
                            definitionActiveIndex.vertical - 1
                        "
                      />
                      <img
                        src="/images/icons/left_arrow_black.svg"
                        alt="icon"
                        class="ml-2 def-next-icon cursor-pointer opacity-25"
                        style="margin-top: -2px; transform: scale(1.5) rotate(180deg);"
                        :class="{
                          'pointer-events-none': horizontalNextDisability(2),
                        }"
                        @click="
                          definitionActiveIndex.vertical =
                          definitionActiveIndex.vertical + 1
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="bottomLevelNumber flex justify-between pt-3" :class="{ 'w-h-def': canvasStyle === 3 }">
                <p class="w-full text-secondary-two flex-shrink-0 relative">0<!--  <span :class="isVisualize&&'hidden'" class="fs-16 fw-600 absolute left-1/2 transform -translate-x-1/2 -bottom-10 text-primary-one whitespace-nowrap">Level 1</span> --></p>
                <p class="w-full text-secondary-two flex-shrink-0 relative">2<!--  <span :class="isVisualize&&'hidden'" class="fs-16 fw-600 absolute left-1/2 transform -translate-x-1/2 -bottom-10 text-primary-one whitespace-nowrap">Level 2</span> --></p>
                <p class="w-full text-secondary-two flex-shrink-0 relative">4<!--  <span :class="isVisualize&&'hidden'" class="fs-16 fw-600 absolute left-1/2 transform -translate-x-1/2 -bottom-10 text-primary-one whitespace-nowrap">Level 3</span> --></p>
                <p class="w-full text-secondary-two flex-shrink-0 relative">6<!--  <span :class="isVisualize&&'hidden'" class="fs-16 fw-600 absolute left-1/2 transform -translate-x-1/2 -bottom-10 text-primary-one whitespace-nowrap">Level 4</span> --></p>
                <p class="w-full text-secondary-two flex-shrink-0 relative">8<!--  <span :class="isVisualize&&'hidden'" class="fs-16 fw-600 absolute left-1/2 transform -translate-x-1/2 -bottom-10 text-primary-one whitespace-nowrap">Level 5</span> --></p>
                <p class="w-full text-secondary-two flex-shrink-0">10</p>
              </div>


              <div v-if="canvasStyle === 3" class="ver-definitions flex">
                <div
                  class="ver-definition i-border-r-1 border-white fs-12"
                  v-for="(definitionItem, dIndex) in getHorizontalDefinitionItems(1)"
                  :key="dIndex"
                  :class="{ 'i-border-l-1': dIndex === 0 }"
                >
                  {{ definitionItem.definition }}
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- vertical low heigh tex start -->
        <div
          class="flex w-full pt-5 justify-between horizontal-lines"
          v-if="conceptData && conceptData.definitions && canvasStyle !== 3"
        >
          <h4
            class="text-primary-one fw-600 w-full"
            v-for="(item, index) in conceptData.definitions[0]"
            :key="index"
          >
            {{ item }}
          </h4>
        </div>
        <!-- vertical low heigh tex end -->


      </div>
    </div>
  </div>
</template>

<script setup>
  import RangeSlider from '@/components/roadmap/RangeSlider.vue'
  import usePermission from '@/composable/usePermission.js'
  const { canRead, canEdit, canCreate, canDelete } = usePermission()
</script>

<script>
import LeaderLine from "leader-line-vue";
import { mapGetters } from "vuex";

export default {
  name: "Layout",
  props: [
    "navigationClicked",
    "filterLevel",
    "isolatedModules",
    "quadrantColors",
    "pickerUpdated",
    "canvasSerial",
    "canvasData",
    "viewDetails",
    "isVisualize",
    "backgroundImages",
    "verticalDefinitionItems",
    "horizontalDefinitionItems",
  ],
  data() {
    return {
      horizontal_filter: { minValue: 0, maxValue: 10 },
      vertical_filter: { minValue: 0, maxValue: 10 },
      layoutOutsider: [30, 32],
      layoutSize: [650, 701],
      item: null,
      lines: [],
      freeTitles: [
        ["Identification", "Identifier", "Area", "Area Measure", "Measure"],
        ["Capability", "Capability Group", "Area", "Area Measure", "Measure"],
        ["Enabler", "Enabler Group", "Area", "Area Measure", "Measure"],
        ["Design", "Design Group", "Area", "Area Measure", "Measure"],
        ["Programme", "Programme Group", "Area", "Area Measure", "Measure"],
        ["Plan", "Plan Group", "Area", "Area Measure", "Measure"],
        ["Solution", "Solution Group", "Area", "Area Measure", "Measure"],
        ["Effect", "Effect Group", "Area", "Area Measure", "Measure"],
        ["Outcome", "Outcome Group", "Area", "Area Measure", "Measure"],
      ],
      defaultColors: ["#2B80AD", "#3D6A83", "#6A7881", "#818487"],
      coneptsId: [],
      hintsUpdated: false,
      lineManagementsData: [],
      tempIds: [],
    };
  },
  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      conceptData: "concept/concept",
      colorCodes: "global/colorCodes",
      activeModuleCollection: "build/activeModuleCollection",
      activeScenarioCollection: "programmatic/activeScenarioCollection",
      conceptModule: "concept/canvasFirstModule",
      canvasPropValue: "concept/canvasPropValue",
      definitions: "concept/definitions",
      definitionActiveIndex: "concept/definitionActiveIndex",
      project: "project/project",
    }),
    canvasStyle() {
      return this.conceptData.style;
    },

    collections() {
      if (this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
        if (this.canvasStyle === 2) {
          if (
            this.canvasData &&
            this.canvasData.collections &&
            this.canvasData.collections.length > 0
          ) {
            return this.canvasData.collections;
          }
          return [];
        } else {
          if (this.conceptModule) {
            let targetModule = this.activeBrainstormData.modules.find(
              (item) => parseInt(item.id) === parseInt(this.conceptModule.id)
            );
            if (targetModule) {
              return targetModule.collections;
            }
          }
        }
        return [];
      } else {
        if (
          this.activeBrainstormData &&
          this.activeBrainstormData.collections &&
          this.conceptData &&
          this.conceptData.hr_property_collection_id &&
          this.conceptData.vr_property_collection_id
        ) {
          return this.activeBrainstormData.collections;
        }
        return [];
      }
    },

    moduleSettings() {
      if (this.activeBrainstormData.scenario_id) {
        if (this.canvasStyle === 2) {
          if (this.canvasData) {
            let settings = JSON.parse(this.canvasData.collection_settings);
            if (settings) {
              return settings;
            }
          }
          return {};
        } else {
          let targetModule = this.activeBrainstormData.modules.find(
            (item) => parseInt(item.id) === parseInt(this.conceptModule.id)
          );
          if (targetModule) {
            let settings = JSON.parse(targetModule.collection_settings);
            if (settings) {
              return settings;
            }
          }
          return {};
        }
      } else {
        if (this.activeBrainstormData.module) {
          let settings = JSON.parse(
            this.activeBrainstormData.module.collection_settings
          );
          if (settings) {
            return settings;
          }
          return {};
        }
        return {};
      }
    },

    getModule() {
      if (this.activeBrainstormData.scenario_id) {
        if (this.canvasStyle === 2) {
          return this.canvasData;
        } else {
          if (this.conceptModule) {
            return this.activeBrainstormData.modules.find(
              (item) => parseInt(item.id) === parseInt(this.conceptModule.id)
            );
          }
          return null;
        }
      } else {
        if (this.activeBrainstormData.module) {
          return this.activeBrainstormData.module;
        }
        return null;
      }
    },
    activeItem() {
      if (this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
        return this.activeScenarioCollection;
      } else {
        return this.activeModuleCollection;
      }
    },
    getHintsText() {
      if (this.canvasSerial !== 2) {
        if (this.conceptData && this.conceptData.hints_text) {
          return this.conceptData.hints_text;
        }
        return ["", "", "", ""];
      } else {
        if (this.conceptData && this.conceptData.hints_text_c_2) {
          return this.conceptData.hints_text_c_2;
        }
        return ["", "", "", ""];
      }
    },
  },
  watch: {
    navigationClicked: function (val) {
      // let width = document.getElementById("graph_layout").offsetWidth;
      // let height = document.getElementById("graph_layout").offsetHeight;
      // this.layoutSize = [width, height];
      // if(val === 2) {
      //   this.layoutSize = [1721, 791];
      // } else {
      //   this.layoutSize = [1361, 701];
      // }
    },
    getHintsText: function (val) {
      this.hintsUpdated = true;
    },
  },
  methods: {
    ifNotInLine(id) {
      return (
        this.lineManagementsData.length > 0 &&
        !this.lineManagementsData.includes(id)
      );
    },

    hintsUpdate() {
      this.hintsUpdated = true;
      if (this.conceptData) {
        let data = {
          id: this.conceptData.id,
          title: this.conceptData.title,
          project_id: this.$route.params.id,
          brainstorm_id: this.$route.params.brainstorm_id,
        };

        if (this.canvasSerial === 1) {
          data.hints_text = this.getHintsText;
        } else {
          data.hints_text_c_2 = this.getHintsText;
        }

        this.$store.dispatch("concept/updateConcept", data);
      }
    },

    getXValue(item) {
      let xObj = item.properties.find(
        (hr) =>
          parseInt(hr.property_collection_id) ===
          parseInt(this.conceptData.hr_property_collection_id)
      );
      return xObj ? xObj.sample_value : 0;
    },

    getYValue(item) {
      let yObj = item.properties.find(
        (vr) =>
          parseInt(vr.property_collection_id) ===
          parseInt(this.conceptData.vr_property_collection_id)
      );
      return yObj ? yObj.sample_value : 0;
    },

    getPosition(item, pos, selfMargin) {
      let canvasWidth = this.layoutSize[0];
      let canvasHeight = this.layoutSize[1];
      if (this.canvasStyle !== 2) {
        canvasWidth = this.canvasStyle === 3 ? 1162 : canvasWidth * 2 + 61;
        if (this.canvasStyle === 3) {
          canvasHeight = 641;
        }
      }

      let level = item.level;
      let id = item.id;

      let tempX = 0;
      let tempY = 0;

      if (this.canvasStyle !== 2) {
        tempX = item.properties.find(
          (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(this.conceptData.hr_property_collection_id)
        );
        tempY = item.properties.find(
          (hr) =>
            parseInt(hr.property_collection_id) ===
            parseInt(this.conceptData.vr_property_collection_id)
        );
      } else {
        if (this.canvasSerial === 1) {
          tempX = item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.canvasPropValue.canvas_one_h)
          );
          tempY = item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.canvasPropValue.canvas_one_v)
          );
        } else {
          tempX = item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.canvasPropValue.canvas_two_h)
          );
          tempY = item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.canvasPropValue.canvas_two_v)
          );
        }
      }

      let x = tempX ? tempX.sample_value : 0;
      let y = tempY ? tempY.sample_value : 0;
      let currentLayout = [canvasWidth, canvasHeight];
      let xy = [
        (currentLayout[0] / 10) * x - selfMargin,
        (currentLayout[1] / 10) * y - selfMargin,
      ];

      const self = this;

      if (level > 1) {
        setTimeout(() => {
          let startElement = document.getElementById(
              `l${level - 1}_${item.parent_id}_${this.canvasSerial}`
            ),
            endElement = document.getElementById(
              `l${level}_${id}_${this.canvasSerial}`
            );
          let i = LeaderLine.setLine(startElement, endElement, {
            color: "#D7D7D7",
            size: 1,
            path: "straight",
            hide: false,
          });
          i.current_item_id = id;
          this.lines.push(i);
        }, 200);
      }
      return xy[pos];
    },

    createLine(x1, y1, x2, y2, lineId) {
      let distance = Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2 + (y1 - y2)));
      let xMid = (x1 + x2) / 2;
      let yMid = (y1 + y2) / 2;

      let slopeInRadian = Math.atan2(y1 - y2, x1 - x2);
      let slopeInDegree = slopeInRadian * 180 * Math.PI;

      let line = document.getElementById(lineId);
      line.style.width = distance + "px";
      line.style.top = yMid + "px";
      line.style.left = xMid - distance / 2 + "px";
      line.style.transform = "rotate(" + slopeInDegree + ")deg";
    },

    getProperty(item) {
      if (item.properties.length > 0) {
        item.canvas_property = {
          x: item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.conceptData.hr_property_collection_id)
          ),
          y: item.properties.find(
            (hr) =>
              parseInt(hr.property_collection_id) ===
              parseInt(this.conceptData.vr_property_collection_id)
          ),
        };
      }
      return item;
    },

    setActiveData(item) {
      this.item = item;
      if (this.activeBrainstormData.scenario_id) {
        this.$store.commit(
          "programmatic/setActiveBrainstormScenarioCollection",
          item
        );
      } else {
        this.$store.dispatch("build/getActiveModuleCollection", item);
      }
    },

    lineManagement(data, parents) {
      this.lineManagementsData = parents;
      this.getIds(data);

      if (this.canvasSerial === 1) {
        let itemData = data;
        itemData.parents = parents;
        this.$emit("toggleIsolation", itemData);
      } else {
        this.tempIds = parents;
        this.tempIds.push(data.id);

        this.getTempIds(data);
        let payload = {
          item_id: data.id,
          data: this.tempIds,
        };

        this.$store.dispatch("concept/tempIsolation", payload);
        this.tempIds = [];
      }

      this.lines.forEach((item) => {
        if (!this.lineManagementsData.includes(item.current_item_id)) {
          item.hide();
        }
      });
    },

    getIds(item) {
      this.lineManagementsData.push(item.id);
      item.child.forEach((child) => {
        this.getIds(child);
      });
    },

    getTempIds(item) {
      this.tempIds.push(item.id);
      item.child.forEach((child) => {
        this.getTempIds(child);
      });
    },
    getBg(level, item) {
      if (item && item.background_color) {
        return item.background_color;
      } else {
        if (
          this.activeBrainstormData.module_id ||
          this.activeBrainstormData.scenario_id
        ) {
          let settings = this.moduleSettings;
          if (settings) {
            let collection = settings.collections[level];
            if (collection) {
              if (collection.custom_bg) {
                return collection.custom_bg;
              }
              let colorCodes = this.getLevelWiseColorCode(level);
              return colorCodes[collection.color];
            }
          }
        } else {
          return this.defaultColors[level - 1];
        }
      }
      return this.defaultColors[level - 1];
    },
    getLevelWiseColorCode(level) {
      let indexLevel = level - 1;
      return this.colorCodes.slice(indexLevel * 9, indexLevel * 9 + 9);
    },
    getRootIndexNumber(level, index) {
      if (
        this.activeBrainstormData.module_id ||
        this.activeBrainstormData.scenario_id
      ) {
        let settings = this.moduleSettings;
        if (settings) {
          let indexing = "";
          let collection = settings.collections[level];
          if (settings.index_number) {
            indexing = parseFloat(collection.index_number) + index;
          }
          return indexing;
        }
      }
      return "";
    },

    getIndexId(level) {
      if (this.activeBrainstormData.module_id) {
        let settings = this.moduleSettings;
        if (settings) {
          let collection = settings.collections[level];
          if (collection) {
            return collection.index_id;
          }
        }
      }
      return "";
    },

    getData(items) {
      let moduleData = this.getModule;
      let moduleInIsolation = this.isolatedModules.find(
        (item) => item && moduleData && item.module_id === moduleData.id
      );
      if (moduleInIsolation && moduleInIsolation.isolate) {
        return items.filter((data) =>
          moduleInIsolation.isolatedIds.includes(data.id)
        );
      }
      return items;
    },
    updatePropertyOnDragend(x, y) {
      this.$Progress.start();
      let tempX = this.activeItem.properties.find(
        (hr) =>
          parseInt(hr.property_collection_id) ===
          parseInt(this.conceptData.hr_property_collection_id)
      );
      let tempY = this.activeItem.properties.find(
        (hr) =>
          parseInt(hr.property_collection_id) ===
          parseInt(this.conceptData.vr_property_collection_id)
      );
      if (tempX && tempY) {
        tempX.sample_value = parseFloat(x.toFixed(1));
        tempY.sample_value = parseFloat(y.toFixed(1));
        if (this.activeBrainstormData.scenario_id) {
          this.$store.dispatch(
            "brainstormv1/updatePropertyItemFromScenario",
            tempX
          );
          this.$store
            .dispatch("brainstormv1/updatePropertyItemFromScenario", tempY)
            .then((response) => {
              if (response) {
                this.$store
                  .dispatch("brainstormv1/getActiveBrainstormData", {
                    brainstorm_id: this.activeBrainstormData.id,
                  })
                  .then((response) => {
                    if (response) {
                      this.$Progress.finish();
                    }
                  });
              }
            });
        } else {
          this.$store.dispatch(
            "brainstormv1/updatePropertyItemFromBuild",
            tempX
          );
          this.$store
            .dispatch("brainstormv1/updatePropertyItemFromBuild", tempY)
            .then((response) => {
              if (response) {
                this.$store
                  .dispatch("brainstormv1/getActiveBrainstormData", {
                    brainstorm_id: this.activeBrainstormData.id,
                  })
                  .then((response) => {
                    if (response) {
                      this.$Progress.finish();
                    }
                  });
              }
            });
        }
      }
    },
    draggable(id) {
      const self = this;
      const layout = document.getElementById("graph_layout").getBoundingClientRect();
      const xtrArr = [40, 35, 30, 25];

      dragElement(document.getElementById(id));
      function dragElement(elmnt) {
        let item = self.activeItem;
        const xtr = xtrArr[item.level - 1];
        self.lines.forEach((line) => {
          line.hide();
        });
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        let elem = elmnt.id.split('_');
        elem.length = 2;
        elem = elem.join('_');


        if (document.getElementById(elem + "_drag")) {
          document.getElementById(elem + "_drag").onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
        function elementDrag(e) {
          self.lines.forEach((line) => {
            line.hide();
          });
          e = e || window.event;
          e.preventDefault();

          // calculate the new cursor position:
          var offsetX = null;
          var offsetY = null;

          // left/right constraint
          if (e.clientX - layout.left < 0) {
            offsetX = -xtr;
          } else if (e.clientX - layout.left > document.getElementById("graph_layout").clientWidth) {
            offsetX = document.getElementById("graph_layout").clientWidth - xtr;
          } else {
            offsetX = elmnt.offsetLeft;
          }

          // top/bottom constraint
          if (e.clientY - layout.top > document.getElementById("graph_layout").clientHeight) {
            // console.log(document.getElementById("graph_layout").clientHeight - xtr)
            // offsetY = document.getElementById("graph_layout").clientHeight - xtr;
            offsetY = -xtr;
          } else {
            offsetY = elmnt.offsetTop;
          }

          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;

          // set the element's new position:
          elmnt.style.left = offsetX - pos1 + "px";
          elmnt.style.top = (offsetY) < -735 ? -735 + "px" : offsetY - pos2 + "px";
        }
        function closeDragElement(e) {
          /* stop moving when mouse button is released:*/
          // calculate property value

          let canvasWidth = self.layoutSize[0];
          let canvasHeight = self.layoutSize[1];
          canvasWidth =
            this.canvasStyle === 2 ? canvasWidth : canvasWidth * 2 + 61;

          let x = ((e.clientX - layout.left - xtr) / canvasWidth) * 10;
          let y = (1 - (e.clientY - layout.top + xtr) / canvasHeight) * 10;
          self.updatePropertyOnDragend(x, y);
          document.onmouseup = null;
          document.onmousemove = null;
        }
      }
    },
    getDefinition(type) {
      if (this.definitions.length > 0) {
        let index = 0;
        if (type === 1) {
          type = this.conceptData.hr_property_collection_id;
          index = this.definitionActiveIndex.horizontal;
        } else {
          type = this.conceptData.vr_property_collection_id;
          index = this.definitionActiveIndex.vertical;
        }

        let data = this.definitions.find((item) => item && item.parameter_id && item.parameter_id === type);
        if (data && data.items && data.items.length > 0) {
          return data.items[index];
        }
      }
      return null;
    },

    getHorizontalDefinitionItems(type) {
      let activeType = type;
      if (this.definitions.length > 0) {
        let index = 0;
        if (type === 1) {
          type = this.conceptData.hr_property_collection_id;
          index = this.definitionActiveIndex.horizontal;
        } else {
          type = this.conceptData.vr_property_collection_id;
          index = this.definitionActiveIndex.vertical;
        }

        let data = this.definitions.find((item) => item && item.parameter_id && item.parameter_id === type);
        let customData = Object.assign({}, data);
        if (customData && customData.items && customData.items.length > 0) {
          return customData.items[index].range_with_definition;
        }
      }
      return [];
    },
    horizontalNextDisability(type) {
      let activeLength = 0;
      if (type === 1) {
        type = this.conceptData.hr_property_collection_id;
        activeLength = this.definitionActiveIndex.horizontal + 1;
      } else {
        type = this.conceptData.vr_property_collection_id;
        activeLength = this.definitionActiveIndex.vertical + 1;
      }

      let items = this.definitions.find((item) => item && item.parameter_id && item.parameter_id === type)?.items;
      if (items && items.length > 0) {
        return activeLength === items.length;
      }
      return true;
    }

  },

  updated() {
    let movable = document.getElementsByClassName("movable");
    if(movable.length){
      for(let i = 0; i < movable.length; i++){
        this.draggable(movable[i].id)
      }
    }
  },

  beforeUpdate() {
    this.lines.forEach((line) => {
      line.remove();
    });
    this.lines = [];
  },
  beforeDestroy() {
    this.lines.forEach((line) => {
      line.remove();
    });
    this.lines = [];
  },
};
</script>


<style scoped>
.bottomLevelNumber {
  width: 1382px;
}

.bottomLevelNumber>p {
  width: fit-content;
}
.canvasStyle_2 .bottomLevelNumber {
  width: 650px
}
.visualizeMode .bottomLevelNumber{
  width: 1414px !important;
}
.visualizeMode.canvasStyle_2 .bottomLevelNumber{
  width: 823px !important;
}
.canvasStyle_2 .bottomLevelNumber>p {
  width: fit-content;
}
.sideBySideWrapper>div {
  width: 855px;
}
.full-canvas {
  height: 790px;
}
.vertical-line-height {
  height: 750px;
  margin-right: 30px;
  padding-top: 35px;
  padding-bottom: 15px;
}
.vertical-line-height>p{
  height: 145px;
}
.visualizeMode .vertical-line-height {
  position: absolute;
  left: 66px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  height: 719px;
  padding-top: 0px;
  padding-bottom: 21px;
}
.vertical-line-height-side {
  margin-right: 4px;
  transform: translateX(18px) rotate(180deg);
}
.vertical-number-height {
  height: 735px;
  margin-top: -4px;
}
.vertical-number-def {
  height: 675px;
}
.vertical-number-height-full {
  height: 822px;
}
.canvas-height {
  /*height: 770px;*/
  width: 100%;
}
.horizontal-lines {
  padding: 0;
  width: 1407px;
  padding-left: 25px;
}
.horizontal-lines > h4{
  width: 277px;
  flex-shrink: 0;
  text-align: center;
  flex-grow: 0;
}

.visualizeMode .horizontal-lines{
  position: absolute;
  top: 110px;
  padding: 0;
  padding-left: 32px;
/*   display: grid;
  grid-template-columns: 299px 281px 275px 279px 274px; */
  /* justify-content: normal; */
  text-align: center;
  display: flex;
  width: 76%;
  justify-content: space-between;
}
.visualizeMode.hasHorizontalDefinitionItems .horizontal-lines{

}

.sideBySide .horizontal-lines {
  padding-left: 88px;
  padding-right: 30px;
}
.vertical-lr {
  writing-mode: vertical-lr;
}
.l1div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  z-index: 2;
}
.l2div {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  z-index: 2;
}
.l3div {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 2;
}
.l4div {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
}
.l1c {
  background: #2b80ad;
}
.l2c {
  background: #3d6a83;
}
.l3c {
  background: #6a7881;
}
.l4c {
  background: #818487;
}
.w-100-5 {
  width: 100.5%;
}
.info-box {
  width: max-content;
  z-index: 9999;
}
.in-right-1 {
  left: 90px;
  z-index: 9;
}
.in-left-1 {
  right: 90px;
}
.in-right-2 {
  left: 80px;
  z-index: 99;
}
.in-left-2 {
  right: 80px;
}
.in-right-3 {
  left: 70px;
  z-index: 999;
}
.in-left-3 {
  right: 70px;
}
.in-right-4 {
  left: 60px;
  z-index: 9999;
}
.in-left-4 {
  right: 60px;
}
.info-box1 {
  width: max-content;
}
.info-box2 {
  width: max-content;
}
.info-box3 {
  width: max-content;
}
.info-box4 {
  width: max-content;
}
.drag-pointer-1 {
  top: -20px;
  left: 70px;
  border-radius: 50%;
  cursor: move;
  z-index: 9999;
}
.drag-pointer-2 {
  top: -20px;
  left: 60px;
  cursor: move;
  z-index: 9999;
}
.drag-pointer-3 {
  top: -20px;
  left: 50px;
  cursor: move;
  z-index: 9999;
}
.drag-pointer-4 {
  top: -20px;
  left: 40px;
  cursor: move;
  z-index: 9999;
}
.square-box {
  width: 8px;
  height: 8px;
  border-radius: 1px;
  z-index: 999;
}
.layoutWrapper{
  grid-template-columns: 25px 1fr;
}
.sideBySide .canvasStyle_2 .layoutWrapper{
  grid-template-columns: 50px 1fr;
}
.canvasStyle_2 :is(.vertical-line-height, .horizontal-lines){
  display: none;
}

.canvasStyle_2 .layoutWrapper{
  grid-template-columns: 60px 1fr;
}
.canvasStyle_2 .layoutWrapper .leftLevelRange>p>span{
  left: -20px;
}
.visualizeMode .layoutWrapper{
  grid-template-columns: 30px 1fr;
}
.visualizeMode .leftLevelRange > p{
  background: #ededed;
  padding: 5px 0px 5px 11px;
  margin-top: 0px;
}
.graph-layout {
  background-size: calc(10% - 0.2px) 70px;
  background-image: linear-gradient(to right, #ffffff 1px, transparent 1px),
    linear-gradient(to bottom, #ffffff 1px, transparent 1px);
  width: 100%;
  height: 701px;
}
.visualizeMode .graph-layout {
  background-size: 141px 70px;
}
.visualizeMode.canvasStyle_0 .graph-layout{
  background-size: 141.3px 70px;
}
.graph-layout-side-by-side {
  width: 650px;
  background-size: 65px 70px;
}
.graph-layout-full {
  background-size: 172px 79px;
  width: 1721px;
  height: 791px;
}
.canvas4-quadrant {
  width: 50%;
  background: #73b98b;
  height: 351px;
  position: relative;
}
.canvas4-quadrant::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image:var(--bgImage);
  opacity: var(--bgOpacity);
  background-size: cover;
}
.canvas-serial {
  margin-left: 76px;
}
.canvas-serial-logic {
  margin-left: 50px;
}
.graph-layout-definition {
  width: 1162px;
  height: 641px;
  background-size: 116px 64px;
}
.hor-definitions {
  width: 190px;
  margin-left: 20px;
  margin-right: 20px;
}
.hor-definition {
  height: 128px;
}
.ver-definitions {
  height: 130px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.ver-definition {
  width: 232px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100px;
  overflow-y: auto;
}
.w-h-def {
  width: 1162px;
}
.vertical-line-def {
  height: 690px;
}
.hor-def-sec {
  top: 105%;
  left: -352px;
}
.def-next-icon {
  width: 8px;
}
.def-title {
  width: 132px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ver-def-sec {
  top: calc(105% + 25px);
  left: -352px;
}
.border-left-0 {
  border-left: none;
}
.border-top-0 {
  border-top: none;
}
.def-next-icon:hover {
  opacity: 1;
}

div[data-firstletter]:before {
  content: attr(data-firstletter);
  pointer-events: none;
  font-size: 150px;
  font-weight: 700;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
</style>
