<template>
    <div class="flex border-4 border-white rounded layoutOneByFiveWrapper">
        <template v-for="(cat, index) in gridLayout.layout_grid_category" >
            <div v-if="selectedCategories.includes(cat.category)" :key="cat.id" >
                <layoutComponent width="345px" height="833px" :style="{'--bgImgForVisualize': `url(${backgroundImages[index]?.bg})`, '--opacityForVisualizeImg': backgroundImages[index]?.opacity}" :isVisualize='isVisualize' :tagIds="tagIds" :category="cat" layout="oneByFive" _class="inner_column_1/5" />
            </div>
        </template>
    </div>
</template>

<script setup>
    import layoutComponent from './layoutComponent.vue';
    import { ref } from'vue'
    import imageOne from '@/assets/images/visualize/1x5-1.webp'
    import imageTwo from '@/assets/images/visualize/1x5-2.webp'
    import imageThree from '@/assets/images/visualize/1x5-3.webp'
    
    const selectedCategories = ['key-partner', 'key-activity', 'value-proportion', 'customer-relationship', 'customer-segment'];

    // eslint-disable-next-line no-undef
    const props =  defineProps({
        gridLayout: {
            type: Object
        },
        isVisualize: {
            type: Boolean
        }
    });

    const backgroundImages = [
        {
            bg: imageOne,
            opacity: 1/100*5
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageTwo,
            opacity: 1/100*10
        },
        {}, // this empty object to match with index of for loop
        {}, // this empty object to match with index of for loop
        {
            bg: imageThree,
            opacity: 1/100*10
        },
    ]
</script>

<style scoped>
    .layoutOneByFiveWrapper{
        min-width: 100%;
    }
    .i-left-panel+div>.i-right-panel .layoutOneByFiveWrapper{
        min-width: unset;
    }
    .layoutOneByFiveWrapper>div{
        flex: 1;
    }
</style>