import axios from 'axios';
const componentList = [
    {id: 1, title: 'Business Layout 1', component: 'twoBySevenLayout'},
    {id: 2, title: 'Business Layout 2', component: 'oneByFiveLayout'},
    {id: 3, title: 'Business Layout 3', component: 'twoByTwoLayout'},
    {id: 4, title: 'Business Layout 4', component: 'threeByThreeLayout'}
]

export function useCanvas() {
    
    async function getLayout(brainstormId)
    {
        return await axios.get(`brainstorms/business-models/${brainstormId}`).then(({data}) => data.data);
    }
    
    async function getScenario(projectId)
    {
        return await axios.get(`projects/${projectId}?solid_object=true`).then(({data})=>data.data.scenario)
    }

    async function createTag(objData)
    {
        let res = await axios.post(`brainstorms/business-models/tags`, objData);
        if(res.status) return res.data;
        return false;
    }
    
    let timeoutId;
    function updateTag(tagId, data)
    {
        if(timeoutId)
            clearTimeout(timeoutId);
        
        timeoutId = setTimeout(async () => {
            return await axios.post(`brainstorms/business-models/tags/${tagId}/update`, data);
        }, 1000);

        return false;
    }

    async function deleteTag(tagId)
    {
        return await axios.delete(`brainstorms/business-models/tags/${tagId}`);
    }

    const updateLayout = (businessModelId, activeLayout) => {
        if(businessModelId) {
            return axios.post(`brainstorms/business-models/${businessModelId}/update`, {active_layout: activeLayout });
        }
    }

    const getData = async (x, callBack = null) => {
        callBack(await x);
        if(await x) return await x;
    }

    const checkBg = (obj) => {
        return obj?.settings?.bg_color;
    }

    const updateTitleAndBgColor = async (id, title = false, color = false) => {
        const data = {}
        if(title) data.title = title
        if(color) data.settings = {bg_color: color}
        
        return await axios.post(`brainstorms/business-models/${id}/update-grid-category`, data)
    }

    const attachTagsAndAtomic = async (tags, atomic, is_scenario) => {
       return await axios.post(`brainstorms/business-models/tags/collections`, { tags, atomic, is_scenario })
    }

    
    /**
     * rowOne = row 1
     * rowTwoColumnOne = row 2, column 1
     */
    const MakeFirstLayoutPerfect = (rowOne,rowTwoColumnOne) =>
    {
        if(!rowOne) return;
        let elements                           = rowOne.querySelectorAll('div.col');
        let rowOneColumnOne                    = elements[0];
        let calculatedWidth                    = (rowOneColumnOne.offsetWidth + elements[1].offsetWidth)+(elements[2].offsetWidth / 2);
        let innerTagContainerOfRowOneColumnOne = rowOneColumnOne.querySelector('.inner_column_2_7');
        let innerTagContainerOfRowTwoColumnOne = rowTwoColumnOne.querySelector('.inner_column_2_7');
        let extraWidth                         = 0;
        let  rowOneColumnOneAndInnerContainerDistance = (rowOneColumnOne.offsetWidth - innerTagContainerOfRowOneColumnOne.offsetWidth);
        (rowOneColumnOneAndInnerContainerDistance < 0) && (rowOneColumnOne.style.minWidth = null);
        
        /* working with rowTwoColumnOne start */
            if(
                rowOneColumnOneAndInnerContainerDistance > 170 
                && (rowTwoColumnOne.offsetWidth - innerTagContainerOfRowTwoColumnOne.offsetWidth) > 170 
            ) {
                rowOneColumnOne.style.minWidth = rowTwoColumnOne.style.minWidth  = null;
            }

            if(rowTwoColumnOne.offsetWidth != calculatedWidth) {
                rowTwoColumnOne.style.minWidth = `${calculatedWidth}px`;
            }
        
            if(rowTwoColumnOne.offsetWidth - innerTagContainerOfRowTwoColumnOne.offsetWidth<0){
                rowOneColumnOne.style.minWidth = `${rowOneColumnOne.offsetWidth + extraWidth - rowTwoColumnOne.offsetWidth - innerTagContainerOfRowTwoColumnOne.offsetWidth}px`;
                rowTwoColumnOne.style.minWidth = `${rowTwoColumnOne.scrollWidth + 40}px`;
            }

            extraWidth = (rowTwoColumnOne.offsetWidth-calculatedWidth);
            rowOneColumnOne.style.minWidth = `${rowOneColumnOne.offsetWidth + extraWidth}px`;
        /* working with rowTwoColumnOne end */
    }

    return {
        getLayout,
        createTag,
        updateTag,
        deleteTag,
        updateLayout,
        componentList,
        getData,
        checkBg,
        attachTagsAndAtomic,
        MakeFirstLayoutPerfect,
        updateTitleAndBgColor,
        getScenario
    };
}