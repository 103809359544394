<template>
  <div class="flex items-center gap-5">
    <MenubarIcon v-if="navigationClicked == 2" @click.native="clickNavigation(1)" class="w-10 h-10 i-p-10 border border-primary-four shadow-one bg-white rounded cursor-pointer i-icon opacity-80 z-50" />
    <div style="width: 620px" class="h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center border border-primary-four relative z-50">
        <!-- <DownloadIcon class="w-10 h-10 mr-5" /> -->
        <img
          v-if="canEdit(project.cans)"
          @click="downloadCallBack"
          class="w-5 mr-4 cursor-pointer"
          src="/images/icons/brainstormV2/save_as.svg" alt="">
        <TextInput
          v-model="rootData.title"
          classes="fw-400 fs-14 w-full min-w-180px text-primary-one "
          :disabled="!canEdit(project.cans)"
        />
        <div class="flex gap-x-5 items-center">
          <BarIcon class="h-4 text-secondary-five" />
          <ResetIcon @click.native="handleReset" class="w-4 h-4 text-primary-one cursor-pointer" />
          <SketchIcon title="Sketch" @click.native="ToggleCanvas(true)" class="cursor-pointer i-w-22 h-4 text-secondary-one" />
          <div>
            <ColorIcon title="Select Color" class="cursor-pointer" @click.native="clickColorPicker"/>
            <input @input="colorSet(skcColor)" ref="colorPickerElem" type="color" v-model="skcColor" class="absolute top-0 bottom-0 invisible" />
          </div>

          <ShareBoxed @click.native="$emit('onShare')" class="cursor-pointer" title="Share with people" />
          <BarIcon class="h-4 text-secondary-five" />
          <slot name="cluster">
            <FilterIcon />
          </slot>
        </div>
      </div>

      <SketchController :isControllerActive="isCanvasOpen" :canvas="canvas" />
      <slot name="extra">
      </slot>

    </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue'
  import TextInput from '@/components/communicator/templates/TextInput'
  import useZoom from '@/elements/zoom'
  import Canvas from "@/views/screens/teamwork/Canvas.vue";
  import SketchController from "@/components/teamwork/sketch/SketchController.vue"
  import usePermission from '@/composable/usePermission.js'
  import store from "@/store";
  const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

  const project = computed(()=>{
    return store.getters["project/project"]
  })

  // eslint-disable-next-line no-undef
  const props = defineProps({
    canvas: {
      type: String,
    },
    downloadCallBack: {
      type: Function,
      default: () => {},
    },
    clickNavigation: Function,
    navigationClicked: Number,
    rootData: {
      type: Object
    },
    colorSet: {
      type: Function,
      default: () => {}
    }
  })

  const emit = defineEmits(['onResetZoom'])

  const {resetZoom}     = useZoom()
  // const title           = ref('Title')
  const skcColor        = ref('#1C1C1D')
  const isCanvasOpen    = ref(false)
  const colorPickerElem = ref()

  const ToggleCanvas = (mode) => {
    let canvasContainer = document.querySelector('.canvas-container');
    if(canvasContainer){
      // console.log(canvasContainer, '>>>>>>>>>>>')
      isCanvasOpen.value = !isCanvasOpen.value
      if(!isCanvasOpen.value){
        canvasContainer.style.display = 'none'
        return;
      }
      canvasContainer.style.display = 'block'
    }
  }

  const clickColorPicker = () => {
    colorPickerElem.value.click()
  }

  const handleReset = () => {
    resetZoom()
    emit('onResetZoom')
  }

</script>

<style>
  .min-w-180px{
    min-width: 180px;
  }
</style>
