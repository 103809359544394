<template>
  <div class="_main_wrapper relative flex" style="height: 1080px;" >
    <div class="pointer-container" :ref="functionRef"></div>
    <div class="i-left-panel shadow-one flex-shrink-0" v-if="navigationClicked === 1">
      <div class="relative">
        <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo">
        <NavToggle v-if="projectId"/>
      </div>
      <left-part
        :navigationClicked="navigationClicked"
        :isolatedModules="isolatedModules"
        :isolatedModuleId="isolatedModule"
        @clickNavigation="clickNavigation"
        @toggleConcept="toggleConcept"
        @gotoConcept="gotoConcept"
        @closeConcept="closeConcept"
        @resetZoom="zoomReset"
        @toggleIsolation="toggleIsolation"
        @changedDataFromCollaboration="changedDataFromCollaboration"
        @toggleToIntel="toggleToIntel"
        @closeIntel="closeIntel"
        @intelChartIsolation="intelChartIsolation"
        @setIntelGroup="setIntelGroup"
        @removeIsolation="removeIsolation"
        @removeIntelIsolation="removeIntelIsolation"
        @moduleIsolation="moduleIsolation"
      />
    </div>

    <template  v-if="$route.name == 'teamwork.mindmap' || $route.name == 'teamwork.concepts' || $route.name == 'teamwork.diagrammatic'">
      <div @mousemove="moving" id="right-div-scroll" class="_rightPart px-10 overflow-scroll right-part scroll-smooth">
          <div v-if="$route.name == 'teamwork.mindmap'" :class="{'bld-full-w-no-left': navigationClicked === 2}">
            <div class="flex flex-col right-0 top-0 z-max ws-heading-box absolute" v-if="navigationClicked === 1">
                <workspace-menu />
            </div>
            <div class="i-top-48 z-50 absolute" v-if="!sketch">
              <div class="top_text flex gap-16 mb-6">
                <div class="flex gap-1 items-center">
                  <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                  <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">{{ project && project.scenario ? project.scenario.title : 'N/A' }}</h2>
                </div>
                <div class="flex gap-1 items-center">
                  <h2 class="fs-14 fw-800 text-primary-one">Data Source: </h2>
                  <select class="custom_select fw-500 fs-14 bg-transparent pr-2">
                    <option value="Scenario Properties">Scenario Properties</option>
                    <!-- <option value="null">Assessment (Coming Soon)</option>-->
                  </select>
                </div>
              </div>
              <TopMenu
                :clickNavigation="clickNavigation"
                :navigationClicked="navigationClicked"
                @onResetZoom="resetZoom"
                @onShare="shareModal = true"
                :rootData="activeBrainstormData"
              >
                <template #cluster>
                  <AutoclusterIcon  :class="[viewIndex == 1 ? 'opacity-100' : 'opacity-70']" class="cursor-pointer" @click.native="toggleView"/>
                </template>
                <template #extra>
                <router-link :to="{name: 'brainstormPreview', params: {projectId: $route.params.id, brainstormId: $route.params.brainstorm_id}}">
                  <MonitorBorderedIcon
                    class="w-10 h-10 minimum-shadow cursor-pointer"
                    title="Quick Visualize"
                  />
                </router-link>
              </template>
              </TopMenu>
            </div>
            <!-- <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo z-50"
                v-if="navigationClicked === 2"> -->
            <div style="height: 1080px" class="" :class="{'': navigationClicked === 2}"
                @mousemove="movedCursorPosition($event)"
                @mouseleave="movedCursorPosition($event, true)">
                <div class="flex justify-between items-center z-50"
                    :class="{'tools-panel-cont' : navigationClicked == 1,
                            'tools-panel-cont-full': navigationClicked == 2,
                            'tools-panel-cont-full-width': navigationClicked == 2 && !sketch}">

                  <!-- <div class="tool-cont-pos mt-24 z-50" :class="{'tool-cont-pos-full': navigationClicked === 2}" v-if="!sketch"> -->
                      <!-- <div class="tools-panel z-50 h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center">
                        <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Brainstorm title"
                                v-model="activeBrainstormData.title" @change="updateTitle()">

                        <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 relative">
                            <img src="/images/icons/brainstormV2/single.svg" alt="new" @click="newRootChild()" :class="{'opacity-25 pointer-events-none': activeBrainstormData.scenario_id}">
                            <img src="/images/icons/brainstormV2/abc.svg" alt="" class="opacity-25 pointer-events-none">
                            <img src="/images/icons/brainstormV2/sketch.svg" alt="" @click="toggleSketchMenu">
                            <div class="absolute p-5 bg-primary-three shadow-two rounded z-10" id="sketch-menu" v-show="sketchMenu">
                            <h3 class="pb-5 i-border-b-1 border-secondary-two mb-5 fs-14 fw-600 text-secondary-one cursor-pointer" @click="generateSketchCanvas">Create New Sketch</h3>
                            <h3 class="mb-5 fs-14 fw-600 text-secondary-one">Open Existing</h3>
                            <h3 class="mb-5 fs-14 fw-400 text-secondary-one opacity-60 sketch-item cursor-pointer" v-for="(item, index) in activeBrainstormData.sketches" :key="index">{{ item.title }}</h3>
                            </div>
                        </div>

                        <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-5">
                            <img src="/images/icons/brainstormV2/view.svg" alt="" @click="toggleView" :class="viewIndex == 1 ? 'opacity-100' : 'opacity-60'">
                            <img src="/images/icons/brainstormV2/sync.svg" alt="" @click="zoomReset">
                            <div class="flex">
                            <div class="flex tool-group i-border-r-1 tool-level-box l1c cursor-default i-border-1 border-transparent" />
                            <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l2c cursor-default i-border-1 border-transparent"/>
                            <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l3c cursor-default i-border-1 border-transparent" />
                            <div class="flex tool-group i-border-r-1 i-ml-5 tool-level-box l4c cursor-default i-border-1 border-transparent" />
                            </div>
                        </div>
                        <div class="flex tool-group ml-5">
                            <img src="/images/icons/brainstormV2/wiki.svg" alt="">
                            <img class="bs-tool-mr-0" src="/images/icons/brainstormV2/chain.svg" alt="" @click="shareModal = true">
                        </div>
                      </div> -->
                  <!-- </div> -->

                  <div class="absolute tool-cont-pos" :class="{'tool-cont-pos-full': navigationClicked === 2}" v-if="sketch">
                      <div class="h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center">
                        <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Sketch title"
                                @change="updateSketchTitle()">
                        <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-1 relative">
                            <img src="/images/icons/brainstormV2/selection.svg" alt="" @click="enableCanvas('selectMode')">
                            <div @click="strokeList = !strokeList" class="relative">
                              <img src="/images/icons/brainstormV2/pen.svg" alt="">
                              <div v-show="strokeList" class="icon-list i-p-10 absolute bg-primary-three shadow-one flex-col justify-center">
                                  <img class="mb-2 p-1" src="/images/icons/brainstormV2/line.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 1})">
                                  <img class="mb-2 p-1" src="/images/icons/brainstormV2/line2.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 2})">
                                  <img class=" p-1" src="/images/icons/brainstormV2/line3.svg" alt="" @click="enableCanvas('freeDrawing', {strokeWidth: 3})">
                              </div>
                            </div>
                            <img src="/images/icons/brainstormV2/arrow.svg" alt="" @click="enableCanvas('arrow')">
                            <div @click="rectList = !rectList" class="relative">
                              <img src="/images/icons/brainstormV2/rectangle.svg" alt="">
                              <div v-show="rectList" class="icon-list i-p-10 absolute bg-primary-three shadow-one flex-col justify-center">
                                  <img class="mb-2 p-1" src="/images/icons/brainstormV2/hollow.svg" alt="" @click="enableCanvas('rect')">
                                  <img class="mb-2 p-1" src="/images/icons/brainstormV2/solid.svg" alt="" @click="enableCanvas('rect', {fill: skcColor})">
                              </div>
                            </div>
                        </div>
                        <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-1 relative">
                            <div class="realtive">
                              <img src="/images/icons/brainstormV2/image.svg" alt="" @click="clickUploadImage">
                              <input id="img-dot" type="file" @input="uploadImage($event)" class="absolute top-0 bottom-0 invisible" />
                            </div>
                            <img src="/images/icons/brainstormV2/comment_box.svg" alt="" @click="enableCanvas('textbox')">
                            <div class="realtive">
                              <img src="/images/icons/brainstormV2/color.svg" alt="" @click="clickColorPicker" class="">
                              <input id="color-dot" type="color" v-model="skcColor" @input="colorPicker()" class="absolute top-0 bottom-0 invisible" />
                            </div>
                        </div>
                        <div class="flex tool-group ml-5 relative text-secondary-two fw-600 fs-12 items-center">
                            <span class="mr-3">Opacity</span>
                            <div class="track-box relative">
                              <input class="range absolute left-0 top-0 cursor-pointer bg-secondary-four w-full" v-model="skcOpacity" min="0" max="1" step=".1" type="range"/>
                            </div>
                            <span class="cursor-pointer ml-3" @click="closeSketchCanvas">Close</span>
                        </div>
                      </div>
                  </div>



                </div>
                <PanzoomComponent>
                    <div class="h-full mt-40" ref="autoScreenshot">
                      <!-- <panZoom class="overflow-hidden bs-content-div relative" ref="panZoom" id="pan-zoom-runner"
                          :class="{'bs-content-full-div': navigationClicked === 2}"
                          :options="{transformOrigin: null, minZoom: 0.2, maxZoom: 8, initialX: initialX, initialY: initialY, initialZoom: initialZoom}"
                          @zoom="closeMenuHandler($event)" @panstart="closeMenuHandler($event)"> -->
                      <div class="relative" v-if="activeBrainstormData && activeBrainstormData.scenario_id" ref="sketchScreenshot" :class="{'invisible' : sketch}">
                      <div class="flex" v-if="viewIndex === 0">
                          <div v-for="(inLoop, loopIndex) in getNumberModules"
                              :key="loopIndex"
                              class="pr-4"
                              :class="{'pl-4': loopIndex > 0, 'border-secondary-two': loopIndex !== 0 && getSectionModules(loopIndex + 1).length > 0 && !isolate}">
                          <div class="flex flex-col pb-8 i-border-b-1" v-for="(module, mIndex) in getSectionModules(loopIndex)"
                              :key="mIndex"
                              :class="{'pt-8': mIndex > 0, 'border-secondary-two': mIndex !== getSectionModules(loopIndex).length - 1 && !isolate}">
                              <component
                                  :is="scenarioCanvasType[viewIndex]"
                                  :module="module"
                                  :appearance="appearance"
                                  :indexTitle="indexTitle"
                                  :flippedArray="flippedArray"
                                  :isolatedModules="isolatedModules"
                                  :isolatedModuleId="isolatedModule"
                                  :contextModule="contextModule"
                                  @newRootChild="newRootChild"
                                  @newSubChild="newSubChild"
                                  @menu="menuHandler"
                                  @toggleFlippedArray="toggleFlippedArray"
                                  @changedDataFromCollaboration="changedDataFromCollaboration"
                              >
                              </component>
                          </div>
                          </div>
                      </div>
                      <div v-else class="flex-col">
                          <div class="flex flex-col i-border-b-1 pb-8"
                              v-for="(inLoop, loopIndex) in getNumberModules"
                              :key="loopIndex"
                              :class="{'pt-8': inLoop > 1 && !isolate, 'border-secondary-two': !isolate}">
                          <div class="flex">
                              <div v-for="(module, mIndex) in getSectionModules(loopIndex)" :key="mIndex" class="i-border-l-1" :class="{'pl-4': mIndex > 0 && !isolate, 'border-secondary-two': mIndex !== 0 && !isolate}">
                              <component
                                  :is="scenarioCanvasType[viewIndex]"
                                  :module="module"
                                  :appearance="appearance"
                                  :indexTitle="indexTitle"
                                  :isolatedModules="isolatedModules"
                                  :isolatedModuleId="isolatedModule"
                                  :contextModule="contextModule"
                                  @newRootChild="newRootChild"
                                  @newSubChild="newSubChild"
                                  @menu="menuHandler"
                                  @changedDataFromCollaboration="changedDataFromCollaboration"
                              >
                              </component>
                              </div>
                          </div>
                          </div>
                      </div>

                      </div>
                      <div class="relative" v-else>
                      <component
                          :is="canvasType[viewIndex]"
                          :appearance="appearance"
                          :indexTitle="indexTitle"
                          :flippedArray="flippedArray"
                          :isolatedModules="isolatedModules"
                          :contextModule="contextModule"
                          @newRootChild="newRootChild"
                          @newSubChild="newSubChild"
                          @menu="menuHandler"
                          @toggleFlippedArray="toggleFlippedArray"
                          @changedDataFromCollaboration="changedDataFromCollaboration"
                      >
                      </component>
                      </div>
                      <sketch-canvas
                          :canvasWidth="skcWidth"
                          :canvasHeight="skcHeight"
                          :backgroundColor="getBackgroundColor"
                          :opacity="parseFloat(skcOpacity)"
                          :backgroundImage="sketchScreen"
                          :zindex="40"
                          ref="sketch"
                          editorId="sketch"
                          class="absolute"
                          v-show="sketch"
                          style="top: 0; left: 0;"
                      />
                      <!-- </panZoom> -->
                    </div>
                </PanzoomComponent>
                <context-menu
                    :viewIndex="viewIndex"
                    :item="contextmenuItem"
                    :id="'context-menu-'+activeBrainstormData.module_id"
                    v-show="contextmenu"
                    @appearance="applyAppearnce"
                    @indexTitle="applyIndexTitle"
                    @background="applyBackground"
                    @description="applyDescription"
                />
                <template v-for="(inBrainstorm, bPIndex) in inBrainstormWorking">
                  <div :key="bPIndex"
                      v-if="!concept && !inBrainstorm.in_concept && !inBrainstorm.hide"
                      class="absolute"
                      :style="{'left': inBrainstorm.x + 'px', 'top': inBrainstorm.y + 'px'}">
                    <div class="circe-of-user text-primary-one bg-primary-three py-2 px-2 flex items-center relative shadow-one">
                        <img :src="inBrainstorm.avatar" alt="image" class="circle-user" />
                        <h4 class="ml-2 fw-600">{{ inBrainstorm.name }}</h4>
                        <div class="absolute triangle-div">
                        <i class="fa fa-location-arrow rotate-90 fa-lg" aria-hidden="true" :style="{'color': usersBg[bPIndex]}"></i>
                        </div>
                    </div>
                  </div>
                </template>
            </div>
          </div>

          <!-- concepts start -->
            <div v-else-if="$route.name == 'teamwork.concepts'" class="-mr-10" :class="{'bld-full-w-no-left': navigationClicked === 2}">
              <div class="flex flex-col right-0 top-0 z-max ws-heading-box absolute" v-if="navigationClicked === 1 && !isVisualize">
                  <workspace-menu />
              </div>
              <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo"
                  v-if="navigationClicked === 2">
              <div class="pt-5 _content_wrapper" :class="{'brainstorm-full-mt': navigationClicked === 2}">
                  <div class="flex justify-between items-center">
                    <img src="/images/icons/build/navigation.svg" alt="icon" class="cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three icon-30 mb-1"
                        v-if="navigationClicked === 2" title="Navigation" @click="clickNavigation(1)">

                    <div class="absolute i-top-48">
                      <div class="top_text flex gap-16 mb-6">
                        <!-- layout switcher start -->
                        <div class="flex gap-1 items-center">
                          <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                          <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">{{ project && project.scenario ? project.scenario.title : 'N/A' }}</h2>
                        </div>
                        <!-- layout switcher end -->
                        <div class="flex gap-1 items-center">
                          <h2 class="fs-14 fw-800 text-primary-one">Data Source: </h2>
                          <select class="custom_select fw-500 fs-14 bg-transparent pr-2">
                            <option value="Scenario Properties">Scenario Properties</option>
                            <!--<option value="null">Assessment (Coming Soon)</option>-->
                          </select>
                        </div>
                      </div>


                        <div class="tools-panel concepts h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center mb-8 border border-primary-four">
                          <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 concept-title-box" type="text" placeholder="Concept title"
                            v-model="conceptData.title" @change="updateConceptTitle()" :disabled="!conceptData.id" />

                          <!-- <div class="flex tool-group border-secondary-five i-border-r-1 px-4"> -->
                              <!-- <img src="/images/icons/brainstormV2/single.svg" alt="new" class="cursor-default pointer-events-none opacity-25"> -->
                              <!-- <img src="/images/icons/brainstormV2/br_view_details.svg" alt="" class="cursor-pointer br-view-details opacity-75"
                                  :class="{'opacity-100 selected-shadow': viewDetails}"
                                  @click="viewDetails = !viewDetails" /> -->
                              <!-- <img src="/images/icons/brainstormV2/link_02.svg" alt="" class="cursor-default pointer-events-none opacity-25"> -->
                          <!-- </div> -->

                          <div class="flex gap-4 justify-between border-secondary-five i-border-r-1 px-4">
                            <ResetIcon class="w-4 h-4 text-primary-one cursor-pointer" />
                            <SketchIcon class="w-5 h-4 text-primary-one cursor-pointer" />
                            <div class="relative" v-click-outside="hidePicker">
                              <img src="/images/icons/brainstormV2/color_picker.svg" alt="picker" class="cursor-pointer" @click="showPicker = !showPicker" />
                              <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showPicker">
                                  <div class="flex flex-col items-center justify-center mt-3">
                                  <color-picker
                                      v-model="getQuadrantsColors[0]"
                                      :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,0)" />
                                  <color-picker
                                      v-model="getQuadrantsColors[1]"
                                      :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,1)" />
                                  <color-picker
                                      v-model="getQuadrantsColors[2]"
                                      :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,2)" />
                                  <color-picker
                                      v-model="getQuadrantsColors[3]"
                                      :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setQuadrantColors($event,3)" />
                                  </div>
                              </div>
                            </div>
                            <ShareBoxed class="cursor-pointer" @click.native="shareModal = true" />
                          </div>
                        <!-- target -->
                          <div class="flex tool-group pl-4">
                              <div class="flex gap-1">
                                <div class="flex tool-group i-border-r-1 concept-tool-level-box l1c cursor-pointer i-border-1 border-transparent"
                                    :class="{'opacity-50': !filterLevel[0].visibility}"
                                    @click="filterLevel[0].visibility = !filterLevel[0].visibility" />
                                <div class="flex tool-group i-border-r-1 concept-tool-level-box l2c cursor-pointer i-border-1 border-transparent"
                                    :class="{'opacity-50': !filterLevel[1].visibility}"
                                    @click="filterLevel[1].visibility = !filterLevel[1].visibility"/>
                                <div class="flex tool-group i-border-r-1 concept-tool-level-box l3c cursor-pointer i-border-1 border-transparent"
                                    :class="{'opacity-50': !filterLevel[2].visibility}"
                                    @click="filterLevel[2].visibility = !filterLevel[2].visibility" />
                                <div class="flex tool-group i-border-r-1 concept-tool-level-box l4c cursor-pointer i-border-1 border-transparent"
                                    :class="{'opacity-50': !filterLevel[3].visibility}"
                                    @click="filterLevel[3].visibility = !filterLevel[3].visibility"/>
                              </div>
                          </div>

                          <div class="absolute flex gap-5 left-full ml-5">
                            <button title="Coming Soon" class="border border-primary-four rounded cursor-pointer px-10px py-7px bg-white shadow">
                              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="21" height="15" rx="1.5" stroke="#3D3D3D"/>
                                <rect x="4" y="4" width="14" height="2" fill="#3D3D3D"/>
                                <rect x="4" y="10" width="14" height="2" fill="#00A7FE"/>
                              </svg>
                            </button>
                            <button @click="viewDetails = !viewDetails" class="border border-primary-four rounded cursor-pointer px-10px py-7px bg-white shadow InfoIcon">
                              <svg width="6" height="15" viewBox="0 0 6 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.01561 4.038C2.31961 4.038 1.76161 3.852 1.34161 3.48C0.921609 3.108 0.711609 2.64 0.711609 2.076C0.711609 1.5 0.921609 1.026 1.34161 0.653999C1.76161 0.269999 2.31961 0.077999 3.01561 0.077999C3.69961 0.077999 4.25161 0.269999 4.67161 0.653999C5.09161 1.026 5.30161 1.5 5.30161 2.076C5.30161 2.64 5.09161 3.108 4.67161 3.48C4.25161 3.852 3.69961 4.038 3.01561 4.038ZM4.99561 4.848V15H0.999609V4.848H4.99561Z" fill="black"/>
                              </svg>
                            </button>
                            <DuplicateBorderedIcon
                              class="w-10 h-10 minimum-shadow"
                              title="Copy Scenario (Coming Soon)"
                            />
                            <MonitorBorderedIcon
                              @click.native="isVisualize = true"
                              class="w-10 h-10 minimum-shadow"
                              title="Quick Visualize"
                            />
                          </div>
                        </div>
                    </div>
                  </div>

<!--                :style="{'&#45;&#45;width': (canvasStyle == 2) ? 'calc(100% + 120px)' : 'calc(100% + 265px)'}"-->
                  <div class="i-mt-140" :class="(canvasStyle == 2)&&'sideBySide flex justify-content-between'">
                    <component
                        :navigationClicked="navigationClicked"
                        :filterLevel="filterLevel"
                        :isolatedModules="isolatedModules"
                        :quadrantColors="getQuadrantsColors"
                        :pickerUpdated="pickerUpdated"
                        :canvasData="getCanvasData(1)"
                        :canvasSerial="1"
                        :viewDetails="viewDetails"
                        @toggleIsolation="toggleIsolation"
                        :is="canvasStyle==4 ? 'Layout' : componentName"
                        :style="(canvasStyle != 2) && 'width: 1460px'"
                      />

                    <component
                        v-if="canvasStyle === 2"
                        :class="(canvasStyle === 2) && 'i-ml-28'"
                        :navigationClicked="navigationClicked"
                        :filterLevel="filterLevel"
                        :isolatedModules="(canvasStyle === 2) ? canvasTwoIsolation : isolatedModules"
                        :quadrantColors="getQuadrantsColors"
                        :pickerUpdated="pickerUpdated"
                        :canvasData="(canvasStyle === 2) ? getCanvasData(2) : getCanvasData(1)"
                        :canvasSerial="(canvasStyle === 2) ? 2 : 1"
                        :viewDetails="viewDetails"
                        @toggleIsolation="toggleIsolation"
                        :is="componentName">
                    </component>

                  </div>
              </div>
            </div>

            <!-- visualize start -->
            <div
              v-if="isVisualize"
              class="bg-secondary-three fixed top-0 left-0 w-full h-full overflow-y-auto"
              style="z-index: 101; padding: 70px 92px 123px;"
              :style="{paddingBottom: isFullScreen&&'0'}"
            >
                <h2 @click="isVisualize = false" class="fs-16 fw-400 absolute top-12 right-24 cursor-pointer">Back</h2>
                <h2 class="fs-24 fw-800 text-primary-one mb-8">High Maturity Model Scenario</h2>
                <div class="visualize_content_wrapper">
                    <div class="flex justify-content-between">

                        <LeftData  v-if="canvasStyle != 2"
                          :canvasSerial="(canvasStyle === 2) ? 2 : 1"
                          :canvasData="(canvasStyle === 2) ? getCanvasData(2) : getCanvasData(1)"
                          :definitionItems="getHorizontalDefinitionItems(2)"
                        />

                        <div class="w-full" :class="canvasStyle==2&&'flex sideBySideWrapper'">
                          <TopData :definitionItems="getHorizontalDefinitionItems(1)" v-if="canvasStyle != 2" />
                            <!-- style="pointer-events:none;" -->
                          <component
                            :verticalDefinitionItems="getHorizontalDefinitionItems(2).length"
                            :horizontalDefinitionItems="getHorizontalDefinitionItems(1).length"
                            :backgroundImages="backgroundImages"
                            :isVisualize="isVisualize"
                            :navigationClicked="navigationClicked"
                            :filterLevel="filterLevel"
                            :isolatedModules="isolatedModules"
                            :quadrantColors="getQuadrantsColors"
                            :pickerUpdated="pickerUpdated"
                            :canvasData="getCanvasData(1)"
                            :canvasSerial="1"
                            :viewDetails="viewDetails"
                            @toggleIsolation="toggleIsolation"
                            :is="canvasStyle==4 ? 'Layout' : componentName">
                          </component>
                            <!-- style="pointer-events:none;" -->
                          <component
                            :verticalDefinitionItems="getHorizontalDefinitionItems(2).length"
                            :horizontalDefinitionItems="getHorizontalDefinitionItems(1).length"
                            :backgroundImages="backgroundImages"
                            v-if="canvasStyle === 2"
                            :class="(canvasStyle === 2) && 'i-ml-28'"
                            :isVisualize="isVisualize"
                            :navigationClicked="navigationClicked"
                            :filterLevel="filterLevel"
                            :isolatedModules="(canvasStyle === 2) ? canvasTwoIsolation : isolatedModules"
                            :quadrantColors="getQuadrantsColors"
                            :pickerUpdated="pickerUpdated"
                            :canvasData="(canvasStyle === 2) ? getCanvasData(2) : getCanvasData(1)"
                            :canvasSerial="(canvasStyle === 2) ? 2 : 1"
                            :viewDetails="viewDetails"
                            @toggleIsolation="toggleIsolation"
                            :is="componentName">
                          </component>
                        </div>
                    </div>
                </div>
                <div :class="canvasStyle==2&&'SbSbottomLayoutChanger'" class="flex fit-content w-14 ml-auto relative index-10 justify-end gap-7 buttons -mt-7">
                    <button @click="setCanvasStyle(-1)" class="p-1 opacity-50 hover:opacity-100 cursor-pointer">
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.18202 12.364L2 7.18202L7.18202 2" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 7.18213H14.2142" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <button @click="setCanvasStyle(+1)" class="p-1 opacity-50 hover:opacity-100 cursor-pointer">
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.94531 12.364L14.1273 7.18202L8.94531 2" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M1.91406 7.18213H14.1282" stroke="#3D3D3D" stroke-width="3.25712" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
              </div>
          </div>
          <!-- visualize end -->

          <!-- concepts end -->
          <div v-else-if="$route.name == 'teamwork.diagrammatic'" class="full-height" :class="{'bld-full-w-no-left': navigationClicked === 2}">
            <div class="flex flex-col right-0 top-0 z-max ws-heading-box absolute" v-if="navigationClicked === 1">
                <workspace-menu />
            </div>
            <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo absolute full-screen-logo"
              v-if="navigationClicked === 2">
            <div class="i-pr-65 pt-5 _content_wrapper" :class="{'brainstorm-full-mt': navigationClicked === 2}">
                <div class="flex justify-between items-center" style="min-height: 120px;">
                  <img src="/images/icons/build/navigation.svg" alt="icon" class="cursor-pointer opacity-50 i-icon mr-5 hover:bg-primary-three icon-30 mb-1"
                      v-if="navigationClicked === 2" title="Navigation" @click="clickNavigation(1)">

                  <div class="absolute i-top-48" >
                    <div class="top_text flex gap-16 mb-6">
                      <!-- layout switcher start -->
                      <div class="flex gap-1 items-center">
                        <h2 class="fs-14 fw-800 text-primary-one">Active Scenario: </h2>
                        <h2 class="custom_select fw-500 fs-14 bg-transparent pr-2">{{ project && project.scenario ? project.scenario.title : 'N/A' }}</h2>
                      </div>
                      <!-- layout switcher end -->
                      <div class="flex gap-1 items-center">
                        <h2 class="fs-14 fw-800 text-primary-one">Data Source: </h2>
                        <select class="custom_select fw-500 fs-14 bg-transparent pr-2">
                          <option value="Scenario Properties">Scenario Properties</option>
<!--                          <option value="null">Assessment (Coming Soon)</option>-->
                        </select>
                      </div>
                    </div>

                    <div class="flex items-start gap-5">
                      <div class="tools-panel border border-primary-four h-10 bg-primary-three shadow-one px-5 py-4 rounded flex items-center mb-8">
                        <input class="bg-transparent pr-5 fs-14 fw-600 text-primary-one i-border-r-1 intel-title-box" type="text" placeholder="Diagram Name"
                                v-model="intelItem.title" @change="updateIntelTitle()" :disabled="!intelItem.id" />
                        <div class="flex tool-group border-secondary-five i-border-r-1 px-5">
                            <!-- <img src="/images/icons/brainstormV2/brainstorm_pencil.svg" alt="picker" class="cursor-pointer opacity-75" /> -->
                            <!-- <img src="/images/icons/brainstormV2/sketch.svg" alt="" class="cursor-pointer opacity-75"
                                :class="{'opacity-100 shadow-two': viewDetails}" /> -->

                            <div class="relative" v-click-outside="hideLinePicker">
                            <img src="/images/icons/brainstormV2/intel_feature_2.svg" alt="picker" class="cursor-pointer opacity-75" @click="showLinePicker = !showLinePicker" />
                            <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showLinePicker">
                                <div class="flex flex-col items-center justify-center mt-3">
                                <color-picker
                                    v-model="intelLineColor"
                                    :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setIntelLineColor($event)" />
                                </div>
                            </div>
                            </div>

                            <!-- <img src="/images/icons/brainstormV2/intel_definition.svg" alt="" class="cursor-default pointer-events-none opacity-25"> -->
                        </div>
                        <div class="flex tool-group border-secondary-five i-border-r-1 ml-5 pr-5">
                            <div class="flex">
                              <div class="flex tool-group i-border-r-1 concept-tool-level-box l1c cursor-pointer i-border-1 border-transparent"
                                  :class="{'opacity-50': !filterLevel[0].visibility}"
                                  @click="filterLevel[0].visibility = !filterLevel[0].visibility" />
                              <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l2c cursor-pointer i-border-1 border-transparent"
                                  :class="{'opacity-50': !filterLevel[1].visibility}"
                                  @click="filterLevel[1].visibility = !filterLevel[1].visibility"/>
                              <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l3c cursor-pointer i-border-1 border-transparent"
                                  :class="{'opacity-50': !filterLevel[2].visibility}"
                                  @click="filterLevel[2].visibility = !filterLevel[2].visibility" />
                              <div class="flex tool-group i-border-r-1 ml-3 concept-tool-level-box l4c cursor-pointer i-border-1 border-transparent"
                                  :class="{'opacity-50': !filterLevel[3].visibility}"
                                  @click="filterLevel[3].visibility = !filterLevel[3].visibility"/>
                            </div>
                        </div>
                        <div class="flex tool-group ml-5">
                            <div class="relative" v-click-outside="hidePicker">
                            <img src="/images/icons/brainstormV2/color_picker.svg" alt="picker" class="cursor-pointer" @click="showPicker = !showPicker" />
                            <div class="absolute color-picker-div bg-primary-three shadow-one" v-if="showPicker">
                                <div class="flex flex-col items-center justify-center mt-3">
                                <color-picker
                                    v-model="intelChartColor"
                                    :position="{left: '40px', top: '0'}" class="picker-section mb-3 i-border-1 border-secondary-two" @change="setIntelChartColor($event)" />
                                </div>
                            </div>
                            </div>
                            <!-- <img class="bs-tool-mr-0 cursor-default pointer-events-none opacity- 25" src="/images/icons/brainstormV2/chain.svg" alt="" /> -->
                        </div>
                      </div>

                      <DuplicateBorderedIcon
                          class="w-10 h-10 minimum-shadow"
                          title="Copy Scenario (Coming Soon)"
                      />
                      <router-link :to="{name: 'diagrammaticPreview', params: {projectId: $route.params.id, brainstormId: $route.params.brainstorm_id}}">
                        <MonitorBorderedIcon
                            class="w-10 h-10 minimum-shadow"
                            title="Quick Visualize"
                        />
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="flex justify-between">
                  <intel-layout ref="layout_1"
                                  :intelModule="getIntelModule"
                                  :parameters="parameters"
                                  :serial="1"
                                  :intelChartColor="intelChartColor"
                                  :intelLineColor="intelLineColor" />
                  <intel-layout v-if="intelActiveStyle === 2"
                                  ref="layout_2"
                                  class="ml-5"
                                  :intelModule="getIntelCanvasTwoModule"
                                  :parameters="getIntelCanvasTwoParameters"
                                  :serial="2"
                                  :intelChartColor="intelChartColor"
                                  :intelLineColor="intelLineColor" />
                </div>
            </div>
          </div>
      </div>
    </template>
    <template v-if="$route.name == 'teamwork.brainstorm'">
        <Brainstorm
          class="right-part"
          :cursorMoving="moving"
          v-if="activeBrainstormData.id"
          :activeBrainstormData="activeBrainstormData"
          :isolatedModule="isolatedModule"
          :isolatedModules="isolatedModules"
          :clickNavigation="clickNavigation"
          :navigationClicked="navigationClicked"
        />
    </template>
    <div v-if="$route.name == 'teamwork.canvas'">
        <Canvas
          class="right-part"
          :cursorMoving="moving"
          :activeBrainstormData="activeBrainstormData"
          :isolatedModules="isolatedModules"
          :isolatedModule="isolatedModule"
          :clickNavigation="clickNavigation"
          :navigationClicked="navigationClicked"
          @onShare="shareModal = true"
        />
    </div>
<!--    <share-modal :dModal="shareModal" :rootItem="{}" @toggleModalMode="toggleShareModalMode" />-->
    <Modal v-model="shareModal">
      <share-modal :activeBrainstormData="activeBrainstormData" />
    </Modal>

    <Nav v-model="showNavigation" @close="showNavigation=false"/>
  </div>
</template>

<script setup>
    import usePanzoom from '@/elements/panzoom/usePanzoom.js';
    import { cursorSharing } from '@/composable/socket/cursorSharing.js';
    import { ref, onMounted } from 'vue'
    import NavToggle from '@/components/navigation/components/NavToggle.vue'
    import useNavigation from '@/components/navigation/useNavigation.js'
    import Nav from '@/components/navigation/Nav.vue'

  const { resetZoom, getRef } = usePanzoom()
  const { moving, createRoom, functionRef } = cursorSharing();


  const isFullScreen  = ref(false)
  const getScreenMode = () => {
    isFullScreen.value = window.innerHeight === screen.height
  }
  const { projectId, showNavigation } = useNavigation()

  onMounted(() => {
    window.onresize = getScreenMode;
    createRoom(`room_${2}`);

  })
</script>

<script>
import Modal from '@/elements/atom/Modal'
import LeftPart from "@/components/brainstormv2/Show/LeftPart";
import LeftData from "@/components/brainstormv2/concept/Visualize/LeftData.vue";
import TopData from "@/components/brainstormv2/concept/Visualize/TopData.vue";
import HrCanvas from "@/components/brainstormv2/canvas/HrCanvas";
import SketchCanvas from "@/components/brainstormv2/canvas/SketchCanvas";
import VrCanvas from "@/components/brainstormv2/canvas/VrCanvas";
import ScenarioHrCanvas from "@/components/brainstormv2/canvas/ScenarioHrCanvas";
import ScenarioVrCanvas from "@/components/brainstormv2/canvas/ScenarioVrCanvas";
import ContextMenu from '@/components/brainstormv2/canvas/CntxtMenu';
import {mapGetters} from 'vuex';
import Layout from "@/components/brainstormv2/concept/Layout";
import Layout4box from "@/components/brainstormv2/concept/Layout4box";
// import ShareModal from "@/components/brainstormv2/Show/ShareModal";
import ShareModal from "@/components/teamwork/brainstorm/ShareModal";
import IntelLayout from "@/components/brainstormv2/intel/IntelLayout";
import Brainstorm from "@/views/screens/teamwork/Brainstorm";
import Canvas from "@/views/screens/teamwork/Canvas.vue";
import TopMenu from "@/components/teamwork/TopMenu.vue";
import Zoomable from '@/elements/Zoomable'
import PanzoomComponent from '@/elements/panzoom/PanzoomComponent'
import imageOne from '@/assets/images/visualize/concepts-1.webp'
import imageTwo from '@/assets/images/visualize/concepts-2.webp'
import {cloneDeep} from "lodash";
import {brainstormContentSharing} from "@/composable/socket/brainstormContentSharing";
const { shareContentApi } = brainstormContentSharing();

export default {
  name: "Mindmap",
  components: {
    Modal,
    IntelLayout,
    Layout,
    Layout4box,
    LeftPart,
    ContextMenu,
    SketchCanvas,
    ShareModal,
    Brainstorm,
    Canvas,
    TopMenu,
    Zoomable,
    LeftData,
    TopData,
    PanzoomComponent
  },

  created() {
    switch(this.$route.name)
    {
      case 'teamwork.concepts':
        document.title = "Teamwork | Concepts"
      break
      case 'teamwork.mindmap':
        document.title = "Teamwork | Mindmap"
      break
      case 'teamwork.canvas':
        document.title = "Teamwork | Canvas"
      break
    }

    // this.zoomReset();
    // this.checkCtrl();
    this.$Progress.start();
    this.$store.dispatch("workspace/projectSelection", this.$route.params.id);
    this.$store.dispatch("project/getSolidObject", this.$route.params.id).then(response => {
      if(response && response.active_module_id) {
        this.$store.dispatch("programmatic/toggleModuleSelection", response.active_module_id);
      }
    });
    if (Object.entries(this.activeBrainstormData).length === 0) {
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id})
          .then(response => {
            if (response) {
              let data = response.data.data
              if(this.$route.name == 'teamwork.brainstorm') {
                if(this.project.active_module_id) {
                  this.isolatedModule = this.project.active_module_id;
                } else {
                  if(!this.isolatedModule && this.activeBrainstormData.modules.length > 0) {
                    this.isolatedModule = this.activeBrainstormData.modules[0].id;
                  }
                }
              }
              if (data.module_id || data.modules) {
                let module_id = data.module_id ? data.module_id : data.modules[0].id
                module_id = this.project.active_module_id ? this.project.active_module_id : module_id;
                this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", module_id)
                .then(response => {
                    if (response) {
                      this.$Progress.finish();
                    }
                })
              }
            }
        });
    }
    else {
        if (this.activeBrainstormData.module_id || this.activeBrainstormData.modules) {
            let module_id = this.activeBrainstormData.module_id ? this.activeBrainstormData.module_id : this.activeBrainstormData.modules[0].id
            module_id = this.project.active_module_id ? this.project.active_module_id : module_id;
            this.$store.dispatch("build/toggleLeftNavigatorModuleSelection", module_id)
            .then(response => {
                if (response) {
                this.$Progress.finish();
                }
            })
        }
        }
  },

  data() {
    return {
      backgroundImages: [
        { bg: imageOne, opacity: 1/100*25 },
        { bg: imageTwo, opacity: 1/100*5 }
      ],
      canvasStylesArr: ['Default style', '4 zone concept', 'Side by side logic', 'Default style + Definitions', '3 Horizons'],
      activeIndex: 0,
      isVisualize: false,
      componentName: Layout4box,
      showPicker: false,
      inBrainstormWorking: [],
      pickerUpdated: false,
      usersBg: ['#5B17EA', '#EF5600', '#1C1C1D', '#00A7FE', '#1BAD9C', '#B858BA', '#BCC292', '#0D9D61', '#AD902B', '#BE4949'],
      flippedArray: [],
      viewIndex: 0,
      canvasType: [HrCanvas, VrCanvas],
      scenarioCanvasType: [ScenarioHrCanvas, ScenarioVrCanvas],
      initialX: 40,
      initialY: 160,
      initialYF: 160,
      initialZoom: 1,
      navigationClicked: 1,
      contextmenu: false,
      indexTitle: true,
      description: false,
      contextmenuItem: null,
      appearance: 0,
      isolate: false,
      isolatedItemId: null,
      brainstormThumbnail: '',
      sketchScreen: '',
      concept: false,
      filterLevel: [{ visibility: true }, { visibility: true }, { visibility: true }, { visibility: true }],
      sketch: false,
      sketchMenu: false,
      isolatedIds: [],
      contextModule: null,
      skcOpacity: .75,
      skcColor: "#1C1C1D",
      skcTool: null,
      strokeList: false,
      rectList: false,
      skcCanvas: {
          canvasWidth: 1565,
          canvasHeight: 1080,
          canvasWidthFull: 1920,
          canvasHeightFull: 1080,
          mode: null,
          options: {
              freeDrawing: {},
              text: {},
              rect: { fill: 'transparent', borderRadius: 2 },
              line: {},
              arrow: {},
              comment: {},
          },
      },
      skcWidth: 1565,
      skcHeight: 1080,
      intel: false,
      intelIsolation: [null, null],
      shareModal: false,
      viewDetails: false,
      intelChartBg: null,
      intelLineBg: null,
      showLinePicker: false,
      isolatedModules: [],
      isolatedModule: null
    }
  },

  watch: {
    skcOpacity: function (val) {
      if (val) {
        this.renderCanvas()
      }
    },
    getQuadrantsColors: function(val) {
      this.pickerUpdated = true;
    },
    navigationClicked: function(val) {
      this.zoomReset()
    },
    $route: {
        handler: function(value) {
          //  this.zoomReset();
            // this.checkCtrl();
            if(value.name == 'teamwork.mindmap' && this.$refs.sketch) {
                this.$refs.sketch.getCanvasZoom();
                // this.$refs.panZoom.$panZoomInstance.pause();
            }

            if(value.name == 'teamwork.canvas') {
              this.navigationClicked = 1;
            }
        },
        immediate: true
    }
  },

  computed: {
    ...mapGetters({
      activeBrainstormData: "brainstormv1/activeBrainstormData",
      conceptData: "concept/concept",
      onlineUsers: "socket/inBrainstormPage",
      canvasPropValue: "concept/canvasPropValue",
      canvasFirstModule: "concept/canvasFirstModule",
      canvasSecondModule: "concept/canvasSecondModule",
      intelItem: "intel/intelItem",
      intelModule: "intel/intelModule",
      parameters: "intel/parameters",
      canvasTwo: "intel/canvasTwo",
      canvasTwoIsolation: "concept/canvasTwoIsolation",
      project: "project/project",
      definitions: "concept/definitions",
      definitionActiveIndex: "concept/definitionActiveIndex",
    }),

    canvasStyle(){
      return this.conceptData.style;
    },
    getModules() {
      if(this.activeBrainstormData) {
        return this.activeBrainstormData.modules;
      }
      return [];
    },
    getNumberModules() {
      if(this.isolatedModule) {
        return 1;
      } else {
        let moduleLength = this.getModules.length / 3;
        return Math.ceil(moduleLength);
      }
    },
    getCanvasType() {
      if(this.activeBrainstormData && this.activeBrainstormData.scenario_id) {
        return this.scenarioCanvasType;
      }
      return this.canvasType;
    },
    getBackgroundColor() {
      let opacity = this.skcOpacity
      return 'rgba(237, 237, 237,'+ opacity +')'
    },
    moduleSettings() {
      if(this.activeBrainstormData.module) {
        let settings = JSON.parse(this.activeBrainstormData.module.collection_settings);
        if(settings) {
          return settings.collections;
        }
        return [];
      }
      return [];
    },
    getQuadrantsColors() {
      if(this.conceptData && this.conceptData.colors) {
        return this.conceptData.colors;
      }
      return ['', '', '', '',];
    },
    intelActiveStyle() {
      return this.intelItem && this.intelItem.style ? this.intelItem.style : 0;
    },
    getIntelCanvasTwoModule() {
      if(this.canvasTwo && this.canvasTwo.module && this.canvasTwo.module.id) {
        return this.canvasTwo.module;
      }
      return null;
    },
    getIntelCanvasTwoParameters() {
      if(this.canvasTwo && this.canvasTwo.parameters && this.canvasTwo.parameters.length > 0) {
        return this.canvasTwo.parameters;
      }
      return [];
    },
    getIntelModule() {
      return this.intelModule;
    },
    intelChartColor: {
      get: function() {
        if(this.intelChartBg) {
          return this.intelChartBg;
        } else {
          if(this.intelItem && this.intelItem.colors && this.intelItem.colors.length > 1 && this.intelItem.colors[1].length > 0) {
            return this.intelItem.colors[1][0];
          }
          return "#F9F9F9";
        }
      },
      set: function(newValue) {
        this.intelChartBg = newValue;
      }
    },
    intelLineColor: {
      get: function() {
        if(this.intelLineBg) {
          return this.intelLineBg;
        } else {
          if(this.intelItem && this.intelItem.colors && this.intelItem.colors.length > 1) {
            return this.intelItem.colors[0];
          }
          return "#E2E2E2";
        }
      },
      set: function(newValue) {
        this.intelLineBg = newValue;
      }
    }
  },

  methods: {
    getModuleForIntel() {
      let module = this.activeBrainstormData.module;
      module.collections = this.activeBrainstormData.collections;
      return module;
    },
    getCanvasData(serial) {
      if(serial === 1) {
        if(this.canvasPropValue.canvas_one_h && this.canvasPropValue.canvas_one_v && this.canvasFirstModule) {
          return this.canvasFirstModule;
        }
      } else {
        if(this.canvasPropValue.canvas_two_h && this.canvasPropValue.canvas_two_v && this.canvasSecondModule) {
          return this.canvasSecondModule;
        }
      }
      return null;
    },
    getHorizontalDefinitionItems(type) {
      let activeType = type;
      if (this.definitions.length > 0) {
        let index = 0;
        if (type === 1) {
          type = this.conceptData.hr_property_collection_id;
          index = this.definitionActiveIndex.horizontal;
        } else {
          type = this.conceptData.vr_property_collection_id;
          index = this.definitionActiveIndex.vertical;
        }

        let data = this.definitions.find((item) => item && item.parameter_id && item.parameter_id === type);
        let customData = Object.assign({}, data);
        if (customData && customData.items && customData.items.length > 0) {
          return customData.items[index].range_with_definition;
        }
      }
      return [];
    },

    setCanvasStyle(incrementOrDecrement)
    {
      console.log(this.canvasStyle)
      let index;
      if((this.canvasStyle+incrementOrDecrement)>=this.canvasStylesArr.length)
      {
        index = 0
      } else if((this.canvasStyle+incrementOrDecrement)<0){
        index = (this.canvasStylesArr.length-1)
      }else{
        index = this.canvasStyle+incrementOrDecrement
      }
      if(index === 3) index = 4;
      this.conceptData.style = index;
      let data = {
        style: index,
        id: this.conceptData.id,
        title: this.conceptData.title,
        project_id: this.$route.params.id,
        brainstorm_id: this.$route.params.brainstorm_id
      };
      this.$store.dispatch("concept/setCanvasStyle", index);
      this.$store.dispatch("concept/updateConcept", data);
    },

    hidePicker() {
      this.showPicker = false;
    },
    hideLinePicker() {
      this.showLinePicker = false;
    },
    colorChange() {
      this.hidePicker();
    },
    setQuadrantColors(e, index) {
      this.pickerUpdated = true;
      if(this.conceptData) {
        let colorsArray = this.getQuadrantsColors;
        colorsArray[index] = e;
        let data = {
          id: this.conceptData.id,
          title: this.conceptData.title,
          colors: colorsArray,
          project_id: this.$route.params.id,
          brainstorm_id: this.$route.params.brainstorm_id,
          stop: true
        };
        this.$store.dispatch("concept/updateConcept", data);
      }
    },
    loadPageData() {
      this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id});
    },
    async autoScreenshot() {
      const el = this.$refs.autoScreenshot;
      const options = {
        type: 'dataURL'
      }
      this.brainstormThumbnail = await this.$html2canvas(el, options);
    },
    async sketchScreenshot() {
      // const el = this.$refs.sketchScreenshot
      const el = document.getElementById('container')
      const options = {
        type: 'dataURL',
        backgroundColor:null,
        height: this.skcHeight,
        width: this.skcWidth
      }
      this.sketchScreen = await this.$html2canvas(el, options);

      let data = {}
      data.title = 'New Brainstorm Sketch'
      data.brainstorm_id = this.activeBrainstormData.id
      this.$store.dispatch('brainstormv1/storeSketchItem', data)
    },
    toggleConcept() {
      this.concept = !this.concept;
      if(this.concept) {
        this.notifyOnCursorMoving({ x: 0, y: 0, in_concept: true, hide: true });
      } else {
        this.notifyOnCursorMoving({ x: 0, y: 0, in_concept: false, hide: true });
      }
    },
    closeConcept(event) {
      this.concept = false;
      if(event && event.pageX && event.pageY) {
        this.notifyOnCursorMoving({ x: event.pageX, y: event.pageY, in_concept: false, hide: true });
      }
    },
    gotoConcept(event) {
      this.concept = true;
      this.notifyOnCursorMoving({ x: event.pageX, y: event.pageY, in_concept: true });
    },
    toggleToIntel() {
      this.concept = false;
      this.sketch = false;
      this.intel = true;

      const self = this;
      setTimeout(() => {
        if(self.intelModule && self.intelModule.id) {
          self.$refs.layout_1.setRootCollections();
          self.$refs.layout_1.setChartData();
        }
        if(self.intelActiveStyle === 2 && self.canvasTwo && self.canvasTwo.module && self.canvasTwo.parameters && self.canvasTwo.parameters.length > 0) {
          self.$refs.layout_2.setRootCollections();
          self.$refs.layout_2.setChartData(true);
        }
      }, 500);
    },
    closeIntel() {
      this.intel = false;
    },
    intelChartIsolation(data, itemId, serial) {
      if(this.intelIsolation[serial - 1] === itemId) {
        this.intelIsolation[serial - 1] = null;
        data = [];
      } else {
        this.intelIsolation[serial - 1] = itemId;
      }

      if(serial === 1) {
        this.$refs.layout_1.setIsolatedData(data);
      } else {
        this.$refs.layout_2.setIsolatedData(data);
      }
    },
    removeIntelIsolation(data, serial) {
      this.intelIsolation[serial - 1] = data;
      if(serial === 1) {
        this.$refs.layout_1.setIsolatedData(data);
      } else {
        this.$refs.layout_2.setIsolatedData(data);
      }
    },
    setIntelGroup(data, serial) {
      if(serial === 1) {
        this.$refs.layout_1.setIntelGroupData(data);
      } else {
        this.$refs.layout_2.setIntelGroupData(data);
      }
    },
    setIntelChartColor(e) {
      this.intelChartBg = e;
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title,
        colors: [this.intelLineColor, [this.intelChartBg]]
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    setIntelLineColor(e) {
      this.intelLineBg = e;
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title,
        colors: [this.intelLineBg, [this.intelChartColor]]
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    clickNavigation(val) {
      if(val) {
        this.navigationClicked = val;
      } else {
        if(this.navigationClicked === 2) {
          this.navigationClicked = 1;
        } else {
          this.navigationClicked = 2;
        }
      }

      if (this.navigationClicked == 1) {
        this.skcWidth = this.skcCanvas.canvasWidth
        this.skcHeight = this.skcCanvas.canvasHeight
        this.initialY = 160
      }
      else if (this.navigationClicked == 2) {
        this.skcWidth = this.skcCanvas.canvasWidthFull
        this.initialY = this.initialYF
        this.skcHeight = this.skcCanvas.canvasHeightFull
      }
    },
    toggleView() {
      if (this.viewIndex == 0) {
        this.viewIndex = 1
      } else if (this.viewIndex == 1) {
        this.viewIndex = 0
      }
    },
    updateTitle() {
      let data = {
        id: this.activeBrainstormData.id,
        title: this.activeBrainstormData.title
      };
      this.$store.dispatch("brainstormv1/updateBrainstorm", data);
    },
    newRootChild(moduleId = null, order = null) {
      let module = this.activeBrainstormData.modules.find(item => item.id == moduleId);
      if(module) {
        let refModule = cloneDeep(module);
        let settings = JSON.parse(refModule.collection_settings);
        let collections = settings.collections;

        let data = {
          parent_id: null,
          brainstorm_id: this.$route.params.brainstorm_id,
          level: 1,
          title: collections.length > 0 ? collections[1].title : 'Identifier',
        }

        this.$Progress.start();
        if(this.activeBrainstormData.scenario_id) {
          data.scenario_id = this.activeBrainstormData.scenario_id;
          data.item_order = data.order;
          data.from_right_side = false;
          data.module_id = moduleId;
          data.order = order;
          this.toScenarioCreate(data);
        } else {
          data.order = this.activeBrainstormData.collections.length > 0 ? this.activeBrainstormData.collections[this.activeBrainstormData.collections.length - 1].order + 1 : 1
          if(this.activeBrainstormData.module_id) {
            data.item_order = data.order;
            data.from_right_side = false;
            data.module_id = this.activeBrainstormData.module_id;
            this.toBuildCreate(data);
          } else {
            this.toBrainstormCreate(data);
          }
        }
      }
    },
    newSubChild(data) {
      this.$Progress.start();
      if(this.activeBrainstormData.scenario_id) {
        let module = this.activeBrainstormData.modules.find(item => item.id == data.module_id);
        if(module) {
          let refModule = cloneDeep(module);
          let settings = JSON.parse(refModule.collection_settings);
          let collections = settings.collections;
          data.title = collections.length > 0 ? collections[data.level].title : data.title;
        }

        data.scenario_id = this.activeBrainstormData.scenario_id;
        data.item_order = data.order;
        data.from_right_side = false;
        this.toScenarioCreate(data);
      } else {
        if(this.activeBrainstormData.module_id) {
          data.module_id = this.activeBrainstormData.module_id;
          data.item_order = data.order;
          data.from_right_side = false;
          this.toBuildCreate(data);
        } else {
          this.toBrainstormCreate(data);
        }
      }
    },
    toBuildCreate(data) {
      this.$store.dispatch("build/storeData", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });
      this.changedDataFromCollaboration({ load: true });
    },
    toBrainstormCreate(data) {
      this.$store.dispatch("brainstormv1/storeItem", data).then(response => {
        if(response && response.data && response.data.status && response.data.data) {
          this.isolatedIds.push(response.data.data.id);
        }
        this.$Progress.finish();
      });

      this.changedDataFromCollaboration({ load: true });
    },
    toScenarioCreate(data) {
      this.$store.dispatch("programmatic/storeScenarioCollectionItem", data).then(response => {
        shareContentApi(this.activeBrainstormData.id);
        if(response && response.data && response.data.data && response.data.data.id) {
          let payload = {
            module_id: data.module_id,
            id: response.data.data.id,
            parent_id: response.data.data.parent_id,
          };
          this.pushToIsolation(payload);
        }

        this.$store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: this.$route.params.brainstorm_id}).then(response => {
          this.$Progress.finish();
        });
      });

      this.changedDataFromCollaboration({ load: true });
    },
    notifyUpdate(data) {
      this.changedDataFromCollaboration(data);
    },
    pushToIsolation(payload) {
      let module = this.isolatedModules.find(data => data.module_id === parseInt(payload.module_id));
      if(module) {
        if(module.isolatedIds && module.isolatedIds.length > 0 && payload.parent_id) {
          let data = module.isolatedIds.filter(item => item === parseInt(payload.parent_id));
          if(data) {
            module.isolatedIds.push(payload.id);
          }
        }
      }
    },
    zoomReset() {
    //   let self = this;
    //   setTimeout(() => {
    //     let inst = self.$refs.panZoom;
    //     if (inst && inst.$panZoomInstance) {
    //       inst.$panZoomInstance.moveTo(self.initialX, self.initialY)
    //       inst.$panZoomInstance.zoomAbs(self.initialX, self.initialY, self.initialZoom);
    //     }
    //   }, 50);
    },
    // controlled scroll
    menuHandler(obj) {
      this.contextmenu = true
      this.contextmenuItem = obj.item
      this.contextModule = this.contextmenuItem.module_id;
      let top = obj.top
      let left = obj.left
      let context = document.getElementById('context-menu-' + this.activeBrainstormData.module_id)
      context.style.left = left+'px'
      context.style.top = top+'px'
    },
    closeMenuHandler(e) {
        var context = document.getElementById('context-menu-' + this.activeBrainstormData.module_id);
        if (context && !context.contains(e.target)) {
          this.contextmenu = false
        }
    },
    applyAppearnce(value) {
      this.appearance = value
      this.contextmenu = false
    },
    applyIndexTitle(value) {
      this.indexTitle = value
      this.contextmenu = false
    },
    applyDescription(value) {
      this.description = value
      this.contextmenu = false
    },
    applyBackground(value) {
      this.contextmenuItem.background_color = value;
      let obj = {... this.contextmenuItem}
      obj.created_by = this.contextmenuItem.created_by.id;
      if (this.activeBrainstormData.module_id) {
        this.$store.dispatch("build/regularUpdate", obj)
      }
      if (this.activeBrainstormData.scenario_id) {
        this.$store.dispatch("programmatic/updateCollection", obj);
      }

      this.contextmenu = false
    },
    updateConceptTitle() {
      let data = {
        id: this.conceptData.id,
        title: this.conceptData.title
      };
      this.$store.dispatch("concept/updateConcept", data);
    },
    updateIntelTitle() {
      let data = {
        id: this.intelItem.id,
        title: this.intelItem.title
      };
      this.$store.dispatch("intel/updateIntel", data);
    },
    getSectionModules(sectionIndex) {
      if(this.isolatedModule) {
        return this.activeBrainstormData.modules.filter(module => module.id === this.isolatedModule);
      } else {
        let initial = sectionIndex * 3;
        let array = [];
        array.push(this.activeBrainstormData.modules[initial]);
        array.push(this.activeBrainstormData.modules[initial + 1]);
        array.push(this.activeBrainstormData.modules[initial + 2]);
        return array;
      }
    },
    toggleSketchMenu() {
      this.sketchMenu = !this.sketchMenu;
    },
    generateSketchCanvas() {
      this.$refs.panZoom.pause()
      this.sketchMenu = false
      this.sketchScreenshot()
      setTimeout(() => {
        this.sketch = true
      }, 200);
      this.enableCanvas('selectMode')
    },
    closeSketchCanvas() {
      this.clearSketch()
      this.$refs.panZoom.resume()
    },
    toggleFlippedArray(data) {
      let index = this.flippedArray.findIndex(item => parseInt(item.id) === parseInt(data.id));
      if(index >= 0) {
        let item = this.flippedArray.find(item => parseInt(item.id) === parseInt(data.id));
        if(item.flip_count === 1) {
          item.flip_count = 2;
        } else {
          this.flippedArray.splice(index, 1);
        }
      } else {
        data.flip_count = 1;
        this.flippedArray.push(data);
      }
    },
    getIds(item) {
      this.isolatedIds.push(item.id);
      item.child.forEach(child => {
        this.getIds(child);
      });
    },
    toggleIsolation(item) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === item.module_id);
      if(moduleIndex >= 0) {
        let moduleIsolation = this.isolatedModules[moduleIndex];
        if(moduleIsolation.isolatedItemId !== item.id) {
          this.prepareIsolationData(item);
        }
        this.isolatedModules.splice(moduleIndex, 1);
      } else {
        this.prepareIsolationData(item);
      }
      // this.zoomReset();
    },
    prepareIsolationData(item) {
      let isolateData = item.parents;
      let data = {
        module_id: item.module_id,
        isolate: true,
        isolatedIds: this.getChildIdForIsolation(item, isolateData),
        isolatedItemId: item.id
      }
      this.isolatedModules.push(data);
    },
    getChildIdForIsolation(item, isolateData) {
      isolateData.push(item.id);
      item.child.forEach(child => {
        isolateData = this.getChildIdForIsolation(child, isolateData);
      });
      return isolateData;
    },
    removeIsolation(moduleId) {
      let moduleIndex = this.isolatedModules.findIndex(data => data && data.module_id === moduleId);
      if(moduleIndex >= 0) {
        this.isolatedModules.splice(moduleIndex, 1);
      }
    },
    moduleIsolation(moduleId) {
      if (moduleId === this.isolatedModule) {
        this.isolatedModule = null;
      } else {
        this.isolatedModule = moduleId;
      }
    },
    isInIsolate(item) {
      let isIndexed = this.isolatedIds.findIndex(id => parseInt(item.id) === parseInt(id));
      return isIndexed >= 0;
    },
    notifyOnCursorMoving(coordination) {
      let loggedUser = JSON.parse(localStorage.getItem('_user'));
      let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);

      if(receivers.length > 0) {
        let data = {
          x: coordination.x,
          y: coordination.y,
          user_id: parseInt(loggedUser.id),
          name: loggedUser.first_name,
          avatar: 'https://picsum.photos/200',
          in_concept: coordination.in_concept ? coordination.in_concept : false,
          working_in_device_width: (window.innerWidth > 0) ? window.innerWidth : screen.width,
          hide: coordination.hide
        };

        this.$socket.emit('brainstorm_cursor_moved', {
          receivers: receivers,
          data: data
        });
      }
    },
    movedCursorPosition(event, out = false) {
      let coordination = {
        x: event.pageX,
        y: event.pageY,
        hide: out
      };
      this.notifyOnCursorMoving(coordination);
    },
    clickColorPicker() {
      let picker = document.getElementById('color-dot')
      picker.click()
    },
    colorPicker() {
      this.$refs.sketch.changeColor(this.skcColor);
      this.skcCanvas.options["arrow"].fill = this.skcColor;

      if (this.skcCanvas.mode != "selectMode" && this.skcCanvas.mode != "textbox") {
          this.enableCanvas(this.skcCanvas.mode);
      }
    },
    enableCanvas(mode) {
        this.$refs.sketch.changeColor(this.skcColor);
        this.enabledTool = mode;
        this.skcCanvas.mode = mode;
        this.$refs.sketch.set(mode, this.skcCanvas.options[mode]);
    },
    disableCanvas() {
        this.skcCanvas.mode = "selectMode";
        this.$refs.sketch.set(this.skcCanvas.mode);
        // this.$refs.editor.getCanvasZoom();
        this.enabledTool = '';
        // $(".draggable").css("z-index", 1);
    },
    addSketch() {
        this.sketch = true;
        setTimeout(() => {
            this.$refs.sketch.changeColor(this.color);
            this.$refs.sketch.set("selectMode");
        }, 100);

    },
    closeSketch() {
        let skcanvas = this.$refs.sketch
        let objects = skcanvas.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });

            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.id = this.activeBrainstorm.id
            bsdata.sketch_data = str

            this.$store.dispatch('build/updateBrainstormItem', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id),
                this.$store.dispatch('build/getActiveBrainstorm', this.activeBrainstorm.id)
            )
        }
        else {
            //
        }
        this.sketch = false;
    },
    save() {
        let objects = this.$refs.editor.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });
            // console.log(data);

            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.module_collection_id = this.activeModuleCollection.id
            bsdata.title = document.getElementById('brtitle').value
            bsdata.data = str

            this.$store.dispatch('build/storeBrainstormData', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id)
            )
        }
        else {
            alert("Please draw something to save!")
        }
    },
    update() {
        let objects = this.$refs.editor.canvas.getObjects()
        let data = []
        if (objects.length > 0) {
            objects.forEach(element => {
                let type = element.get('type');
                let obj = {}
                obj.type = type
                obj.width = element.width
                obj.height = element.height
                obj.fill = element.fill
                obj.left = element.left
                obj.top = element.top
                obj.scaleX = element.scaleX
                obj.scaleY = element.scaleY
                obj.zoomX = element.zoomX
                obj.zoomY = element.zoomY
                obj.angle = element.angle ? element.angle : 0
                if (type == 'group') {
                    obj.items = element._objects
                }
                else if (type == 'i-text') {
                    obj.fontFamily = element.fontFamily
                    obj.fontSize = element.fontSize
                    obj.placeholder = element.text
                }
                else if (type == 'lineArrow') {
                    obj.stroke = element.stroke
                    obj.strokeWidth = element.strokeWidth
                    obj.heads = element.heads
                    obj.x1 = element.x1
                    obj.x2 = element.x2
                    obj.y1 = element.y1
                    obj.y2 = element.y2
                }
                else if (type == 'path') {
                    obj.stroke = element.stroke
                    obj.strokeLineCap = element.strokeLineCap
                    obj.strokeLineJoin = element.strokeLineJoin
                    obj.strokeMiterLimit = element.strokeMiterLimit
                    obj.strokeWidth = element.strokeWidth
                    obj.path = element.path
                }
                data.push(obj)

            });
            let str = JSON.stringify(data)

            let bsdata = {}
            bsdata.id = this.activeBrainstorm.id
            bsdata.title = document.getElementById('brtitle').value
            bsdata.data = str

            this.$store.dispatch('build/updateBrainstormItem', bsdata)
            .then(
                this.$store.dispatch('build/getBrainstorms', this.activeModuleCollection.id),
                this.$store.dispatch('build/getActiveBrainstorm', this.activeBrainstorm.id)
            )
        }
        else {
            alert("Please draw something to save!")
        }
    },
    clickUploadImage() {
      let picker = document.getElementById('img-dot')
      picker.click()
    },
    uploadImage(e) {
      this.$refs.sketch.uploadImage(e);
    },
    clearSketch() {
        this.sketch = false
        this.$refs.sketch.clear();
        this.skcOpacity = .75
        this.$refs.sketch.renderCanvas();
    },
    renderCanvas() {
        this.$refs.sketch.renderCanvas();
    },
    changedDataFromCollaboration(data) {
      let loggedUser = JSON.parse(localStorage.getItem('_user'));
      let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);

      if(receivers.length > 0) {
        this.$socket.emit('brainstorm_data_updated', {
          receivers: receivers,
          data: data
        });
      }
    },
    toggleShareModalMode(value) {
      this.shareModal = value;
    }
  },

  mounted() {
    if(this.$route.name == 'teamwork.mindmap') {
        this.$refs.sketch.getCanvasZoom();
        // this.$refs.panZoom.$panZoomInstance.pause();
    }
  },

  beforeDestroy() {
    let loggedUser = JSON.parse(localStorage.getItem('_user'));
    let receivers = this.onlineUsers.filter(item => parseInt(item) !== parseInt(loggedUser.id)).map(user => user);

    if(receivers.length > 0) {
      this.$socket.emit('leave_from_brainstorm', {
        receivers: receivers,
        user_id: parseInt(loggedUser.id)
      });
    }
  }

}
</script>

<style scoped>
    .tools-panel {
        width: 620px;
    }
    .w-1500px{
        width: 1500px;
    }

    .tool-group img:hover {
        opacity: 1;
    }

    .bs-content-div {
        height: 1080px;
    }
    .bs-content-full-div {
        height: 1080px;
    }

    .tool-group .bs-tool-mr-0 {
        margin-right: 0;
    }
    .brainstorm-full-mt {
        margin-top: 54px;
    }
    .icon-30 {
        width: 30px;
        height: 30px;
    }

    .concept-tool-level-box {
        width: 10px;
        height: 16px;
        border-radius: 1px;
    }
    .l1c {
        background: #2B80AD;
    }
    .l2c {
        background: #3D6A83;
    }
    .l3c {
        background: #6A7881;
    }
    .l4c {
        background: #818487;
    }
    .concept-title-box {
        width: 332px;
    }
    .intel-title-box {
        width: 347px;
    }
    #sketch-menu {
        top: 33px;
        left: 115px;
        width: 275px;
    }
    .sketch-item:hover {
        opacity: 1;
    }
    .track-box {
        width: 140px;
    }
    .value-track, .progress-track, .range {
        height: 2px;
    }
    .range {
        outline: none;
        -webkit-appearance: none;
    }
    input[type='range']::-webkit-slider-thumb {
        width: 20px;
        -webkit-appearance: none;
        height: 10px !important;
        cursor: pointer;
        background: #3D3D3D;
        box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
        border-radius: 1px;
    }
    .icon-list {
        border-radius: 0px 0px 2px 2px;
        z-index: 999;
        top: 33px;
        left: -15px;
    }
    .circe-of-user {
        border-radius: 17px;
        z-index: 9999;
    }
    .circle-user {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .triangle-div {
        top: -18px;
        left: -16px;
        font-size: 1.4rem;
    }

    /*arrow*/
    .rotate-90 {
        transform: rotate(
            -90deg
        );
    }
    .vue-pan-zoom-scene {
        height: 790px
    }
    #container {
        width: fit-content;
        /* padding-right: 1000px; */
        padding-bottom: 1000px;
    }
    .color-picker-div {
        border-radius: 0 0 2px 2px;
        width: 32px;
        z-index: 9999999
    }
    .pan-area {
        z-index: 1;
    }
    .tools-panel-cont {
        left: 40px;
        top: 160px;
    }
    .tools-panel-cont-full {
        left: 40px;
        top: 100px;
    }
    .tools-panel-cont-full-width {
        width: 1840px;
    }
    .tool-pos {
        top: 69px;
    }
    .tool-cont-pos-full {
        top: -6px;
        left: 70px;
    }
    .br-view-details {
        border-radius: 2px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.13);
    }
    .selected-shadow {
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    ._rightPart{
        width: calc(1560px + 355px);
    }
    .i-left-panel + ._rightPart{
        width: 1560px;
    }
    .shadow {
        box-shadow: 0px 2px 8px rgba(44, 52, 65, 0.1);
    }
    .InfoIcon{
        font-weight: 800;
        font-family: 'poppins';
        font-size: 15px;
        padding: 11px 18px;
    }
    .custom_select{
        outline: none;
        width: 170px;
    }

    .visualize_content_wrapper{
        width: 1736px;
    }

    .sideBySideWrapper {
        transform: scaleY(1.14);
        transform-origin: top;
    }
    .SbSbottomLayoutChanger {
        margin-top: 85px;
        position: relative;
        z-index: 9;
    }
    .sideBySideWrapper :is(.l1div, .l2div, .l3div, .l4div){
        transform: scaleY(0.9);
    }
</style>
