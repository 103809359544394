import { ref } from 'vue'

const selectedTagsIds = ref([])
const selectedTags = ref([])

export function useTag() {
    const handleSelectedTag = (tag) => {
        if(selectedTagsIds.value.includes(tag.id)) {
            selectedTagsIds.value.splice(selectedTagsIds.value.indexOf(tag.id), 1)
            selectedTags.value.splice(selectedTags.value.indexOf(tag), 1)
            return
        }
        selectedTagsIds.value.push(tag.id)
        selectedTags.value.push(tag)
    }

    const removeSelectedTags = () => {
        selectedTagsIds.value =  []
        selectedTags.value =  []
    }

    return {selectedTagsIds, selectedTags, handleSelectedTag, removeSelectedTags}
}