<template>
  <div>
    <div class="flex gap-5">
        <div class="flex gap-5 mt-9 mb-11 panzoom-exclude">
            <div class="relative text-primary-one" style="width: 455px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" class="opacity-70 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <path d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z" fill="currentColor"></path></svg>
                <input placeholder="Search" class="i-border-1 border-secondary-two rounded bg-transparent p-2 pl-10 w-full" @input="filterData">
            </div>
            <span class="filterBtn rounded fs-14 fw-500 button-shadow" title="Coming Soon">
<!--              title="Select Individual"-->
              <IconFourRect />
            </span>
            <span class="filterBtn rounded fs-14 fw-500 button-shadow" title="Coming Soon">
<!--              title="Multi Select"-->
              <IconFourRect2 />
            </span>
            <span class="filterBtn rounded fs-14 fw-500 button-shadow" title="Coming Soon">
<!--              title="Isolate tags"-->
              <IconFourRect3 />
            </span>
            <span v-if="canCreate(project.cans)" class="filterBtn rounded fs-14 fw-500 button-shadow" title="Add Tags">
              <IconPlus @click.native="tagAttach" />
            </span>
        </div>
    </div>

    <div class="flex gap-6 flex-wrap ws-scrollbar" style="height: 220px; overflow-y: auto;">
        <CollectionBox
            v-for="(level, i) in filteredData"
            :key="i"
            :level="level"
            :attachments="getAttachments(level)"
            :module="module"
        />
    </div>
  </div>
</template>

<script setup>
    import CollectionBox from "./components/CollectionBox";
    import IconFourRect from './icons/IconFourRect.vue'
    import IconFourRect2 from './icons/IconFourRect2.vue'
    import IconFourRect3 from './icons/IconFourRect3.vue'
    import IconPlus from './icons/IconPlus.vue'
    import useAtomic from "./atomic";
    import { computed, ref, watch, watchEffect } from "vue";
    import { find, isEmpty } from "lodash";
    import { useTag } from "@/components/teamwork/canvas/useTag";
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import usePermission from '@/composable/usePermission.js'
    import store from '@/store'

    const { selectedTagsIds, removeSelectedTags } = useTag()
    const { getLastLevel, getAtomicTagsAttachment, selectedAtomicIds, removeSelectedAtomicAtomic } = useAtomic();
    const { attachTagsAndAtomic  } = useCanvas();
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()


    const props = defineProps({
        isolatedModule: [Array, Number, Boolean],
        activeBrainstormData: Object,
        isolatedModules: Array,
        gridLayout: Object
    });

    const attachedTagsItems = ref([]);
    const filteredData = ref();
    const loading = ref(false);
    const project = computed(() => store.getters['project/project']);

    const levelDataIds = computed(() => {
        let moduleId = props.isolatedModule;
        if (!moduleId) {
            return null; //
        }
        let module = find(props.isolatedModules, { module_id: moduleId });
        return module ? module.isolatedIds : null;
    });
    const collections = computed(() => {
        let brainstormData = props.activeBrainstormData;
        let collections = [];
        if (brainstormData.collections) {
            collections = brainstormData.collections;
        }
        if (brainstormData.modules && brainstormData.modules.length && props.isolatedModule) {
            collections = brainstormData.modules.find((module) => module.id == props.isolatedModule).collections;
        }
        return getLastLevel(collections, levelDataIds.value);
    });
    const module = computed(() => {
        let brainstormData = props.activeBrainstormData;
        if (brainstormData.module_id && !brainstormData.scenario_id) {
            return brainstormData.module;
        }
        if (brainstormData.modules && brainstormData.modules.length && props.isolatedModule) {
            return brainstormData.modules.find((module) => module.id == props.isolatedModule);
        }
        return Object(null);
    });
    watch([collections, () => props.gridLayout], async ([value]) => {
        if (value.length > 0 && !isEmpty(props.gridLayout)) {
            let response = await getAtomicTagsAttachment(value, props.gridLayout, props.activeBrainstormData );
            attachedTagsItems.value = value.map((item) => {
            item["attachedTags"] = response[item.id];
            return item;
            });
        }
    });

    const tmpData = computed(() => {
        if (attachedTagsItems.value.length > 0) {
            return attachedTagsItems.value;
        }
        return collections.value;
    })

    watchEffect(() => filteredData.value = tmpData.value);

    const isScenario = computed(() => Boolean(props.activeBrainstormData.scenario_id));

    const tagAttach = async () => {
        if(isEmpty(selectedTagsIds.value) || isEmpty(selectedAtomicIds.value)) {
                return;
            }
        loading.value = true;
        await attachTagsAndAtomic(selectedTagsIds.value, selectedAtomicIds.value, isScenario.value);
        loading.value = false;
        let response = await getAtomicTagsAttachment(collections.value, props.gridLayout, props.activeBrainstormData );
        attachedTagsItems.value = collections.value.map((item) => {
            item["attachedTags"] = response[item.id];
            return item;
        });
        removeSelectedTags();
        removeSelectedAtomicAtomic();
    }
    const filterData = (e) => {
        let searchKey = e.target.value.toLowerCase()
        if(searchKey.length)
        {
            filteredData.value = tmpData.value.filter((item) => {
            return item.title.toLowerCase().includes(searchKey)
            })
        }else{
            filteredData.value = tmpData.value
        }
    }

    const getAttachments = (level) => {
        if (level.attachedTags && level.attachedTags.length > 0) {
            return level.attachedTags;
        }
        return [];
    };
</script>

<style scoped>
  .filterBtn{
    background-color: #ffffff;
    width: 40px;
    height: 40px;
    display: grid;
    place-content: center;
    border: 1px solid var(--color-primary-four);
    cursor: pointer;
  }
  .filterBtn:is(:hover, .active){
    background: var(--color-primary-four);
  }
  .filterBtn:is(:hover, .active) svg{
    filter: invert(1)
  }
</style>
