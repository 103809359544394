import axios from "axios"
import { fabric } from "fabric"

// customize the select controller
fabric.Object.prototype.cornerColor = '#fff'
fabric.Object.prototype.cornerStyle = 'rect'
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerSize = 14;
fabric.Object.prototype.cornerStrokeColor = '#cccc';
fabric.Object.prototype.borderColor = '#cccc';

export function useFabric()
{
    const init = {
        canvas: null,
        bg: 'transparent',
        canvasSelection: true,
        canvasDimention: {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    const initCanvas = (canvas, route) =>
    {
        let container = document.querySelector('.right-part, .i-right-panel')
        init.canvas = new fabric.Canvas(canvas, {
            backgroundColor: init.bg,
            uniformScaling: true,
            uniScaleKey: "altKey"
        })

        init.canvas.setDimensions(init.canvasDimention)
        init.canvas.selection = true
        init.canvas.isDrawingMode = init.drawingMode

        window.addEventListener('keyup', (e) =>
        {
            // make group of selected object by pressing (ctrl+g|G)
            if(e.ctrlKey && (e.key == 'g' || e.key == 'G'))
                makeGroup()

            // press delete or backspace key to delete selected object
            if(e.key == 'Delete')
                deleteSelectedObject()

            // SELECT all object by pressing (ctrl+a|A)
            if(e.ctrlKey && (e.key == 'a' || e.key == 'A')){
                let activeObject = init.canvas.getActiveObject()
                if(!activeObject?.isEditing)
                {
                    e.preventDefault()
                    selectAllObjects()
                }
            }
        })

        if(container){
            window.onclick = () => {
                init.canvas.setDimensions({
                    width: container.scrollWidth,
                    height: container.scrollHeight
                })
            }
        }

        initCanvasSaving(route)

        return init
    }

    const setDrawingMode = (mode) => {
        init.canvas.isDrawingMode = mode //mode=true|false
    }

    // delete selected object
    const deleteSelectedObject = () => {
        // if(init.canvas.getActiveObject().type.indexOf('text')>-1) return;
        let activeObjects = init.canvas.getActiveObjects()

        activeObjects.forEach(obj=>init.canvas.remove(obj))
    }

    // SELECT all objects
    const selectAllObjects = () =>
    {
        if(!init.canvas.getObjects().length) return;
        init.canvas.discardActiveObject()
        let sel = new fabric.ActiveSelection(init.canvas.getObjects(),{canvas: init.canvas})

        init.canvas.setActiveObject(sel)
        init.canvas.requestRenderAll()
    }


    const disableSelectionObjects = () => {
        init.canvas.selection = false
    }

    const enableSelectionObjects = () => {
        init.canvas.selection = true
    }

    // make group and ungroup of selected object by pressing (ctrl+g|G)
    const makeGroup = () =>
    {
        if (!init.canvas.getActiveObjects()) return;

        let activeObjects = init.canvas.getActiveObjects()

        if(activeObjects.length>1)
        {
            // make group
            init.canvas.getActiveObject().toGroup()
            init.canvas.requestRenderAll()
        }
        else{
            // make ungrouped
            if(activeObjects[0].type == 'group')
            {
                activeObjects[0].destroy()
                let insideGroupObjs = activeObjects[0].getObjects();

                // delete group from canvas
                init.canvas.remove(activeObjects[0])
                insideGroupObjs.forEach(obj => {
                    init.canvas.add(obj)
                    init.canvas.requestRenderAll()
                })
            }
        }

        init.canvas.requestRenderAll();
    }

    let sketchData = null
    const initCanvasSaving = async (route) =>
    {
        const {brainstorm_id:brainstormId, id:projectId} = route.params
        sketchData = await getSketch(brainstormId)

        if(sketchData){
            init.canvas.loadFromJSON(sketchData.settings, init.canvas.renderAll.bind(init.canvas))
        }

        init.canvas.on("object:added", async () => {
            if(!sketchData){
                saveSketch({
                    brainstorm_id: brainstormId,
                    project_id: projectId,
                    settings: init.canvas.toJSON()
                })
                return;
            }

            updateSketch(sketchData.id, {
                settings: init.canvas.toJSON()
            })
        })

        init.canvas.on({
            'mouse:up': updateApi,
            'mouse:move': updateApi
        })
    }

    let timeoutId = null
    const updateApi = () =>
    {
        if(!init.canvas.getActiveObject()) return;
        clearTimeout(timeoutId)
        timeoutId = setTimeout(()=>{

            if(!sketchData) return;
            updateSketch(sketchData?.id, {
                settings: init.canvas.toJSON()
            })
        }, 1000)
    }

    const callOnBeforeUnmount = ()=>{
        init.canvas.off({
            'mouse:up': updateApi,
            'mouse:move': updateApi
        })
    }


    const getSketch = async (brainstormId) => {
        return await axios.get(`brainstorms/sketch/sticky-notes/${brainstormId}`).then(({ data }) => data.data)
    }

    const saveSketch = async (dataObj) => {
        return await axios.post(`brainstorms/sketch/sticky-notes`, JSON.stringify(dataObj))
    }

    const updateSketch = async(id, dataObj) => {
        return await axios.post(`brainstorms/sketch/sticky-notes/${id}/update`, JSON.stringify(dataObj))
    }


    return {
        init,
        initCanvas,
        setDrawingMode,
        disableSelectionObjects,
        enableSelectionObjects,
        getSketch,
        saveSketch,
        updateSketch,
        callOnBeforeUnmount
    }
}
