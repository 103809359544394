import { fabric } from 'fabric';
export function useText()
{
    const TextConfig = {
        canvas: null,
        left: 100,
        top: 100,
        fontFamily: 'poppins',
        fill: '#000',
        fontSize: 16,
        fontWeight: '300',
        stroke: '#fff',
        strokeWidth: 0,

        demoText: 'Lorem ipsum dolor sit amet \nconsectetur adipisicing elit...',
        textInstance: null
    }

    const InitText = (canvas, {backgroundColor, color}) => {
        TextConfig.canvas = canvas
        TextConfig.fill   = color
        TextConfig.backgroundColor = backgroundColor
    }

    const AddText = (demoText=TextConfig.demoText) => 
    {
        // Change the padding logic to include background-color
        fabric.Text.prototype.set({
            _getNonTransformedDimensions() { // Object dimensions
                return new fabric.Point(this.width, this.height).scalarAdd(this.padding);
            },
            _calculateCurrentDimensions() { // Controls dimensions
                return fabric.util.transformPoint(this._getTransformedDimensions(), this.getViewportTransform(), true);
            }
        });

        let shadow = new fabric.Shadow({
            "color": "#23272f21",
            "blur": 15,
            "offsetX": 0,
            "offsetY": 6,
            "affectStroke": false,
            "nonScaling": false
        })

        let TextInstance = new fabric.IText(demoText, { 
            left: TextConfig.left,
            top: TextConfig.top,
            width: 260 ,
            height: 160,
            padding: 16,
            class: '_text',
            stroke: TextConfig.stroke,
            strokeWidth: TextConfig.strokeWidth,
            fontFamily: TextConfig.fontFamily,
            fontWeight: TextConfig.fontWeight,
            fill: TextConfig.fill,
            fontSize: TextConfig.fontSize,
            backgroundColor: TextConfig.backgroundColor,
            shadow: shadow
        })

        TextConfig.canvas.add(TextInstance)
        TextInstance.setCoords()
        TextConfig.canvas.renderAll()
    }

    const TextStyle = ({backgroundColor, color}) => 
    {
        let activeObject = TextConfig.canvas.getActiveObject()
        if(activeObject && activeObject.type == 'i-text'){
            activeObject.set({fill: color})
            activeObject.set({backgroundColor: backgroundColor})
            TextConfig.canvas.requestRenderAll()
        }
    }

    return {
        TextConfig,
        InitText,
        AddText,
        TextStyle
    }
}