<template>
    <div
        draggable
        style="min-height: 257px;"
        :style="{
            minWidth: width+'px',
            width: width+'px',
            backgroundColor: getBgColor(bgColor),
            color: getTextColor(textColor),
            marginBottom: marginBottom+'px',
            zIndex: level == 1 ? -1 : ''
        }"
        @dragenter="onDragenter"
        @dragleave="onDragleave"
        @dragover.prevent
        @drop.self="onDrop"
        @dragstart="onDragStart"
        @dragend="onDragend"
        @drag="onDrag"
        @click.stop="setActiveData"
        class="relative rounded border i-p-18 shadow-one level-wrapper"
        :class="[isSelected ? 'border-primary-one' : 'border-white',  hiddenLevel >= level ? 'visible' : 'invisible']"
    >

    <div :id="`footer-${item.id}`" class="absolute hidden bottom-2 right-3 items-center justify-between level-footer-icon-wrapper z-10">
        <LevelFooter
            @deleteItem="handleDelete"
            :item="item"
            :create-new-item="createNewItem"
            :bg-color="bgColor"
            :handle-level-component-change="handleLevelComponentChange"
        />
    </div>

        <MoveIcon
            v-if="isSelected"
            :class="!canEdit(project.cans) && 'hidden'"
            class="panzoom-exclude drag-handler w-3 h-3 cursor-move absolute right-1 top-2 opacity-80 hover:opacity-190 transition duration-150 text-black"
        />
        <RestoreIcon
            v-if="level !=1 && isSelected"
            @click.native.stop="handleBringTop($event, '.level-wrapper')"
            :class="!canEdit(project.cans) && 'hidden'"
            class="panzoom-exclude w-3 h-3 cursor-pointer absolute right-6 top-2 transition duration-150 back-icon text-black"
        />
        <template v-if="!selectedComponent">
            <TextEdit :hasChild="hasChild" :item="item" :level="level" :project="project" :updateInfo="updateInfo" :isIndexCodeVisible="isIndexCodeVisible" />
        </template>
        <div v-else>
            <component
                v-if="module"
                :module="module"
                :item="item"
                :title="title"
                :chart-config="chartConfig"
                :module-properties="module.properties"
                :hasChild="hasChild"
                :level="level"
                :project="project"
                :updateInfo="updateInfo"
                :is="selectedComponent"
                :isIndexCodeVisible="isIndexCodeVisible"
            >
                <div class="flex text-primary-one">
                    <span v-if="item.index_code && isIndexCodeVisible" class="mr-1 flex-shrink-0  fs-14 fw-700">{{ item.index_code }}</span>
                    <p
                        class="fs-14 fw-700 truncate"
                        style="min-height: 20px; width: 164x; letter-spacing: 0.05em;"
                    >{{ title }}</p>
                </div>
            </component>
        </div>
        <slot />
    </div>
</template>


<script setup>
    import { ref, watch, computed } from 'vue';
    import {cloneDeep, debounce} from 'lodash'
    import store from '@/store'
    import useLevel from "./level";
    import usePermission from '@/components/teamwork/brainstorm/usePermission.js'
    import useLevelAssessment from './useLevelAssessment'
    import TextEdit from './assessment/TextEdit'
    import LevelFooter from './LevelFooter'
    import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'
    import useLevelDom from './useLevelDom'

    const { canEdit } = usePermission();
    const { onDragover, onDragenter, onDragleave, onDrag, onDragStart, onDragend } = useLevel();
    const { shareContentUpdating, socket } = brainstormContentSharing();
    const {selectedComponent, handleLevelComponentChange} = useLevelAssessment()
    const { handleBringTop } = useLevelDom()

    const props = defineProps({
        title: String,
        module: Object,
        height: [Number, String],
        width: [Number, String],
        id: Number,
        bgColor: String,
        textColor: String,
        marginBottom: [Number, String],
        onDrop: Function,
        module_id: Number,
        hasChild: Boolean,
        item: Object,
        hiddenLevel: Number,
        activeBrainstormData: Object,
        chartConfig: Object,
        level: [String, Number],
        createNewItem: Function,
        handleDelete: Function,
    });
    const emit = defineEmits(['onUpdate', 'onClick'])

    const project = computed(()=> store.getters['project/project'])
    const activeData = computed(() => store.getters['programmatic/activeScenarioCollection'])
    const isSelected = computed(() => ('id' in activeData.value && activeData.value.id == props.item.id))
    const isIndexCodeVisible = computed(() => {
      let localModule = cloneDeep(props.module.collection_settings);
      let moduleSettings = JSON.parse(localModule);
      if(moduleSettings && moduleSettings.index_number) {
        return true;
      }
      return false;
    })

    socket.value.on("brainstorm_content_updating", (data) => {
        const title = document.querySelector(`[data-id="${data.id}"] input`);
        const description = document.querySelector(`[data-id="${data.id}"] textarea`);
        title.value = data.title;
        description.value = data.description;
    });
    const updateInfo =  debounce(function () {
        let item = {
            title: props.item.title,
            description: props.item.description,
            id: props.id
        };
        shareContentUpdating({
            title: props.item.title,
            description: props.item.description,
            id: props.id
        }, `room_${props.activeBrainstormData.id}`);
        emit('onUpdate',item);
    }, 500);

    function setActiveData(e) {
        store.commit("programmatic/setActiveBrainstormScenarioCollection", props.item)
        store.commit("brainstormv1/setActiveLevelId", props.item.id)
        let activeModule = props.activeBrainstormData.modules.find(data => data.id == props.module_id);
        if(activeModule) {
          store.commit("build/setModulePropertySet", activeModule.properties);
        }
        // emit('onClick', props.level);
        handleBringTop(e, '.level-wrapper')
    }

    const getBgColor = (color) => {
        return selectedComponent.value ? '#fff' : color
    }
    const getTextColor = (color) => {
        return selectedComponent.value ? '#1C1C1D' : color
    }
</script>

<style>
.level-footer-icon-wrapper{
    width: calc(100% - 36px);
}
</style>
