<template>
    <div @click="closeMenuHandler($event)">
        <!-- <button @click="check">check</button> -->
        <div v-if="collections" style="z-index: -1"
            class="flex gap-8 brainstrom_wrapper w-full items-start i-h-920 absolute">
            <Level @onUpdate="updateLevelInfo" v-for="(item, index) in getLevelData(collections, isolatedModules, moduleId)"
                :key="item.id" :module="module" :title="item.title" :description="item.description" :width="getWidth(item)"
                :bg-color="style.getBg(1, item)" text-color="#fff" :onDrop="onDrop" :data-id="item.id" :data-index="index"
                :data-parent-id="item.parent_id" :root-id="item.id" :level="1" :data-level="1" :id="item.id"
                :hasChild="checkChild(item)" :item="item" :hiddenLevel="hiddenLevel" :module_id="isolatedModule"
                :chart-config="chartConfig" :activeBrainstormData="activeBrainstormData" :create-new-item="createNewItem"
                :handle-delete="handleDelete">
                <div v-if="item.child.length" class="mt-5">
                    <template v-for="(sndLevelItem, sndLevelIndex) in  getLevelData(item.child, isolatedModules, moduleId)">
                        <Level :key="sndLevelItem.id" @onUpdate="updateLevelInfo" :data-index="sndLevelIndex"
                            :module="module" :title="sndLevelItem.title" :description="sndLevelItem.description" width="265"
                            :bg-color="style.getBg(2, sndLevelItem)" text-color="#fff"
                            :margin-bottom="getMarginBottom(sndLevelItem)" :onDrop="onDrop" :data-id="sndLevelItem.id"
                            :data-parent-id="sndLevelItem.parent_id" :level="2" :data-level="2" :root-id="item.id"
                            :id="sndLevelItem.id" :item="sndLevelItem" :hiddenLevel="hiddenLevel"
                            :module_id="isolatedModule" :activeBrainstormData="activeBrainstormData"
                            :chart-config="chartConfig" :create-new-item="createNewItem" :handle-delete="handleDelete">
                            <template
                                v-for="(thirdLevelChild, thirdChildIndex) in getLevelData(sndLevelItem.child, isolatedModules, moduleId)">
                                <!-- {{ child.title }} {{ child.level }} -->
                                <div :key="thirdLevelChild.id" :style="{
                                                        top: getTop(thirdChildIndex, sndLevelItem.child),
                                                        left: getLeft(thirdLevelChild),
                                                      }" class="absolute third-child-wrapper">
                                    <!-- v-if="child.id != sndLevelItem.id" -->
                                    <Level @onUpdate="updateLevelInfo" :title="thirdLevelChild.title" :module="module"
                                        :description="thirdLevelChild.description" width="265"
                                        :bg-color="style.getBg(3, thirdLevelChild)" text-color="#fff" :onDrop="onDrop"
                                        :data-id="thirdLevelChild.id" :data-index="thirdChildIndex"
                                        :data-parent-id="thirdLevelChild.parent_id" :level="3" :data-level="3"
                                        :root-id="item.id" :id="thirdLevelChild.id" :item="thirdLevelChild"
                                        :module_id="isolatedModule" :hiddenLevel="hiddenLevel"
                                        :activeBrainstormData="activeBrainstormData" :chart-config="chartConfig"
                                        :create-new-item="createNewItem" :handle-delete="handleDelete">
                                        <template
                                            v-for="(fourthLevelChild, fourthChildIndex) in getLevelData(thirdLevelChild.child, isolatedModules, moduleId)">
                                            <!-- {{ child.title }} {{ child.level }} -->
                                            <div :key="fourthLevelChild.id" :style="{
                                                                          top: 40 + fourthChildIndex * 43 + 'px',
                                                                        }" class="absolute fourth-child-wrapper">
                                                <Level @onUpdate="updateLevelInfo" :title="fourthLevelChild.title"
                                                    :module="module" :description="fourthLevelChild.description" width="265"
                                                    :bg-color="style.getBg(4 , fourthLevelChild)" text-color="#fff"
                                                    :onDrop="onDrop" :data-id="fourthLevelChild.id"
                                                    :data-index="fourthChildIndex"
                                                    :data-parent-id="fourthLevelChild.parent_id" :level="4" :data-level="4"
                                                    :root-id="item.id" :id="fourthLevelChild.id" :item="fourthLevelChild"
                                                    :module_id="isolatedModule" :hiddenLevel="hiddenLevel"
                                                    :chart-config="chartConfig" :activeBrainstormData="activeBrainstormData"
                                                    :create-new-item="createNewItem" :handle-delete="handleDelete" />
                                            </div>
                                        </template>
                                    </Level>
                                </div>
                            </template>
                        </Level>
                    </template>
                </div>
            </Level>
        </div>
        <context-menu :item="contextmenuItem" :menu="menu" :id="'context-menu-'+moduleId" v-show="contextmenu"
            class="absolute" @appearance="applyAppearnce" @appearanceSelf="applyAppearanceSelf"
            @indexTitle="applyIndexTitle" @background="applyBackground" @description="applyDescription"
            @addNew="addNew"></context-menu>
    </div>
</template>

<script setup>
    import Level from "./Level.vue";
    import useLevel from "./level";
    import useStyle from "@/components/teamwork/brainstorm/style.js";
    import { brainstormContentSharing } from '@/composable/socket/brainstormContentSharing.js'
    import router from "@/routes/routes.js";
    import store from "@/store";
    import ContextMenu from '@/components/brainstormv2/canvas/CntxtMenu';
    import {ref, computed, onMounted, watch} from "vue";
    import { get } from 'lodash'

    const route = router.currentRoute;

    const chartConfig = ref({
      choosed_property_id: null,
      choosed_property_param_id: null
    })

  // eslint-disable-next-line no-undef
  const props = defineProps({
    activeBrainstormData: Object,
    isolatedModule: [Array, Number, Boolean],
    isolatedModules:[Array],
    hiddenLevel: Number
  });

  const activeData = computed(() => {
      return store.getters['programmatic/activeScenarioCollection']
  })

  const updateLevelInfo = (item) => {
    store.commit('brainstormv1/updateLevelData', {isolatedModuleId: props.isolatedModule, item: item})
    if (props.activeBrainstormData.module_id) {
      store.dispatch("build/regularUpdate", item);
    } else if (props.activeBrainstormData.scenario_id) {
      store.dispatch("programmatic/updateCollection", item);
    } else {
      item.brainstorm_id = route.params.brainstorm_id;
      store.dispatch("brainstormv1/regularUpdate", item);
    }
  };

  const { shareContentApi } = brainstormContentSharing();
  const {
    getWidth,
    getLeft,
    getMarginBottom,
    getAllChildren,
    onDragover,
    changeTitle,
    getShortedChildren,
    check,
    setCollections,
    deleteSticky,
    stickyConvertToData,
    setNewCreatedLevel,
    convertModuleData,
    convertScenarioData,
    removeLevel,
    getLevelData,
    getTop,
    deleteLevel
  } = useLevel(route.params.brainstorm_id);

  const brainStormData = computed(() => {
    return props.activeBrainstormData;
  });

  const module = computed(() => {
    let brainstormData = brainStormData.value;
    if (brainstormData.module) return brainstormData.module;
    if (
      brainstormData.modules &&
      brainstormData.modules.length &&
      props.isolatedModule
    ) {
      return brainstormData.modules.find((module) => module.id == props.isolatedModule
      );
    }
  });

  const getModuleSettings = computed(() => {
    if (module.value) {
      return JSON.parse(module.value.collection_settings);
    }
    return null;
  });

  const moduleId = computed(() => {
    let brainstormData = brainStormData.value;
    if (brainstormData.module_id) return brainstormData.module_id;
    if (
      brainstormData.modules &&
      brainstormData.modules.length &&
      props.isolatedModule
    ) {
      return brainstormData.modules.find(
        (module) => module.id == props.isolatedModule
      ).id;
    }
    if (brainstormData.module) {
      return module.id;
    }
  });

  const style = computed(() => {
    return useStyle(brainStormData, module.value)
  })

  const collections = computed(() => {
    let brainstormData = brainStormData.value;
    if (brainstormData.collections) {
      return brainstormData.collections;
    }
    if (
      brainstormData.modules &&
      brainstormData.modules.length &&
      props.isolatedModule
    ) {
      return brainstormData.modules.find(
        (module) => module.id == props.isolatedModule
      ).collections;
    }
  });

  function onDrop(event) {
    let dropType = event.dataTransfer.getData('data-type')
    if(dropType == 'level') {
      handleLevelDrop(event)
    }
    if(dropType == 'sticky') {
      handleStickyDrop(event)
    }
    shareContentApi(route.params.brainstorm_id);
  }

  function handleLevelDrop (e) {
    let data = {
        id: parseInt(e.dataTransfer.getData('data-id')),
        index: 0,
        old_index: parseInt(e.dataTransfer.getData('data-index')),
        old_parent: parseInt(e.dataTransfer.getData('data-parent-id')),
        parent_id: parseInt(e.target.getAttribute('data-id')),
        project_id: parseInt(route.params.id),
        // brainstorm_id: route.params.brainstorm_id,
        //model: getModelName(),
        // title: e.dataTransfer.getData('data-title'),
        // description: e.dataTransfer.getData('data-description'),
        // coordination: { x: null, y: null }
    }

    if(props.activeBrainstormData.module_id) {
      convertModuleData(data)
    }

    if(props.activeBrainstormData.scenario_id) {
      data.scenario_id = props.activeBrainstormData.scenario_id
      convertScenarioData(data, props.activeBrainstormData.scenario_id)
    }
  }

  function handleStickyDrop (event) {
    let brainstormData = brainStormData.value
    const dropZone = event.target;
    const level = parseInt(dropZone.getAttribute("data-level"))
    // const rootId = dropZone.getAttribute('root-id')
    if (level == 4) return;
    let stickyNoteId = event.dataTransfer.getData("data-id")
    const parentId = dropZone.getAttribute("data-id");

    let data = {
      sticky_note_id: stickyNoteId,
      parent_id: parentId,
      level: level + 1,
      brainstorm_id: route.params.brainstorm_id,
    };

    if (moduleId.value) {
      data.module_id = moduleId.value
    }
    if (brainstormData.scenario_id) {
      data.scenario_id = brainstormData.scenario_id
    }

    stickyConvertToData(data)
    .then(res => {
        dropZone.style.borderColor = 'white'
        if (res.data?.data) {
            // console.log(res, '>>>res')
            setNewCreatedLevel(res.data.data, parentId)
            let sticky = document.getElementById('sticky-' + stickyNoteId)
            // console.log(sticky, 'sticky')
            sticky.remove()
            deleteSticky(stickyNoteId)
        }
        shareContentApi(route.params.brainstorm_id);
    })
  }

  function checkChild(item) {
    return Boolean(item.child?.length)
  }

  function createNewItem(item) {
    item.project_id = route.params.id;
    item.title = getModuleSettings.value.collections[item.level].title;
    store.dispatch("programmatic/storeScenarioCollectionItem", item).then(response => {
    shareContentApi(route.params.brainstorm_id);
    store.dispatch("brainstormv1/getActiveBrainstormData", {brainstorm_id: route.params.brainstorm_id});

      if(response && response.data && response.data.data && response.data.data.id) {
        let payload = {
          module_id: moduleId.value,
          id: response.data.data.id,
          parent_id: response.data.data.parent_id,
        };

        let module = props.isolatedModules.find(data => data.module_id === parseInt(payload.module_id));
        if(module) {
          if(module.isolatedIds && module.isolatedIds.length > 0 && payload.parent_id) {
            let data = module.isolatedIds.filter(item => item === parseInt(payload.parent_id));
            if(data) {
              module.isolatedIds.push(payload.id);
            }
          }
        }
      }
    });
  }

  const setInitialChartConfig = (properties) => {
    if(!properties.length) return
    if(chartConfig.value.choosed_property_id == null) {
      chartConfig.value.choosed_property_id = properties[0].id
    }

    if(chartConfig.value.choosed_property_param_id == null) {
      chartConfig.value.choosed_property_param_id = get(properties[0], `property_collections[0].id`, null)
    }

    
  }

  watch(collections, (newVal) => {
    setCollections(newVal);
  });

  watch(
    () => module.value,
    (newVal) => {
      if(newVal?.properties) {
        setInitialChartConfig(newVal.properties)
      }
    },
    {immediate: true, deep: true}
  )

  

  onMounted(() => {
    setCollections(collections.value);
  });
  // deleteLevel(activeData.value.id, route.params.id, props.activeBrainstormData.scenario_id)
  const handleDelete = async (id) => {
    await deleteLevel(id, route.params.id, props.activeBrainstormData.scenario_id);
    shareContentApi(route.params.brainstorm_id);
  }
</script>




<script>
export default {
    props: ["activeBrainstormData", "isolatedModule"],
    data() {
        return {
            indexTitle: true,
            contextmenu: false,
            contextmenuItem: null,
            menu: {
                description: true
            }
        }
    },

    computed: {
        myModule() {
            let brainstormData = this.activeBrainstormData;
            if (brainstormData.module) return brainstormData.module;
            if (
                brainstormData.modules &&
                brainstormData.modules.length &&
                this.isolatedModule
            ) {
                return brainstormData.modules.find((module) => module.id == this.isolatedModule);
            }
            return {}
        }
    },
    methods: {
        // context menu
        menuHandler(e, item) {
            e.preventDefault()
            this.contextmenu = true
            this.contextmenuItem = item
            let top = e.clientY - this.$el.getBoundingClientRect().y
            let left = e.clientX - this.$el.getBoundingClientRect().x
            let context = document.getElementById('context-menu-' + item.module_id)
            context.style.left = left + 'px'
            context.style.top = top + 'px'
        },

        closeMenuHandler(e) {
            let module_id = this.myModule.id
            var context = document.getElementById('context-menu-' + module_id);
            if (context && !context.contains(e.target)) {
                this.contextmenu = false
            }
        },
        applyAppearnce(value) {
            this.appearance = value
            let data = this.getLevelData
            this.setAppearanceToAll(data, value)
            this.contextmenu = false
        },
        applyBackground(value) {
            this.contextmenuItem.background_color = value;
            let obj = { ... this.contextmenuItem }
            obj.created_by = this.contextmenuItem.created_by.id;
            if (this.activeBrainstormData.module_id) {
                this.$store.dispatch("build/regularUpdate", obj)
            }
            if (this.activeBrainstormData.scenario_id) {
                this.$store.dispatch("programmatic/updateCollection", obj);
            }

            this.contextmenu = false
        },
        applyIndexTitle(value) {
            this.indexTitle = value
            this.contextmenu = false
        },
        applyAppearanceSelf(value) {
            this.contextmenuItem.appearance = value;
            this.contextmenu = false
        },
        applyDescription(value) {
            this.description = value
            this.contextmenu = false
        },
        addNew(value) {
            let parent = this.contextmenuItem
            let chcount = parent.child.length
            let order = 1;
            if (chcount > 0) {
                order = chcount + 1
            }
            let obj = {};
            obj.parent_id = parent.id;
            obj.title = this.myModuleSettings.collections[parent.level + 1].title;
            obj.level = parent.level + 1;
            obj.item_order = order;
            obj.order = order;
            obj.module_id = parent.module_id;
            obj.scenario_id = parent.scenario_id;
            this.$Progress.start();
            this.$emit("newSubChild", obj);
            this.contextmenu = false;
        },
    }
}
</script>
