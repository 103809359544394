import { useLine } from './shapJs/useLine'

const { moveTo, lineTo } = useLine()

export function useShape() {
    const ShapeConfig = {
        canvas: null,
        shapeType: null,
        strokeWidth: 1,
        strokeColor: '#000',
        fillColor: '#000',
        isDown: false,
        line: null //this proper ty used in useLine.js
    }

    const InitShape = (canvas) => {
        ShapeConfig.canvas = canvas;
    }


    /**
     * 
     * @param {Line | Rect | Circle | Others} shapeType 
     */
    let isActivatedDrawingShape = false;
    const ActivateDrawShape = (shapeType) =>
    {
        if(isActivatedDrawingShape) return
        isActivatedDrawingShape = true
        ShapeConfig.canvas.selection = false
        ShapeConfig.canvas.hoverCursor = 'auto' // to remove the move cursor, when try create new line near another line
        changeObjectSelectableTo(false) // To fix the problem: if i activate drawing to draw and deactivate drawing after that i again activate the drawing and try to draw near prev draw then it will slected and start drawing new line
        
        ShapeConfig.shapeType = shapeType
        
        ShapeConfig.canvas.on('mouse:down', startDrawingShap)
        ShapeConfig.canvas.on('mouse:move', continueDrawingShap)
        ShapeConfig.canvas.on('mouse:up', endDrawingShap)
    }

    const DeactivateDrawingShape = () => {
        isActivatedDrawingShape = false

        ShapeConfig.canvas.selection = true
        // make selectable all object of canvas
        changeObjectSelectableTo(true)
        // change the hoverCursor to move, this change to auto in ActivateDrawShap method
        ShapeConfig.canvas.hoverCursor = 'move'


        ShapeConfig.canvas.off('mouse:down', startDrawingShap)
        ShapeConfig.canvas.off('mouse:move', continueDrawingShap)
        ShapeConfig.canvas.off('mouse:up', endDrawingShap)
    }

    const SetStrokeWidth = width => {
        let activeObj = ShapeConfig.canvas.getActiveObject()
        if(activeObj && activeObj.type == 'line')
        {
            activeObj.set({strokeWidth: parseInt(width)})
            ShapeConfig.canvas.requestRenderAll()
        }

        ShapeConfig.strokeWidth = parseInt(width)
    }

    const SetStrokeColor = color => 
    {
        let activeObj = ShapeConfig.canvas.getActiveObject()
        if(activeObj && activeObj.type == 'line')
        {
            activeObj.set({stroke: color})
            ShapeConfig.canvas.requestRenderAll()
        }
            
        ShapeConfig.strokeColor = color
    }

    const SetFillColor = color => ShapeConfig.fillColor  = color



    


    // helper methods start =====================
    const startDrawingShap = (o) => 
    {
        ShapeConfig.isDown = true
        let pointer = ShapeConfig.canvas.getPointer(o.e)
        
        if(ShapeConfig.shapeType === 'line'){
            moveTo(pointer, ShapeConfig)
        }

        ShapeConfig.canvas.requestRenderAll()
    }

    const continueDrawingShap = (o) => 
    {
        if (!ShapeConfig.isDown) return
        let pointer = ShapeConfig.canvas.getPointer(o.e)
        
        if(ShapeConfig.shapeType === 'line'){
            lineTo(pointer, ShapeConfig)
        }

        ShapeConfig.canvas.requestRenderAll()
    }

    const endDrawingShap = (o) => {
        ShapeConfig.isDown = false

        if(ShapeConfig.shapeType === 'line'){
            ShapeConfig.line.setCoords()
        }
    }

    const changeObjectSelectableTo = (boolean) => {
        ShapeConfig.canvas.getObjects().forEach(obj => {
            obj.set({
                selectable: boolean
            })
        })
    }


    return {
        ShapeConfig,
        InitShape,
        ActivateDrawShape,
        DeactivateDrawingShape,
        SetStrokeWidth,
        SetStrokeColor,
        SetFillColor
    }
}