<template>
    <div ref="navWrapper" class="scetchController grid gap-5" :class="(isControllerActive&&canvas)&&'active'">
        <button class="text-gray-500 rounded bg-primary-three shadow-one mainController relative p-2 text-center cursor-pointer" @click="drawingMode(false)" title="Move Tool">
            <IconSelection size="24px" style="transform: scale(0.8)" />
        </button>

        <button class="text-gray-500 rounded bg-primary-three shadow-one mainController relative p-2 text-center cursor-pointer" @click="drawingMode(true)" title="Pen Tool">
            <IconPen size="24px" style="transform: scale(0.8)" />
            <div class="subController shadow-one absolute bg-white rounded left-0 p-2 px-4">
                <RangSlider :min="1" :max="50" :step="1" :value="parseInt(penSize)" @Onchange="getPenSize" />
                <input v-model="skcColor" type="color" title="Pen Color" @input="getPenColor">
            </div>
        </button>
        
        <button class="text-gray-500 rounded bg-primary-three shadow-one mainController relative p-2 text-center cursor-pointer">
            <span @click="drawLine" title="Draw Line">
                <IconLine size="24px" style="transform: scale(0.8)" />
            </span>
            <div class="subController shadow-one absolute bg-white rounded left-0 p-2 px-4">
                <RangSlider :min="1" :max="50" :step="1" :value="parseInt(lineStrokeWidth)" @Onchange="getStrokeWidth" />
                <input v-model="strokeColor" type="color" title="Line Color" @input="getStrokeColor">
            </div>
        </button>
        
        <button class="text-gray-500 rounded bg-primary-three shadow-one mainController relative p-2 text-center cursor-pointer">
            <span @click="setText" title="Add Text">
                <IconText size="24px" style="transform: scale(0.8)" />
            </span>
            <div class="subController shadow-one absolute bg-white rounded left-0 p-2 px-4">
                <input v-model="textBGColor" type="color" title="Background Color" @input="TextStyle({backgroundColor: textBGColor, color: textColor})">
                <input v-model="textColor" type="color" title="Text Color" @input="TextStyle({backgroundColor: textBGColor, color: textColor})">
            </div>
        </button>

        <button class="text-gray-500 rounded bg-primary-three shadow-one relative p-2 text-center cursor-pointer" @click="clearCanvas" title="Clear Canvas">
            <IconTrash size="24px" style="transform: scale(0.8)" />
        </button>
    </div>
</template>

<script setup>
    import IconSelection from '@/components/teamwork/sketch/icons/IconSelection.vue'
    import IconPen from '@/components/teamwork/sketch/icons/IconPen.vue'
    import IconText from '@/components/teamwork/sketch/icons/IconText.vue'
    import IconLine from '@/components/teamwork/sketch/icons/IconLine.vue'
    import IconEmoji from '@/components/teamwork/sketch/icons/IconEmoji.vue'
    import IconStar from '@/components/teamwork/sketch/icons/IconStar.vue'
    import IconFlip from '@/components/teamwork/sketch/icons/IconFlip.vue'
    import IconFilter from '@/components/teamwork/sketch/icons/IconFilter.vue'
    import IconColor from '@/components/teamwork/sketch/icons/IconColor.vue'
    import IconClear from '@/components/teamwork/sketch/icons/IconClear.vue'
    import IconReset from '@/components/teamwork/sketch/icons/IconReset.vue'
    import IconTrash from '@/components/teamwork/sketch/icons/IconTrash.vue'
    import router from '@/routes/routes.js'
    import { ref, onMounted, onBeforeUnmount } from "vue"
    import RangSlider from './RangSlider'
    import { useFabric } from './useFabric'
    import { usePen } from './usePen'
    import { useShape } from './useShape'
    import { useText } from './useText'
    
    
    const route = router.currentRoute
    const { init, initCanvas, setDrawingMode, getSketch, saveSketch, updateSketch, callOnBeforeUnmount } = useFabric()
    const { initDrawing, penStyles, setPenStyle, setPenSize, setPenColor } = usePen()
    const { ShapeConfig, InitShape, ActivateDrawShap, DeactivateDrawingShape, SetStrokeWidth, SetStrokeColor, SetFillColor } = useShape()
    const { TextConfig, InitText, AddText, TextStyle } = useText()

    // eslint-disable-next-line no-undef
    const props = defineProps({
        canvas: {
            type: String,
            default: null
        },
        isControllerActive: {
            type: Boolean,
            default: false
        }
    })

    const skcColor        = ref('#1C1C1D')
    const penSize         = ref(1)
    const lineStrokeWidth = ref(1)

    const colorPickerElem = ref()
    const isCanvasOpen    = ref(false)
    const strokeColor     = ref('#1c1cd')
    const navWrapper      = ref()

    const textBGColor     = ref('#e7edc0')
    const textColor       = ref('#1c1c1d')


    onMounted(()=>{
        initCanvas(props.canvas, route)
        initDrawing(init.canvas)
        InitShape(init.canvas)
        InitText(init.canvas, {backgroundColor: textBGColor.value, color: textColor.value})
        
        let naves = navWrapper.value.children;
        if(naves)
        {
            naves = [...naves]
            naves.forEach(nav => {
                nav.addEventListener('click', ()=>{
                    naves.forEach(_nav => _nav.classList.remove('active'))
                })
            })
        }
    });

    const drawingMode = (mode) => {
        DeactivateDrawingShape()
        setDrawingMode(mode)
    }
    
    const getPenSize  = (size) => {
        penSize.value = size
        setPenSize(parseInt(size))
    }
    const getPenColor = () => setPenColor(skcColor.value)


    /* working with shape start */
        const drawLine = () => 
        {
            setDrawingMode(false)

            ShapeConfig.shape = 'line'            
            ActivateDrawShape('line')
        }

        const getStrokeWidth = (width) => {
            lineStrokeWidth.value = width
            SetStrokeWidth(parseInt(width))
        }
        const getStrokeColor = () => SetStrokeColor(strokeColor.value)
    /* working with shape end */

    const setText = () => {
        drawingMode(false)
        AddText()
    }


    const clearCanvas = () => {
        if(confirm("Are you sure to clear the canvas?")){
            init.canvas.clear()
            init.canvas.set({
                backgroundColor: init.bg
            })
        }
    }


    // push active object style to control buttons start
        onMounted(() => {
            let container = document.querySelector('.right-part, .i-right-panel')
            let CanvasContainer = document.querySelector('.canvas-container')
            if(CanvasContainer) CanvasContainer.style.cssText = "z-index: 1;left: 0;"

            init.canvas.setDimensions({
                width: (container) ? container.scrollWidth : 1563, 
                height:930
            })

            init.canvas.on({
                'mouse:down': updateDefaultValue,
                'object:added': updateDefaultValue,
                'selection:created': updateDefaultValue,
                'selection:updated': updateDefaultValue,
                'before:selection:cleared': updateDefaultValue
            })
        })

        onBeforeUnmount(()=>
        {
            callOnBeforeUnmount()
            init.canvas.off({
                'mouse:down': updateDefaultValue,
                'object:added': updateDefaultValue,
                'selection:created': updateDefaultValue,
                'selection:updated': updateDefaultValue,
                'before:selection:cleared': updateDefaultValue
            })
        })
        
        const updateDefaultValue = () => 
        {
            let selectedObjects = init.canvas.getActiveObjects()
            if(selectedObjects.length>1 || selectedObjects.length === 0)
            {
                skcColor.value = '#000'
                penSize.value  = 1
                lineStrokeWidth.value = 1
                strokeColor.value     = '#000'
                textBGColor.value = '#e7edc0'
                textColor.value   = '#1c1c1d'
                return;
            }

            const { stroke, fill, strokeWidth, backgroundColor } = selectedObjects[0]
            if(selectedObjects[0].type == 'i-text'){
                textBGColor.value = backgroundColor
                textColor.value   = fill
            }
            if(selectedObjects[0].type == 'path'){
                skcColor.value = stroke
                penSize.value  = strokeWidth
            }
            if(selectedObjects[0].type == 'line'){
                lineStrokeWidth.value = strokeWidth
                strokeColor.value     = stroke
            }
        }
    // push active object style to control buttons start

</script>



<style scoped>
    .scetchController{
        position: absolute;
        z-index: 9999;
        /* top: 0px; */
        left: calc(100% + 20px);
        display: none;
        border-radius: 4px;
    }
    .scetchController>button{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
    }
    .scetchController>*+*{
        border-top: 1px solid #00000011;
    }
    .scetchController button:is(.active, :hover){
        box-shadow: 0 0 0 1px orange;
    }
    .scetchController.active{
        display: flex;
    }

    .subController{
        top: calc(100% - 20px);
        visibility: hidden;
        opacity: 0;
    }
    .subController{
        min-width: 150px;
        background: #fff;
        top: 100%;
    }
    .mainController:hover .subController{
        visibility: visible;
        opacity: 1;
    }
</style>

<style>
    .canvas-container{
        position: absolute !important;
        right: 0;
        top: 150px !important;
        display: none;
        z-index: 999;
    }
</style>