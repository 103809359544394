<template>
    <div
        @click="onSelect"
        class="panzoom-exclude collection-box relative flex-shrink-0 py-3 px-5 rounded text-white border-2 border-white"
        :class="[selectedAtomicIds.includes(level.id) ? 'opacity-100 is-active' : 'opacity-50 cursor-pointer' ]"
        :style="{backgroundColor: collection.getBackground(level, module)}"
    >
        <h2 class="fs-14 font-bold">{{ level.title }}</h2>
        <p
            class=" fs-14 font-normal mt-1"
            style="line-height: 21px"
            :class="[selectedAtomicIds.includes(level.id) ? 'invisible' : 'visible' ]"
        >
            {{ level.description }}
        </p>
        <ul
            class="attachments flex flex-col"
        >
            <li
                :class="{'invisible' : !item}"
                v-for="(item, index) in localAttachments"
                :key="index"
                :style="{background: item.color}"
                class="px-5 font-light text-primary-one flex items-center justify-between group"
            >
            <span class="w-11/12 truncate">{{ item.title }}</span>
            <svg @click.stop="removeTag(item)" class="bg-white rounded-full cursor-pointer opacity-0 group-hover:opacity-100 w-3 h-3 flex-shrink-0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path data-v-22e31d76="" d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="currentColor"></path>
            </svg>
        </li>
        </ul>
    </div>
</template>

<script setup>
    import useAtomic from "@/components/teamwork/canvas/atomic";
    import { ref, computed } from 'vue';
    import Collection from '@/store/services/traits/collection';
    import store from '@/store'
import axios from "axios";

    const { handleAtomicSelect, selectedAtomicIds } = useAtomic()
    // eslint-disable-next-line no-undef
    const props = defineProps({
        opacity: {
            type: String,
            default: 'opacity-100'
        },
        level: Object,
        attachments: Array,
        module: Object
    });
    const collection = new Collection;
    const deletedItems = ref([]);
    const localAttachments = computed(() => props.attachments.filter(item => !deletedItems.value.includes(item.id)))
    const onSelect = () => {
        store.commit("programmatic/setActiveBrainstormScenarioCollection", props.level)
        handleAtomicSelect(props.level.id)
    }
    const removeTag = (item) => {
        deletedItems.value.push(item.id);
        axios.delete(`/brainstorms/business-models/tags/collections/${item.id}`);
    }
</script>

<style scoped>
    .collection-box {
        width: 265px;
        height: 260px;
    }
    .collection-box > p {
        height: 190px;
        overflow: hidden;
    }
    .attachments {
        position: absolute;
        right: -14px;
        top: 39px;
        width: 21px;
        height: 200px;
        overflow-y: auto;
        gap: 9px;
        font-size: 0;
    }
    .is-active .attachments {
        width: 276px;
    }
    .is-active .attachments li{
        font-size: 14px;
        height: 20px;
    }
    .attachments > li {
        width: 100%;
        height: 20px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 1px 1px 0px;
        border: 0.5px solid #FFFFFF;
        flex-shrink: 0;
        flex-grow: 0;

    }
</style>
