import { ref } from 'vue';
import { useSocket } from '@/composable/socket/index.js';
import store from '@/store';
import { get } from 'lodash';
export const cursorSharing = () => {
    const pointerContainer = ref(null);
    const room = ref(null)
    const { socket } = useSocket();
    let users = {};
    let cursorNodes = {};

    let pointer = document.createElement("div");
    pointer.setAttribute("class", "pointer absolute");
    

    const createRoom = (name) => {
        room.value = name;
        socket.value.emit("join_to_brainstorm", name)
    }
    const moving = (e) => {
        socket.value.emit("brainstorm_cursor_move", {
            x: e.pageX,
            y: e.pageY,
            room: room.value,
            name: get(store.getters, 'user.name', ''),
            image: get(store.getters, 'user.image', null) || '/images/profile.png'
        });

       
        socket.value.on("brainstorm_cursor_moving", (data) => {
            
            if (!users[data.id] && pointerContainer.value) {
                pointer.innerHTML = getHtmlContent(data)
                cursorNodes[data.id] = pointerContainer.value.appendChild(pointer.cloneNode(true));
            }

            cursorNodes[data.id].style.left = data.x + "px";
            cursorNodes[data.id].style.top = data.y + "px";
            
            users[data.id] = data;
        });

        socket.value.on("leave_from_brainstorm", function(id) {
            delete users[id];
            if (cursorNodes[id] && cursorNodes[id].parentNode) {
                cursorNodes[id].parentNode.removeChild(cursorNodes[id]);
            }
        });
    }
    const getHtmlContent = (data) => {
        const hexColor = `#${Math.floor(Math.random() * 0xffffff).toString(16).padEnd(6, "0")}`;
        return `
            <div class="rounded-2xl text-primary-one bg-primary-three py-2 px-2 flex items-center relative shadow-one">
                <img src="${data.image}" alt="image" class="w-6 h-6 rounded-full" />
                <h4 class="ml-2 fw-600 whitespace-nowrap"> ${ data.name || '' } </h4>
                <div class="absolute triangle-div">
                    <i class="fa fa-location-arrow rotate-270 fa-lg" aria-hidden="true" style="color: ${ hexColor }"></i>
                </div>
            </div>
        `;
    }
    const functionRef = (element) => {
        pointerContainer.value = element
    }

    return {
        moving,
        createRoom,
        functionRef
    }
}