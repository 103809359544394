<template>
  <div class="mb-2 flex items-center justify-between flex-auto w-260px">
    <h2 @input="updateTitle"
        class="uppercase title fs-16 font-black font-poppins text-white panzoom-exclude cursor-text"
        :contenteditable="canEdit(project.cans)"> {{ title }} </h2>
    <button v-if="canCreate(project.cans)" @click="$emit('onClick')">
        <PlusIcon class="h-10 w-10" />
    </button>
  </div>
</template>

<script setup>
    import PlusIcon from '@/components/teamwork/canvas/components/PlusIcon.vue';
    import { useCanvas } from "@/views/screens/teamwork/useCanvas";
    import { computed } from 'vue';
    import store from '@/store'
    import usePermission from '@/composable/usePermission.js'
    const { updateTitleAndBgColor } = useCanvas();
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

    // eslint-disable-next-line no-undef
    let props = defineProps({
        catId: {
            type: Number
        },
        title: {
            type: String
        }
    })

    const project = computed(()=>{
        return store.getters['project/project']
    })

    let intervalId = null
    const updateTitle = (e) => {
        clearTimeout(intervalId)
        intervalId = setTimeout(() => {
            updateTitleAndBgColor(props.catId, e.target.innerText)
        }, 2000)
    }
</script>

<style scoped>
    .title{
        width: fit-content;
        position: relative;
    }
    .title:empty{
        min-height: 25px;
        width: 100%;
    }
    .title:empty::before{
        content: 'Title goes here';
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        color: #fff;
        opacity: 0.5;
    }
</style>
