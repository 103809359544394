import { fabric } from "fabric";

export function useLine() 
{
    const moveTo = (pointer, ShapConfig) => 
    {
        let points  = [ pointer.x, pointer.y, pointer.x, pointer.y ]
        ShapConfig.line = new fabric.Line(points, {
            strokeWidth: ShapConfig.strokeWidth,
            fill: ShapConfig.fillColor,
            stroke: ShapConfig.strokeColor,
            originX: 'center',
            originY: 'center',
            selectable : false
        })

        ShapConfig.canvas.add(ShapConfig.line)
    }

    const lineTo = (pointer, ShapeConfig) => {
        ShapeConfig.line.set({ 
            x2: pointer.x, 
            y2: pointer.y 
        })
    }


    return {
        moveTo,
        lineTo
    }
}