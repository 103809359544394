import { render, staticRenderFns } from "./Mindmap.vue?vue&type=template&id=0cd7bbb2&scoped=true&"
import script from "./Mindmap.vue?vue&type=script&setup=true&lang=js&"
export * from "./Mindmap.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Mindmap.vue?vue&type=style&index=0&id=0cd7bbb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd7bbb2",
  null
  
)

export default component.exports