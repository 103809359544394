import { ref, computed } from 'vue'
import store from '@/store'

export default function useStyle(activeBrainstormData, module) {
    const colorCodes = store.getters['global/colorCodes']

    const defaultColors = ['#2B80AD', '#3D6A83', '#6A7881', '#818487']

    const moduleSettings = computed(() => {
        if (module) {
            let settings = JSON.parse(module.collection_settings);
            if (settings) {
                return settings;
            }
            return {};
        }
        
        return {};
    })

    const getBg = (level, item) => {
        if (item && item.background_color) {
            return item.background_color;
        } else {
            if (activeBrainstormData.value.module_id || activeBrainstormData.value.scenario_id) {
                let settings = moduleSettings.value;
                if (settings) {
                    let collection = settings.collections[level];
                    if (collection) {
                        if (collection.custom_bg) {
                            return collection.custom_bg;
                        }
                        let colorCodes = getLevelWiseColorCode(level);
                        return colorCodes[collection.color];
                    }
                }
            } else {
                return defaultColors[level - 1];
            }
        }
        return defaultColors[level - 1];
    }

    const getLevelWiseColorCode = (level) => {
        let indexLevel = level - 1;
        return colorCodes.slice((indexLevel * 9), ((indexLevel * 9) + 9));
      }

    return {
        getBg,
        activeBrainstormData,
        module
    }
}