import axios from "axios";
export function useBrainstorm(){
    const getBrainstorms = async (projectId) => {
        return await axios.get(`brainstorms/sticky-notes/${projectId}`).then(({data}) => data.data)
    }

    const cloneBrainstormsTags = async (data, callBackToUpdateLayout) =>
    {
        const res = await axios.post('brainstorms/sticky-notes/replace-tags-by-selected-brainstorm', data)
        callBackToUpdateLayout()
        return res;
    }

    return {
        getBrainstorms,
        cloneBrainstormsTags
    }
}
