<template>
  <div class="w-full flex flex-col">
    <div class="grid w-full border-4 border-white rounded overflow-x-auto w-1460px" :class="!isVisualize&&'h-655px'" style="grid-template-columns: 1fr 1fr;">
        <template v-for="(cat, index) in gridLayout.layout_grid_category" >
          <div v-if="selectedCategories.includes(cat.category)" :key="cat.id" :class="!isVisualize&&'h-324px'">
              <layoutComponent width="863px" height="417px" class="twoByTwoLayoutItem" :style="{'--bgImgForVisualize': `url(${backgroundImages[index]?.bg})`, '--opacityForVisualizeImg': backgroundImages[index]?.opacity}" :isVisualize='isVisualize' :category="cat" layout="twoByTwo" _class="inner_column_2/2" />
          </div>
        </template>
    </div>
  </div>
</template>

<script setup>
  import layoutComponent from './layoutComponent.vue'
  import imageOne from '@/assets/images/visualize/2x2-1.webp'
  const selectedCategories = ['key-partner', 'customer-relationship', 'cost-structure', 'revenue-stream'];

  // eslint-disable-next-line no-undef
  const props =  defineProps({
      gridLayout: {
          type: Object
      },
      isVisualize: {
          type: Boolean
      }
  });

  const backgroundImages = [
      {
          bg: imageOne,
          opacity: 1/100*5
      },
      {}, // this empty object to match with index of for loop
      {}, // this empty object to match with index of for loop
      {}, // this empty object to match with index of for loop
      {
          bg: imageOne,
          opacity: 1/100*5
      },
      {}, // this empty object to match with index of for loop
      {}, // this empty object to match with index of for loop
      {
          bg: imageOne,
          opacity: 1/100*5
      },
      {
          bg: imageOne,
          opacity: 1/100*5
      },
  ]
</script>

<style scoped>
  .inner_column{
    grid-template-rows: repeat(2, 100px);
    grid-auto-flow:column;
    min-width: 284px;
    grid-auto-columns: minmax(min-content, max-content);
  }
  .h-324px{
    height: 324px;
  }
  .h-655px{
    height: 655px;
  }
</style>