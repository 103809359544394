import axios  from 'axios';
import {ref} from 'vue'

export const _flatTree = (localLevel) => (result, object, key='child') => {
    let child = object[key] || [];
    let item = Object.keys(object).reduce(function (o, k) {
        if (k !== key) {
            o[k] = object[k];
        }
        return o;
    }, {});
    localLevel = localLevel || 0;
    item.localLevel = localLevel;
    return result.concat(item, child.reduce(_flatTree(localLevel + 1), []));
};

const selectedAtomicIds = ref([])

export default function useAtomic() {
    const getLastLevel = (collections, levelDataIds) => {
        let flattenCollections = collections.reduce(_flatTree(), []);
        if(levelDataIds && levelDataIds.length > 0) {
            return flattenCollections.filter(el => levelDataIds.includes(el.id))
        }
        return flattenCollections;
    }

    const handleAtomicSelect = (id) => {
        if(selectedAtomicIds.value.includes(id)) {
            selectedAtomicIds.value.splice(selectedAtomicIds.value.indexOf(id), 1)
            return
        }
        selectedAtomicIds.value.push(id)
    }

    const removeSelectedAtomicAtomic = () => {
        selectedAtomicIds.value =  []
    }

    const getAtomicTagsAttachment = async (collectionsData, gridLayout, activeBrainstormData) => {
        let data = {
            atomic_ids: collectionsData ? collectionsData.map(item => item.id) : [],
            grid_category_ids: gridLayout.layout_grid_category ? gridLayout.layout_grid_category.map(item => item.id) : [],
            is_scenario: Boolean(activeBrainstormData.scenario_id)
        }
        let response = await axios.post(`brainstorms/business-models/tags/collections/grids`, data ).then(({data}) => data.data);
        let attachments = [];
        for (const key in response) {
            attachments[key] = response[key]
        }
        return attachments;
    }
    return {
        getLastLevel,
        getAtomicTagsAttachment,
        selectedAtomicIds,
        handleAtomicSelect,
        removeSelectedAtomicAtomic
    }
}