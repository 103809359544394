import oneByFiveLayout  from './layouts/oneByFiveLayout';
import twoByTwoLayout from './layouts/twoByTwoLayout';
import threeByThreeLayout from './layouts/threeByThreeLayout';
import twoBySevenLayout from './layouts/twoBySevenLayout';
import ComponentSwitcher from "./components/ComponentSwitcher";
import AtomicArea from "./AtomicArea";
export default {
    oneByFiveLayout,
    twoByTwoLayout,
    threeByThreeLayout,
    twoBySevenLayout,
    ComponentSwitcher,
    AtomicArea
}